import { api } from 'api';
import { openInNewTab } from 'utils/render-utils';
import { hashCode } from 'utils/hash';
import { downloadFile } from './download-file';

type DownloadTrackParams = {
  sessionId: string;
  licenseId: string;
  generateLicense?: boolean;
};

export function monitorAndDownloadTrackWhenReady({ sessionId, licenseId }: DownloadTrackParams) {
  let resolve, reject;
  const promise = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });

  const checkTrackInfoAndDownload = async () => {
    try {
      const track = await api.generateTrackInfo({
        id: sessionId,
        licenseId,
        generateLicense: false
      });

      if (
        track &&
        track.purchases &&
        track.purchases[licenseId] &&
        track.purchases[licenseId].track
      ) {
        downloadFile(track.purchases[licenseId].track, track.name);
        resolve();
      } else {
        setTimeout(checkTrackInfoAndDownload, 15000);
      }
    } catch (e) {
      reject(e);
    }
  };

  setTimeout(checkTrackInfoAndDownload, 5000);

  return promise;
}

export async function downloadTrack({ sessionId, licenseId }: DownloadTrackParams) {
  const track = await api.generateTrackInfo({
    id: sessionId,
    licenseId,
    generateLicense: false
  });

  if (track && track.purchases && track.purchases[licenseId] && track.purchases[licenseId].track) {
    downloadFile(track.purchases[licenseId].track);

    return true;
  }

  return false;
}

export async function downloadLicense({
  sessionId,
  licenseId,
  generateLicense
}: DownloadTrackParams) {
  try {
    const track = await api.generateTrackInfo({
      id: sessionId,
      licenseId,
      generateLicense
    });

    if (
      track &&
      track.purchases &&
      track.purchases[licenseId] &&
      track.purchases[licenseId].license
    ) {
      setTimeout(() => {
        openInNewTab(track.purchases[licenseId].license);
      });
    } else {
      throw new Error('License error');
    }
  } catch (e) {
    throw new Error('License error');
  }
}

function downloadURI(uri, name = '') {
  let link = document.createElement('a');
  link.download = name; // <- name instead of 'name'
  link.href = uri;
  link.click();
  link.remove();
}

const COLORS = [
  '#8086FF',
  '#BF80FF',
  '#80D1FF',
  '#FFAE80',
  '#FF8080',
  '#80FFFF',
  // '#C7FF80',
  // '#FCFF80',
  // '#85FFA7',
  '#85C4FF'
];

export function getColorBySessionId(sessionId: string) {
  return COLORS[hashCode(sessionId) % COLORS.length];
}
