import React from 'react';
import { Helmet } from 'react-helmet';
import { PopularPlaylists } from 'layout/components/popular-playlists';
import FormSnippet from 'layout/components/form-snippet/FormSnippet';
import './Default.scss';
import { Artists } from './ArtistsPage';
import useTranslation from 'hooks/useTranslations';

const DefaultPage = () => {
  const helmet = (
    <Helmet>
      <title>Royalty-Free Music Generation For App And Content Creators | Mubert Render</title>
      <meta
        name='description'
        content="Are You Looking To Elevate Your Content with Royalty-Free Music? ⭐ Generate Customized Royalty-Free Music Of Any Duration Using Mubert's Powerful AI Technology ⏩"
      />
    </Helmet>
  );
  const translate = useTranslation()

  return (
    <>
      {helmet}
      <div className='wrapper-music'>
        <h1 className='default-title'>{translate("Royalty-Free Music for modern creators & apps")}</h1>
        <PopularPlaylists title={translate('Popular playlists')}  />
      </div>
      <Artists isPreviewArtist={true}  />
      <FormSnippet isPreviewTracks={true} staffPicksTags='/render' />
      <PopularPlaylists title={translate('Similar categories')} isSimilarCategories/>
    </>
  );
};

export default DefaultPage;
