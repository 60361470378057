import { useMemo } from 'react';
import { useSelectBestHighestAvailableSubscription } from '../../../redux/pricing-info/selectors';

export const useDefaultPrice = () => {
  const bestAvailableSub = useSelectBestHighestAvailableSubscription();

  return useMemo(() => {
    if (!bestAvailableSub) return null;

    return { priceId: bestAvailableSub.priceId, type: bestAvailableSub.period };
  }, [bestAvailableSub]);
};

