import React, { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useInitForm } from 'hooks';
import { Button } from 'layout/components/app-button';
import { AppSelect } from 'layout/components/app-select';
import './genetrate-track.scss';
import { TRACK_HINT } from 'constants/track-types';
import { Field } from 'layout/components/field';
import { MaskedInput } from 'layout/components/masked-input';
import { useGetTrackMusicTreeQuery } from '../../../redux/api/track-music-tree';

type GenerateTrackProps = {
  onSubmit: (values: any) => void;
  className?: string;
  isLoading?: boolean;
  buttonText?: string;
};

type ForwardRefProps = {
  setError: (name: string, error: any) => void;
};

const MASK = [/\d/, /\d/, ':', /\d/, /\d/];

const GenerateTrack: React.ForwardRefRenderFunction<ForwardRefProps, GenerateTrackProps> = (
  { onSubmit, isLoading = false, buttonText = 'Make a Track Now', className },
  ref
) => {
  const { data: menu } = useGetTrackMusicTreeQuery();

  const [subMenu, setSubMenu] = useState([]);
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch
  } = useInitForm(ref, {
    defaultValues: {
      duration: '00:45'
    }
  });
  const menuChoice = watch('menuChoice');

  const secondSelectLabel = useMemo(() => {
    const choice = menuChoice || 'Genres';

    return choice === 'Activities'
      ? '2. Pick an activity'
      : `2. Pick a ${choice.slice(0, -1).toLowerCase()}`;
  }, [menuChoice]);

  const getPaths = () => {
    const paths = window.location.pathname.split('/');
    if (paths.length > 4) {
      const slice = paths.slice(-3);
      return [slice[0], slice[2]];
    } else if (paths.length === 3) {
      return [];
    }
    return paths.slice(-2);
  };

  useEffect(() => {
    if (menu) {
      const menuKeys = Object.keys(menu);
      const paths = getPaths();
      const menuChoice = menuKeys.includes(paths[0]) ? paths[0] : 'Genres';

      setValue('menuChoice', menuChoice);
      handleTopSelectorChange(menuChoice);
      const subMenuValue = menu[menuChoice];
      if (!subMenuValue) {
        return;
      }
      const groups = subMenuValue['groups'];

      const predicate = menuChoice === paths[0] ? paths[1] : 'House';
      for (const group in groups) {
        if (predicate === group) {
          setValue('playlist', { title: groups[group].name, value: groups[group].playlist });
        }
        const channels = groups[group].channels;

        for (const channel in channels) {
          if (predicate === channel) {
            setValue('playlist', {
              title: channels[channel].name,
              value: channels[channel].playlist
            });
          }
        }
      }
    }
  }, [menu]);

  const handleTopSelectorChange = (value: string) => {
    const subMenuValue = menu[value];
    setValue('playlist', '');

    if (!subMenuValue) {
      return;
    }

    const groups = subMenuValue.groups;
    const menuOptions = [];

    for (const group in groups) {
      menuOptions.push({
        title: group,
        playlist: groups[group].playlist
      });

      const channels = groups[group].channels;

      for (const channel in channels) {
        menuOptions.push({
          title: '- ' + channel,
          playlist: channels[channel].playlist
        });
      }
    }
    setSubMenu(menuOptions);
  };

  const menuOptions = Object.keys(menu || {}).map((item) => ({ title: item, value: item }));
  const subMenuOptions = subMenu.map((item, index) => ({
    value: item.playlist,
    title: item.title
  }));
  const subMenuMap = subMenu.reduce((result, item) => {
    return {
      ...result,
      [item.playlist]: item.title
    };
  }, {})
  
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={className}>
      <div className='generate-track-form'>
        <Field label='1. Select a category' error={errors.menuChoice?.message}>
          <Controller
            name='menuChoice'
            control={control}
            rules={{ required: 'Field is required' }}
            render={({ field }) => (
              <AppSelect
                {...field}
                options={menuOptions}
                onChange={(value) => {
                  field.onChange(value);
                  handleTopSelectorChange(value);
                }}
                error={!!errors.menuChoice}
              />
            )}
          />
        </Field>
        <Field label={secondSelectLabel} error={errors.playlist?.message}>
          <Controller
            name='playlist'
            control={control}
            rules={{ required: 'Field is required' }}
            render={({ field }) => {
              return (
                <AppSelect
                  {...field}
                  showSearch
                  options={subMenuOptions}
                  error={!!errors.playlist}
                  value={field.value?.value}
                  filterOption={(input, option) => {
                    return subMenuMap[option.value]?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                  onChange={(value) => {
                    const result = subMenuOptions.find((item) => item.value === value);
                    field.onChange(result);
                  }}
                />
              );
            }}
          />
        </Field>
        <Field label='3. Set duration' error={errors.duration?.message} hint={TRACK_HINT} unmountErrorElement>
          <Controller
            name='duration'
            control={control}
            rules={{
              required: TRACK_HINT,
              validate: (value) => {
                if (!value || !value.match(/\d\d:\d\d/) || value === '00:00') {
                  return TRACK_HINT;
                }

                const minutes = parseInt(value.split(':')[0]);
                const seconds = parseInt(value.split(':')[1]);

                if (seconds > 59) {
                  return 'Invalid seconds value';
                }

                if ((minutes === 25 && seconds > 0) || minutes > 25) {
                  return TRACK_HINT;
                }

                return true;
              }
            }}
            render={({ field }) => (
              <MaskedInput {...field} error={!!errors.duration} placeholder='00:00' mask={MASK} />
            )}
          />
        </Field>
        <Field
          label={
            <span>
              <br />
            </span>
          }
          className='generate-track-form__button-field'
        >
          <Button
            type='primary'
            htmlType='submit'
            className='generate-track-form__button'
            loading={isLoading}
            block
          >
            {buttonText}
          </Button>
        </Field>
      </div>
    </form>
  );
};

GenerateTrack.displayName = 'GenerateTrack';

export const GenerateTrackForm = React.forwardRef(GenerateTrack);

GenerateTrackForm.displayName = 'GenerateTrackForm';
