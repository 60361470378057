import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useAppSelector } from 'hooks';
import { createPortal } from 'react-dom';
import './tooltip.scss';

type TooltipProps = {
  children: React.ReactElement;
  text: string;
  showOverlay?: boolean;
  visible?: boolean;
  position?: 'bottom-center' | 'bottom-right' | 'bottom-left' | 'top-right' | 'top-left';
};

export const Tooltip = ({
  children,
  text,
  showOverlay = true,
  visible: propVisible = false,
  position = 'bottom-center'
}:
TooltipProps) => {
  const ref = useRef<HTMLElement>(null);
  const [isVisible, setIsVisible] = useState(propVisible);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const isMobile = useAppSelector((state) => state.settings.isMobile);

  useEffect(() => {
    const handleScroll = () => {
      if (!propVisible) {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [propVisible]);

  useEffect(() => {
    setIsVisible(propVisible);
    if (propVisible && ref?.current) {
      const { top, left, height, width } = ref.current.getBoundingClientRect();
      setTooltipPosition(getPosition(top, left, height, width, position));
    }
  }, [propVisible]);

  const handleMouseEnter = (e: React.MouseEvent) => {
    if (!propVisible) {
      const { top, left, height, width } = e.currentTarget.getBoundingClientRect();
      setTooltipPosition(getPosition(top, left, height, width, position));
      setIsVisible(true);
    }
  };
  const handleMouseLeave = () => {
    if (!propVisible) {
      setIsVisible(false);
    }
  };

  const getPosition = (
    top: number,
    left: number,
    height: number,
    width: number,
    position: string
  ) => {
    switch (position) {
      case 'bottom-right':
        return {
          top: top + height + 5 + window.scrollY,
          left: left + width
        };
      case 'bottom-left':
        return {
          top: top + height + 5 + window.scrollY,
          left: left // Устанавливаем отступ слева
        };
      case 'bottom-center':
      default: // Позиция по умолчанию
        return {
          top: top + height + 5 + window.scrollY,
          left: left + width / 2 // Устанавливаем отступ по центру
        };
      case 'top-right':
        return {
          top: top - 5 + window.scrollY - 30, // Смещение для верхнего положения
          left: left + width - 5 // Устанавливаем отступ справа
        };
      case 'top-left':
        return {
          top: top - 5 + window.scrollY - 30, // Смещение для верхнего положения
          left: left // Устанавливаем отступ слева
        };
    }
  };


  return (
    <>
      {React.cloneElement(children, {
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
        ref: ref,
        onClick: (e: React.MouseEvent) => {
          if (children.props.onClick) {
            children.props.onClick(e);
          }
          // handleClick(e);
        }
      })}
      {typeof window !== 'undefined' &&
        !isMobile &&
        createPortal(
          <CSSTransition
            in={isVisible && showOverlay}
            timeout={300}
            classNames='tooltip'
            unmountOnExit
          >
            <div
              className={`tooltip ${position}`}
              style={{
                top: tooltipPosition.top,
                left: tooltipPosition.left,
                transform:
                  position === 'bottom-center'
                    ? 'translateX(-50%)'
                    : position.includes('right')
                    ? 'translateX(-100%)'
                    : 'translateX(0)',

                whiteSpace: 'pre'
              }}
            >
              {text}
            </div>
          </CSSTransition>,
          document.body
        )}
    </>
  );
};
