import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { AppSelect } from 'layout/components/app-select';
import { Input } from 'layout/components/input';
import './connect-channels.scss';
import { NewButton } from 'layout/components/new-button';
import useTranslation from 'hooks/useTranslations';
import { ArrowRight } from 'layout/components/icons/arrow-right';
import { SocialLinkType } from '../../../redux/api/social-links';
import {
  CHANNELS,
  SOCIAL_LINK_NAMES
} from 'pages/render/ProfilePage/ConnectChannel/ConnectChannel';
import { usePush } from 'hooks/usePush';

type ConnectChannelsFormProps = {
  onSubmit: (data: any) => void;
  onClose?: () => {};
  isModal?: boolean;
};

function valueNormalization(value?: string) {
  if (!value) {
    return value;
  }

  return value.replace(/[^A-Za-z\s0-9\.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g, '');
}

export function ConnectChannelsForm({
  onSubmit: onSubmitProp,
  onClose,
  isModal
}: ConnectChannelsFormProps) {
  const translate = useTranslation();

  const methods = useForm<{ socialNetwork: SocialLinkType; url: string }>({
    defaultValues: {
      socialNetwork: CHANNELS[0],
      url: ''
    }
  });

  const { control, handleSubmit } = methods;
  const { errors, isSubmitting } = methods.formState;
  const menuOptions = CHANNELS.map((item) => ({ title: SOCIAL_LINK_NAMES[item], value: item }));
  const socialNetwork = methods.watch('socialNetwork');
  const onSubmit = async (data: any) => {
    const result = (await onSubmitProp(data)) as any;

    if (result?.data?.status === 1) {
      methods.setValue('socialNetwork', CHANNELS[0]);
      methods.setValue('url', '');
    }
  };

  const onCancel = () => {
    if (typeof onClose === 'function') {
      onClose(); // Call onClose to cancel and close the form
    } else {
      console.error('onClose is not a function');
    }
  };

  return (
    <div className='connect-channels'>
      <h2>{translate("Protect Your Content with Mubert")}</h2>
      <h3>
        {translate("We're excited that you're creating music with Mubert! Let us know where you'll be sharing your tracks so we can assist you in case of any false copyright claims. This information helps us resolve issues quickly and keep your content safe.")}
        <br />
        <br />
        {translate("Your Main Channel Link: Enter link to your YouTube, Vimeo, Tiktok or other channel where you using generated music.")}
      </h3>
      <form className='connect-channels__form'>
        <div className='connect-channels__item'>
          <Controller
            control={control}
            name='socialNetwork'
            render={({ field }) => (
              <AppSelect
                {...field}
                renderDropDownToBody
                options={menuOptions}
                isNewSelector
                customArrow={<ArrowRight className='connect-channels__arrow' />}
              />
            )}
          />
          <div className='connect-channels__input-wrapper'>
            <Controller
              control={control}
              name='url'
              rules={{
                required: 'URL is required',
                pattern: {
                  value: /^(https?:\/\/)?([\w\d-]+\.){1,2}[\w-]{2,}(\/.*)?$/i,
                  message: 'Please enter a valid URL'
                }
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  onChange={(e) => {
                    field.onChange(valueNormalization(e.target.value));
                  }}
                  placeholder={socialNetwork === SocialLinkType.Other ? '' : 'https://'}
                  className='connect-channels__input'
                  autoComplete='off'
                  error={!!errors.url}
                />
              )}
            />
            {errors.url && <p className='connect-channels__error'>{errors.url.message}</p>}
          </div>
        </div>
      </form>
      <div className='connect-channels__buttons'>
        {isModal && (
          <NewButton onClick={onCancel} transparent>
            {translate('Skip for now')}
          </NewButton>
        )}
        <NewButton loading={isSubmitting} onClick={handleSubmit(onSubmit)} gradient>
          {translate('Add Channel')}
        </NewButton>
      </div>
    </div>
  );
}
