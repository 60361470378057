import React from 'react';

import classNames from '../../../utils/class-names';
import SoundPlayerLoader from './SoundPlayerLoader';

import './SoundPlayer.scss';
import Waveform from '../waveform/Waveform';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../interfaces/app-state';

const SoundPlayer = ({ boxed = true, loaded = true }) => {
  let footerClasses = classNames({
    loaded,
    boxed
  });
  const currentTrack = useSelector<IAppState, string>((state) => state.currentTrack);

  return currentTrack ? (
    <div className={`sound-player ${footerClasses}`}>
      <Waveform session_id={currentTrack} />
      <SoundPlayerLoader />
    </div>
  ) : null;
};

export default SoundPlayer;
