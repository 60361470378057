import React, { useRef } from 'react';
import './track-options-dropdown.scss';
import useTranslation from 'hooks/useTranslations';

type SubOptionsProps = {
  data: any;
  onSelectOption: (props: { value: string; categories: string[] }) => void;
};

export function SubOptions({ data, onSelectOption }: SubOptionsProps) {
  const translate = useTranslation();
  if (!data) {
    return null;
  }

  const options = Object.keys(data).map((key) => ({
    name: data[key].name,
    value: data[key].playlist,
    sub: data[key].channels
      ? Object.keys(data[key].channels).map((subKey) => ({
          name: data[key].channels[subKey].name,
          value: data[key].channels[subKey].playlist
        }))
      : null
  }));

  return (
    <div className='sub-options'>
      {options.map((item) => (
        <ul key={item.value}>
          <li
            className='sub-options__item sub-options__item_main'
            onClick={() =>
              onSelectOption({
                value: item.value,
                categories: [item.name]
              })
            }
          >
            {translate(`${item.name}`)}
          </li>
          {item.sub?.map((sub) => (
            <li
              className='sub-options__item'
              onClick={() =>
                onSelectOption({
                  value: sub.value,
                  categories: [item.name, sub.name]
                })
              }
            >
              <div className='sub-options__item-content'>{translate(`${sub.name}`)}</div>
            </li>
          ))}
        </ul>
      ))}
    </div>
  );
}

type SubOptionsPopupProps = {
  children: React.ReactNode;
};

function SubOptionsPopup({ children }: SubOptionsPopupProps) {
  return <div className='sub-options-popup'>{children}</div>;
}

type TrackOptionsDropdownProps = {
  setIsPopUpVisible: (value: boolean) => void;
  onSelectOption: (props: { value: string; categories: string[] }) => void;
  selectedItem: string | null;
  setSelectedItem: (value: string) => void;
  menu: any;
};

export function TrackOptionsDropdown({
  menu,
  selectedItem,
  setSelectedItem,
  onSelectOption,
  setIsPopUpVisible
}: TrackOptionsDropdownProps) {
  const timoutRef = useRef(null);

  const subMenuItems = selectedItem ? menu[selectedItem]?.groups : null;

  function handleSelectSubOption({ value, categories }: { value: string; categories: string[] }) {
    onSelectOption({
      value,
      categories: [selectedItem, ...categories]
    });

    setIsPopUpVisible(false);

    if (timoutRef.current) {
      clearTimeout(timoutRef.current);
    }

    timoutRef.current = setTimeout(() => {
      setSelectedItem(null);
    }, 250);
  }

  return (
    <div>
      <SubOptionsPopup>
        <SubOptions data={subMenuItems} onSelectOption={handleSelectSubOption} />
      </SubOptionsPopup>
    </div>
  );
}
