import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'layout/components/app-button';
import { hideModal } from '../../../../redux/modals/actions';
import { usePush } from 'hooks/usePush';

export  function FreePlanTracksRestrictions({message}) {
  const dispatch = useDispatch();
  const push = usePush();

  const handleClick = () => {
    push('/render/pricing');
    dispatch(hideModal());
  };

  return (
    <div className='info-modal'>
      {message}
      <div className='info-modal__buttons info-modal__buttons_block'>
        <Button onClick={handleClick}>
          Upgrade
        </Button>
      </div>
    </div>
  )
}
