import React, { useEffect, useRef } from 'react';
import classNames from 'utils/class-names';
import './textarea.scss';

export type TextareaProps = {
  value?: any;
  id?: string;
  name?: string;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  error?: boolean;
  autoComplete?: string;
  className?: string;
  disabled?: boolean;
  onFocus?: () => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  postIcon?: React.ReactNode;
  prefixIcon?: React.ReactNode;
  readonly?: boolean;
};

function TextAreaInternal(
  {
    name,
    placeholder,
    onChange,
    value,
    error,
    autoComplete,
    disabled,
    className: classNameProp,
    onFocus,
    onBlur,
    onKeyUp,
    id,
    postIcon,
    prefixIcon,
    readonly,
  }: TextareaProps,
  ref
) {
  const textareaRef = useRef(null);

  useEffect(() => {
    const textarea = textareaRef.current;

    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [value, ref]);

  const className = classNames({
    'app-textarea': true,
    'app-textarea_error': !!error,
    'app-textarea__width-prefix': !!prefixIcon,
    [classNameProp]: !!classNameProp
  });

  return (
    <div className='app-textarea__wrapper'>
      {prefixIcon && <div className='app-textarea__prefix'>{prefixIcon}</div>}
      <textarea
        ref={textareaRef}
        rows={1}
        value={value}
        className={className}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        autoComplete={autoComplete}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        id={id}
        readOnly={readonly}
      />
      {postIcon && <div className='app-textarea__post'>{postIcon}</div>}
    </div>
  );
}

export const Textarea = React.forwardRef(TextAreaInternal);
