import React from 'react';
import Preloader from 'layout/components/preloader/Preloader';
import './redirecting.scss';

export function Redirecting() {
  return (
    <div className='redirecting'>
      <Preloader width={90} height={90} />
      <h3>
        Taking you to the next step...
      </h3>
    </div>
  )
}
