import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'layout/components/app-button';
import { hideModal } from '../../../../../redux/modals/actions';
import { useSelectAvailableSubscriptions } from '../../../../../redux/pricing-info/selectors';
import Logo from '../../../../../assets/icons/logo-with-wave.svg';
import { PricingCard } from 'pages/render/PricingPage/PricingCard';

import './upgrade-subscription.scss';
import useTranslation from 'hooks/useTranslations';
import { usePush } from 'hooks/usePush';

export function UpgradeSubscription() {
  const dispatch = useDispatch();
  const subscriptionPrices = useSelectAvailableSubscriptions();
  const translate = useTranslation();
  const push = usePush();

  let pricesWithoutFree = subscriptionPrices.filter((item) => item.isFree !== true);
  let prices =
    pricesWithoutFree.length > 0
      ? [
          pricesWithoutFree[pricesWithoutFree.length - 1],
          ...pricesWithoutFree.slice(0, pricesWithoutFree.length - 1)
        ]
      : [];

  const handleClick = () => {
    push('/render/pricing');
    dispatch(hideModal());
  };

  return (
    <div className='upgrade-modal'>
      <div className='upgrade-modal__header'>
        <img alt='logo' src={Logo} className='upgrade-modal__logo' />
        <div className='upgrade-modal__cards'>
          {prices.map((item) => (
            <PricingCard key={item.name} pricing={item} />
          ))}
        </div>
      </div>
      <div className='upgrade-modal__content'>
        <h4 className='upgrade-modal__title'>
          A paid plan is required in order
          <br /> to play the track
        </h4>
        <p className='upgrade-modal__text'>
          Subscribe now and generate <br /> tracks without restrictions
        </p>
        <Button onClick={handleClick} className='upgrade-modal__button'>
          {translate('Get started')}
        </Button>
      </div>
    </div>
  );
}
