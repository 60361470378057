import React from 'react';
import { ISubscriptionPricing } from 'interfaces';
import { PurchaseOption } from './PurchaseOption';

import './AvailableSubscription.scss';
import useTranslation from 'hooks/useTranslations';

type AvailableSubscriptionProps = {
  priceId: string
  avialableSub: ISubscriptionPricing
}

export function AvailableSubscription({ avialableSub, priceId }: AvailableSubscriptionProps) {
  const translate = useTranslation();
  return (
    <div className='available-subscription'>
      <div className='available-subscription__title'>{translate("Your subscription")}</div>
      <PurchaseOption
        id={priceId}
        name={avialableSub.name}
        description={avialableSub.popupDescription}
        price='Free'
        className='available-subscription__purchase'
      />
    </div>
  );
}
