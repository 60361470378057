import React from 'react';
import { useInitForm } from 'hooks';
import {Input} from 'layout/components/input';
import {Field} from 'layout/components/field';
import { Button } from 'layout/components/app-button';

type InsertCodeProps = {
  onSubmit: (values: any) => void;
  disabled?: boolean;
};

type ForwardRefProps = {
  setError: (name: string, error: any) => void;
};

const Recover: React.ForwardRefRenderFunction<ForwardRefProps, InsertCodeProps> = ({onSubmit, disabled}, ref) => {
  const {register, handleSubmit, formState: { errors }} = useInitForm(ref);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field label='Email' error={errors.email?.message}>
        <Input
          {...register('email', {
            required: 'The field is required'
          })}
          error={!!errors.email}
        />
      </Field>
      <div className='session-wrapper-footer forget-sbt'>
        <Button
          block={false}
          type='primary'
          htmlType='submit'
          className='submitButton'
          disabled={disabled}
        >
          Recover password
        </Button>
      </div>
    </form>
  );
}

export const RecoverForm = React.forwardRef(Recover);
