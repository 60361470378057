import React from 'react';
import { Link } from 'layout/components/link';
import classNames from 'utils/class-names';
import './tags.scss'
import useTranslation from 'hooks/useTranslations';

type TagsProps = {
  tags: Array<{
    flag_menu: 1 | 0;
    flag_artist: 1 | 0;
    title: string;
    routing: string;
    image_url?: string;
  }>;
};

export function Tags({ tags }: TagsProps) {
  const translate = useTranslation();
  if (!tags || !tags.length) {
    return null;
  }

  return (
    <ul className='render-tags'>
      {
        tags.map((tag, index) => {
          const className = classNames({
            'render-tags__item': true,
            'render-tags__item_artist': !!tag.flag_artist,
            'render-tags__advanced': (tag.title === 'Text-To-Music' || tag.title === 'Image-To-Music')
          });

          return (
            <li key={index} className={className}>
              <Link to={tag.flag_menu ? `/render${tag.routing}` : `/render/tags${tag.routing}`}>
                <span>
                  {tag.image_url && <img src={tag.image_url} alt={tag.title} width={19} height={19} />}
                  {translate(tag.title)}
                </span>
              </Link>
            </li>
          );
        })
      }
    </ul>
  );
}
