import React from 'react';
import classNames from 'utils/class-names';
import './more-icon.scss';

type MoreIconProps = {
  disabled?: boolean;
  onClick?: (e: any) => void;
  pending?: boolean;
};

export function MoreIcon({ disabled, onClick, pending }: MoreIconProps) {
  const className = classNames({
    'more-icon': true,
    'more-icon_disabled': disabled,
    'more-icon_pending': pending
  });

  return (
    <svg className={className} onClick={onClick} xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' fill='none'>
      <circle cx='8.5' cy='14.5' r='1.5' fill='#373E44' />
      <circle cx='15.5' cy='14.5' r='1.5' fill='#373E44' />
      <circle cx='22.5' cy='14.5' r='1.5' fill='#373E44' />
    </svg>
  );
}
