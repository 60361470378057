import React from 'react';
import classNames from 'utils/class-names';
import './logo-generate-icon.scss';

type LogoGenerateIconProps = {
  disabled?: boolean;
  onClick?: (e: any) => void;
};

export function LogoGenerateIcon({ disabled, onClick }: LogoGenerateIconProps) {
  const className = classNames({
    'logo-generate-icon': true,
    'logo-generate-icon_disabled': disabled
  });

  return (
    <svg className={className} onClick={onClick} width='18' height='5' viewBox='0 0 18 5' fill='none' xmlns='http://www.w3.org/2000/svg '>
      <path className='fill'
        d='M17.7698 3.75227C17.0094 3.75227 16.6148 3.14882 16.0311 2.15971C15.4638 1.19782 14.7568 0 13.3428 0C11.9287 0 11.2217 1.19782 10.6545 2.15971C10.0708 3.14428 9.67618 3.75227 8.91573 3.75227C8.15528 3.75227 7.76067 3.14882 7.17698 2.15971C6.60973 1.19782 5.90272 0 4.48869 0C3.07467 0 2.36766 1.19782 1.80041 2.15971C1.21672 3.14428 0.822105 3.75227 0.0616579 3.75227C0.0411053 3.75227 0.0205526 3.75227 0 3.74773V5C0.0205526 5 0.0411053 5 0.0616579 5C1.47568 5 2.18269 3.80218 2.74994 2.84029C3.33364 1.85572 3.72825 1.24773 4.48869 1.24773C5.24914 1.24773 5.64375 1.85118 6.22745 2.84029C6.7947 3.80218 7.50171 5 8.91573 5C10.3298 5 11.0368 3.80218 11.604 2.84029C12.1877 1.85572 12.5823 1.24773 13.3428 1.24773C14.1032 1.24773 14.4978 1.85118 15.0815 2.84029C15.6488 3.80218 16.3558 5 17.7698 5C17.8479 5 17.926 4.99546 18 4.98639V3.72505C17.926 3.7432 17.8479 3.75227 17.7698 3.75227Z'
        fill='#363C43'
      />
    </svg>
  );
}
