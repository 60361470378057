import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPricing } from '../../redux/pricing-info/actions';
import { IAppState } from 'interfaces';

export function useFetchPricingInfo() {
  const dispatch = useDispatch();
  const loggedIn = !!useSelector((state: IAppState) => state.loggedIn);

  useEffect(() => {
    dispatch(fetchPricing());
  }, [dispatch, loggedIn]);
}
