import React from 'react';
import { Helmet } from 'react-helmet';
import { Artists } from './Artists';

export function ArtistsPage() {
   const helmet = (
    <Helmet>
      <title>
        Artists (Beta) - Generate royalty-free music from Artist sample packs with AI | Mubert
        Render
      </title>
    </Helmet>
  );

  return (
    <>
      {helmet}
      <Artists />
    </>
  )
}