import React from 'react';

import {
  useUserSocialLinkAddMutation,
  useUserSocialLinkListQuery,
  SocialLinkType
} from '../../../../redux/api/social-links';
import { ChannelsList } from './ChannelsList';

import './ConnectChannel.scss';
import Preloader from 'layout/components/preloader/Preloader';
import { ConnectChannelsForm } from 'layout/forms';

export const SOCIAL_LINK_NAMES: Record<SocialLinkType, string> = {
  [SocialLinkType.YouTube]: 'YouTube',
  [SocialLinkType.Facebook]: 'Facebook',
  [SocialLinkType.Instagram]: 'Instagram',
  [SocialLinkType.TikTok]: 'TikTok',
  [SocialLinkType.Twitch]: 'Twitch',
  [SocialLinkType.Twitter]: 'Twitter',
  [SocialLinkType.Vimeo]: 'Vimeo',
  [SocialLinkType.Other]: 'Other'
};

export const CHANNELS: SocialLinkType[] = Object.values(SocialLinkType) as SocialLinkType[];

export function ConnectChannel() {
  const [addLink] = useUserSocialLinkAddMutation();

  const onSubmit = (data: { socialNetwork: SocialLinkType; url: string }) => {
    const { socialNetwork, url } = data;
    return addLink({ type: socialNetwork, link: url });
  };
  const { data, isFetching } = useUserSocialLinkListQuery();

  return (
    <div className='connect-channel-page'>
      <div className='connect-header'>
        <ConnectChannelsForm onSubmit={onSubmit} />
      </div>
      {isFetching && <Preloader width={100} height={100} />}
      {data && !isFetching && <ChannelsList data={data} />}
    </div>
  );
}
