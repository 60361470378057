import React from 'react';
import { ToggleButton } from 'ui/toggle-button';
import './ToggleGenerator.scss';

type ToggleGeneratorProps = {
  downloaded: boolean;
  setDownloaded: (downloaded: boolean) => void;
  id: string;
};

export default function ToggleGenerator({ downloaded, setDownloaded , id}: ToggleGeneratorProps) {
  return (
    <div className='toggle-generator'>
      Downloaded
      <ToggleButton
        isChecked={downloaded}
        size='XS'
        onChange={() => setDownloaded(!downloaded)}
        id={id}
      />
    </div>
  );
}
