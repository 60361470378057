export const getStripeKey = () => {
  if (process.env.REACT_APP_ENV === 'development') {
    return 'pk_test_qWStyVJzv0lWMqSxcj7XTJZ4';
  }
  return 'pk_live_eO9cFo8nB5ryntkmA0kQgwMb';
};

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener noreferrer');
  if (newWindow) newWindow.opener = null;
};
