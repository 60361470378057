import React from 'react';
import { usePathPrefix } from 'hooks/useLanguage';
import { Redirect as ReactRouterRedirect, RedirectProps } from 'react-router-dom';

export const Redirect = ({ to, ...props }: RedirectProps) => {
    const prefix = usePathPrefix();

    const updatedTo = typeof to === 'string'
        ? `${prefix}${to}`
        : to;

    return <ReactRouterRedirect {...props} to={updatedTo} />
};