import React from 'react';
import { NavLink } from 'layout/components/nav-link';
import { ArrowRight } from '../icons/arrow-right';

import Logo from 'assets/icons/logo-in-circle.svg';
import useTranslation from 'hooks/useTranslations';

type CoverPlaylistProps = {
  playlist: any;
  isSimilarCategories?: boolean;
};

type SimilarCategoriesProps = {
  playlist: any;
};

type PopularPlaylistsProps = {
  playlist: any;
};

function SimilarCategories({ playlist }: SimilarCategoriesProps) {
  const translate = useTranslation()
  return (
    <li>
      <img src={playlist.image.small} alt='cover' className='similar-categories__image-cover' />
      {translate(playlist.title)}
      <ArrowRight className='similar-categories__arrow-icon' width={24} height={24} />
    </li>
  );
}

function PopularPlaylists({ playlist }: PopularPlaylistsProps) {
  const translate = useTranslation()
  return (
    <>
      <div className='popular-playlists__cover'>
        <img src={playlist.image.medium} alt='cover' className='popular-playlists__cover-img' />
        <div className='popular-playlists__cover-title'>{translate(playlist.title)}</div>
        <img src={Logo} alt='logo' width={24} height={24} className='popular-playlists__logo' />
      </div>
      <div className='popular-playlists__title'>{translate(playlist.title)}</div>
    </>
  );
}

export default function CoverPlaylist({ playlist, isSimilarCategories }: CoverPlaylistProps) {
  return (
    <NavLink to={`/render${playlist.routing}`}>
      {isSimilarCategories ? (
        <SimilarCategories playlist={playlist} /> 
      ) : (
        <PopularPlaylists playlist={playlist} /> 
      )}
    </NavLink>
  );
}
