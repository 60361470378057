import React from 'react';

export function CrossIcon() {
  return (
    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.08398" width="62.814" height="0.848838" transform="rotate(45 1.08398 0)" fill="url(#paint0_linear_7710_7937)"/>
      <rect x="0.483398" y="44.416" width="62.814" height="0.848838" transform="rotate(-45 0.483398 44.416)" fill="url(#paint1_linear_7710_7937)"/>
      <defs>
        <linearGradient id="paint0_linear_7710_7937" x1="-7.13467" y1="0.848944" x2="63.898" y2="0.848944" gradientUnits="userSpaceOnUse">
          <stop stopColor="#000DFF"/>
          <stop offset="1" stopColor="#EE0392"/>
        </linearGradient>
        <linearGradient id="paint1_linear_7710_7937" x1="-7.73526" y1="45.265" x2="63.2974" y2="45.265" gradientUnits="userSpaceOnUse">
          <stop stopColor="#000DFF"/>
          <stop offset="1" stopColor="#EE0392"/>
        </linearGradient>
      </defs>
    </svg>
  );
}
