import React, { useRef } from 'react';
import { useOutsideClick } from 'hooks';
import { OptionItem } from '../option-item';
import './track-options.scss';



type TrackOptionsProps = {
  menu: any;
  setIsPopUpVisible: (value: boolean) => void;
  selectedItem: string;
  setSelectedItem: (value: string) => void;
};

export function TrackOptions({ menu,  setIsPopUpVisible, selectedItem, setSelectedItem }: TrackOptionsProps) {
  const timoutRef = useRef(null);
  const nodeRef = useRef(null);
  // const [selectedItem, setSelectedItem] = useState(null);

  const trackOptionsRef = useRef(null);
  useOutsideClick(
    nodeRef,
    () => {
      setIsPopUpVisible(false);

      if (timoutRef.current) {
        clearTimeout(timoutRef.current);
      }

      timoutRef.current = setTimeout(() => {
        setSelectedItem(null);
      }, 250);
    },
    (target) => {
      return !trackOptionsRef.current || !trackOptionsRef.current.contains(target);
    }
  );

  if (!menu) {
    return null;
  }

  const mainOptions = Object.keys(menu).map((item) => ({
    title: menu[item].name,
    value: menu[item].name
  }));



  return (
    <>
      <div className='track-options' ref={trackOptionsRef}>
        {mainOptions.map((option) => (
          <OptionItem
            active={option.value === selectedItem}
            key={option.value}
            onClick={() => {
              if (timoutRef.current) {
                clearTimeout(timoutRef.current);
              }

              if (selectedItem === option.value) {
                setIsPopUpVisible(false);
                timoutRef.current = setTimeout(() => {
                  setSelectedItem(null);
                }, 250);
              } else {
                setIsPopUpVisible(true);
                setSelectedItem(option.value);
              }
            }}
            title={option.title}
          />
        ))}
      </div>

    </>
  );
}
