import React, { Children, cloneElement, ReactNode, ReactElement } from 'react';
import classNames from 'utils/class-names';

import './PurchaseFieldset.scss'

type PurchaseFieldsetProps = {
  children: ReactNode;
  className?: string;
};

export function PurchaseFieldset({
  children,
  className: propClassName,
}: PurchaseFieldsetProps) {
  const className = classNames({
    'purchase-fieldset': true,
    [propClassName]: !!propClassName
  });

  return (
    <div className={className}>
      {Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          const existingClassName = child.props.className || '';
          const newClassName = `${existingClassName} purchase-fieldset__item`.trim();

          return cloneElement(child as ReactElement<{ className: string }>, {
            className: newClassName
          });
        }
        return child;
      })}
    </div>
  );
}
