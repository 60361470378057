import React from 'react';
import classNames from 'utils/class-names';
import './table.scss';

type TableProps = {
  className?: string;
  rowKey: string | ((item: any) => string);
  dataSource: any[];
  getRowClassName?: (item: any) => string;
  showDataHeader?: boolean;
  columns: {
    dataIndex: string;
    className?: string;
    dataHeader?: string;
    width?: string | number;
    minWidth?: string | number;
    maxWidth?: string | number;
    render: (name: any, record: any, index?: number) => React.ReactNode;
  }[];
};

export function Table({
  className: propClassName,
  columns,
  dataSource,
  rowKey,
  showDataHeader,
  getRowClassName
}: TableProps) {
  const className = classNames({
    'app-table-wrapper': true,
    [propClassName]: !!propClassName
  });

  return (
    <div className={className}>
      <table className='app-table'>
        <thead>
          <tr>
            {columns.map((column, index) => {
              return (
                <th
                  key={`${column.dataIndex}-${index}`}
                  style={{
                    width: column.width,
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth
                  }}
                  className={column.className}
                >
                  {showDataHeader && column.dataHeader}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {dataSource.map((item, index) => {
            const customClassName = getRowClassName ? getRowClassName(item) : '';
            const rowClassName = classNames({
              'app-table-row': true,
              [customClassName]: !!customClassName
            });

            return (
              <tr key={typeof rowKey === 'string' ? item[rowKey] : rowKey(item)} className={rowClassName} >
                {columns.map((column, index) => {
                  const columnClassName = classNames({
                    'app-table-cell': true,
                    [column.className]: !!column.className
                  });

                  return (
                    <td key={column.dataIndex} className={columnClassName}>
                      {column.render(item[column.dataIndex], item, index)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
