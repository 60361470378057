import React, { useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import classNames from 'utils/class-names';
import Hand4 from 'assets/img/Hand4.png';
import { Button } from 'layout/components/app-button';
import { IconBase } from 'layout/components/tracklist/IconBase';
import { DeleteIcon } from 'layout/components/icons';
import { Input } from 'layout/components/input';
import './generate-by-image.scss';
import { NewBadge } from 'layout/components/icons/new-badge';
import useTranslation from 'hooks/useTranslations';

export type ImageLoadStatus = 'idle' | 'loading' | 'failed' | 'success';

type GenerateByImageLayoutProps = {
  onAddUrl: (url: string) => void;
  onUploadFile: () => void;
  onRemoveFile: () => void;
  onDrop: (e: any) => void;
  onFinish: () => void;
  imageLoadStatus: ImageLoadStatus;
  imgPreview?: string | null;
};

export function GenerateByImageLayout({
  onUploadFile,
  onAddUrl,
  onRemoveFile,
  onFinish,
  onDrop,
  imgPreview,
  imageLoadStatus
}: GenerateByImageLayoutProps) {
  const [dragActive, setDragActive] = React.useState(false);
  const isMediumScreen = useMediaQuery({ maxWidth: 767 });
  const mainContentClassName = classNames({
    'generate-by-image__main': true,
    'generate-by-image__main_filled': !!imgPreview,
    'generate-by-image__main_hovered': dragActive
  });
  const inputRef = useRef<any>(null);
  const translate = useTranslation();

  const handleNextClick = () => {
    onAddUrl(inputRef.current?.value);
  };

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    onDrop(e);
  };

  return (
    <div className='generate-by-image__content'>
      <div className='generate-by-image__heading'>
        {translate("Image-to-music")}
        <NewBadge className='generate-by-image__new'/> 
      </div>
      <div className={mainContentClassName}>
        {imgPreview ? (
          <img src={imgPreview} alt='preview' className='generate-by-image__preview-img' />
        ) : (
          <>
            <img src={Hand4} alt='hand-image' className='generate-by-image__main-img' />
            <div
              className='generate-by-image__main-content'
              onDrop={onDrop}
              onDragEnter={handleDrag}
            >
              <div className='generate-by-image__main-text'>
                {translate(`Drag and drop`)}
                <br />
                {translate(`the image here`)}
  
                {!isMediumScreen && (
                  <div>
                    {translate(`you can use CTRL + V (CMD + V) to insert`)}
                    <br />
                    {translate(`image here`)}
                  </div>
                )}
              </div>
              <Button className='generate-by-image__upload-button' onClick={onUploadFile}>
              {translate(`Upload png, jpg or webp`)} 
              </Button>
            </div>
          </>
        )}
        {dragActive && (
          <div
            className='generate-by-image__drop-area'
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </div>
      <div className='generate-by-image__footer'>
        {imgPreview ? (
          <>
            <IconBase onClick={onRemoveFile} className='generate-by-image__icon-button'>
              <DeleteIcon />
            </IconBase>
            <Button className='generate-by-image__generate-button' onClick={onFinish}>
             {translate(`Continue`)}  
            </Button>
          </>
        ) : (
          <>
            <div className='generate-by-image__input-wrapper'>
              <Input
                id='insert-input'
                placeholder={translate(`Enter the address of the image`)}
                ref={inputRef}
                error={imageLoadStatus === 'failed'}
              />
              {imageLoadStatus === 'failed' && (
                <div className='generate-by-image__error-text'> {translate(`Failed to load the image`)}</div>
              )}
            </div>
            <Button
              className='generate-by-image__next-button'
              onClick={handleNextClick}
              disabled={imageLoadStatus === 'loading'}
            >
              {translate('Next')} 
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
