import React from 'react';
import { NavLink } from 'layout/components/nav-link';
import { IBreadcrumb } from 'interfaces';

type Props = {
  breadcrumbs: IBreadcrumb[];
};

const Breadcrumb = ({ route, title }) => {
  return (
    <li className='item'>
      {route ? (
        <NavLink className='link' replace to={route === '/' ? '/render' : `/render/${route}`}>
          {title}
        </NavLink>
      ) : (
        <span className='link'>{title}</span>
      )}
    </li>
  );
};

const Breadcrumbs = ({ breadcrumbs = [] }: Props) => {
  const breadcrumbList = breadcrumbs.map(({ title, route }: IBreadcrumb, index) => {

    return (
      <React.Fragment key={index}>
        <Breadcrumb title={title} route={index < breadcrumbs.length - 1 ? route : ''} />
        {index < breadcrumbs.length - 1 && (
          <li style={{padding: '0 6px'}}>
            <span>{'/'}</span>
          </li>
        )}
      </React.Fragment>
    );
  });

  if (breadcrumbList.length === 0) {
    return null;
  }

  return <ul className='page-breadcrumbs'>{breadcrumbList}</ul>;
};

export default Breadcrumbs;
