import React from 'react';
import { Link } from 'layout/components/link';
import { Button } from 'layout/components/app-button';
import { useInitForm } from 'hooks';
import { Field } from 'layout/components/field';
import { Input } from 'layout/components/input';

type SignInProps = {
  onSubmit: (values: any) => void;
  disabled?: boolean;
};

type ForwardRefProps = {
  setError: (name: string, error: any) => void;
};

const SignIn: React.ForwardRefRenderFunction<ForwardRefProps, SignInProps> = (
  { onSubmit, disabled },
  ref
) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useInitForm(ref);



  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field label='Email' error={errors.email?.message}>
        <Input
          {...register('email', {
            required: 'The field is required'
          })}
        />
      </Field>
      <Field label='Password' error={errors.password?.message}>
        <Input
          type='password'
          {...register('password', {
            required: 'The field is required'
          })}
        />
      </Field>
      <p className='text-forget'>
        <Link to='forget'>Forgot your password?</Link>
      </p>
      <div className='session-wrapper-footer'>
        <Button
          block={false}
          type='primary'
          htmlType='submit'
          className='submitButton'
          disabled={disabled}
        >
          Log In
        </Button>
      </div>
    </form>
  );
};

export const SignInForm = React.forwardRef(SignIn);
