import { useDispatch } from 'react-redux';
import { trackPopupModal } from 'utils/metrics';
import { ModalNames } from 'constants/modal-names';
import { hideModal, showModal } from '../redux/modals/actions';

type ShowAttributionInfoPopupProps = {
  trackId: string;
  onComplete?: () => Promise<void>;
  licenseName: string;
};

export function useShowAttributionInfoPopup() {
  const dispatch = useDispatch();
  return ({ licenseName, trackId, onComplete }: ShowAttributionInfoPopupProps) => {
    trackPopupModal({
      event: 'popup_ambassador_show',
      trackId
    });

    dispatch(
      showModal({
        name: ModalNames.AttributionInfo,
        large: true,
        maskClosable: false,
        onComplete: async () => {
          trackPopupModal({
            event: 'popup_ambassador_agree',
            trackId
          });

          dispatch(hideModal());

          if (onComplete) {
            await onComplete();
          }
        },
        onClose: async () => {
          trackPopupModal({
            event: 'popup_ambassador_close',
            trackId
          });
        },
        additionalProps: {
          licenseName,
          trackId
        }
      })
    );
  };
}
