import React from 'react';
import { Redirect } from 'layout/components/redirect';
import { useSelectIsLoggedIn } from '../../../redux/login/selectors';

type InternalRouteWrapperProps = {
  children: React.ReactNode;
  redirectUrl?: string;
};

export function InternalRouteWrapper({
  children,
  redirectUrl = '/render/sign-in'
}: InternalRouteWrapperProps) {
  const isLoggedIn = useSelectIsLoggedIn();

  if (!isLoggedIn) {
    return <Redirect to={redirectUrl} />;
  }

  return <>{children}</>;
}
