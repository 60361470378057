import { useEffect, useState } from 'react';
import { POLLING_TIMEOUT } from 'constants/common';
import { useGetGeneratedTrackListQuery } from '../redux/api/tracks';

export function useFetchGeneratedTrackList(outerDownloaded?: boolean) {
  const [downloaded, setDownloaded] = useState(false);
  const [pollingInterval, setPollingInterval] = useState(null);

  const { data, isLoading, refetch } = useGetGeneratedTrackListQuery(
    { page: 1, add_downloaded: outerDownloaded !== undefined ? outerDownloaded : downloaded },
    {
      pollingInterval: pollingInterval
    }
  );
  const isTrackGenerating = !!data?.tracks?.find((item) => item.status_track === 0);

  useEffect(() => {
    const interval = isTrackGenerating ? POLLING_TIMEOUT : null;
    setPollingInterval(interval);
  }, [isTrackGenerating]);

  return {
    data: data?.tracks || [],
    isLoading,
    isTrackGenerating,
    refetch,
    setDownloaded,
    downloaded
  };
}
