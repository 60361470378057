export function downloadFile(url: string, trackName?: string) {
  const uniqueAnchorId = `download-link-${Date.now()}-${Math.random()}`;
  const a = document.createElement('a');
  a.href = url;
  a.download = ''; // Empty string forces download even without specifying the file name
  a.id = uniqueAnchorId;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
