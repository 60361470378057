import React from 'react';
import { useDispatch } from 'react-redux';
import { useGetWhatsNewQuery } from '../../../../redux/api/whatsnew';
import { showModal } from '../../../../redux/modals/actions';
import { ArrowRight } from '../../icons/arrow-right';
import { ModalNames } from 'constants/modal-names';
import { NewBadge } from 'layout/components/icons/new-badge';
import './WhatsNewContent.scss';

import { unixToDdMmYyyy } from 'utils/unixTimestampTransform';
import { useLanguage } from 'hooks';

type WhatsNewContentProps = {
  onCloseDropdown: () => void;
  isNew: (date: number) => boolean;
  handleNewsClicked: (news: any) => void;
};

export function WhatsNewContent({
  onCloseDropdown,
  isNew,
  handleNewsClicked
}: WhatsNewContentProps) {
  const { data = [] } = useGetWhatsNewQuery(); 
  const language = useLanguage();
  const dispatch = useDispatch();

  return (
    <div className='whatsnew-content'>
      {data.map((news, index) => {
        return (
          <div
            className='whatsnew-content__item'
            key={index}
            onClick={() => {
              onCloseDropdown();
              handleNewsClicked(news);
              dispatch(
                showModal({
                  name: ModalNames.WhatsNewModal,
                  small: true,
                  big: true,
                  additionalProps: {
                    news
                  }
                })
              );
            }}
          >
            <div className='whatsnew-content__info'>
              <div className='whatsnew-content__header'>
                <div className='whatsnew-content__date'>{unixToDdMmYyyy(news.date)}</div>
                {isNew(news.date) && (
                  <NewBadge className='whatsnew-content__new' />
                )}
              </div>
              <div className='whatsnew-content__title'>{news.title[language]}</div>
              <div className='whatsnew-content__text'>{news.preview_description[language]}</div>
            </div>
            <ArrowRight className='whatsnew-content__arrow' />
          </div>
        );
      })}
    </div>
  );
}
