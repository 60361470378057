import { useDispatch } from 'react-redux';
import { trackPopupModal } from 'utils/metrics';
import { ModalNames } from 'constants/modal-names';
import { hideModal, showModal } from '../redux/modals/actions';
import { useUserSocialLinkAddMutation } from '../redux/api/social-links';

type ShowChannelsInfoPopupProps = {
  trackId: string;
  onComplete?: () => Promise<void>;
  onClose?: () => Promise<void>;
};

export const useShowChannelsInfoPopup = () => {
  const [addLink] = useUserSocialLinkAddMutation();
  const dispatch = useDispatch();

  return ({ trackId, onComplete, onClose }: ShowChannelsInfoPopupProps) => {
    trackPopupModal({
      event: 'popup_channels_show',
      trackId
    });

    dispatch(
      showModal({
        name: ModalNames.ChannelInfo,
        large: true,
        maskClosable: false,
        onComplete: async (data) => {
          trackPopupModal({
            event: 'popup_channels_continue',
            trackId
          });

          const { socialNetwork, url } = data;
          const result = (await addLink({ type: socialNetwork, link: url })) as any;

          if (result?.data?.status === 1) {
            if (onComplete) {
              await onComplete();
            }

            dispatch(hideModal());
          }
        },
        onClose: async () => {
          trackPopupModal({
            event: 'popup_channels_close',
            trackId
          });

          if (onClose) {
            await onClose();
          }
        },
        additionalProps: { trackId, isModal: true }
      })
    );
  };
};
