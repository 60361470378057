import React, { useState } from 'react';
import { IMenuItemSub } from 'interfaces';
import {Row, Col} from 'layout/components/grid';
import { NavLink } from 'layout/components/nav-link';
import { Link } from 'layout/components/link';
import useTranslation from 'hooks/useTranslations';
import './BottomNav.scss';

const scrollTop = () => {
  window.scrollTo({top: 0, behavior: 'smooth'});
};

const getSub = (sub, translate) => {
  return (
    <ul>
      {
        sub.map((item: IMenuItemSub, i: number) => {
          return (
            <li key={`sub-${i}`}>
              <NavLink
                to={`/render${item.routing}`}
                className='item-link'
                onClick={scrollTop}
                activeClassName='active'
                replace
              >
                <span className='link-text'>{translate(item.title)}</span>
              </NavLink>
            </li>
          );
        })
      }
    </ul>
  )
};

const BottomNav = ({ data }) => {
  const [showMore, setShowMore] = useState(false);
  const translate = useTranslation();

  if (!data) {
    return null;
  }

  const handleClick = () => {
    setShowMore(!showMore);
  }

  const col = Math.floor(24 / data.length);

  const rows = data.map((item) => {
    const numberOfItems = showMore ? item.sub.length : 10
    return (
      <Col key={item.title} span={col * 2} lg={col}>
        <div className='bottom-nav-col__header'>
          <Link
            to={`/render${item.routing}`}
            className='bottom-nav-col__header-link'
            onClick={scrollTop}
          >
            {translate(`${item.title.charAt(0).toUpperCase() + item.title.substring(1)}`)}
          </Link>
        </div>
        {
          item.sub && (
            <div className='bottom-nav-col__content'>
              {getSub(item.sub.slice(0, numberOfItems), translate)}
              {item.sub.length > 10 && (
                <a className='showLess-more-link' onClick={handleClick}>
                  {showMore ? '' : translate('and more')}
                </a>
              )}
            </div>
          )
        }
      </Col>
    );
  });

  return (
    <div className='bottom-nav-wrap'>
      <div className='title-footer'>Royalty-free music catalog</div>
      <Row>
        {rows}
      </Row>
      <a className='showLess-more-link' onClick={handleClick}>
        {showMore ? translate('Show less') : ''}
      </a>
    </div>
  );
};

export default BottomNav;
