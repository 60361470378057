import React from 'react';
import { Controller } from 'react-hook-form';
import { useInitForm } from 'hooks';
import { Field } from 'layout/components/field';
import { Input } from 'layout/components/input';
import { Checkbox as MyCheckbox } from 'layout/components/checkbox';
import { Button } from 'layout/components/app-button';

type SignUpFormProps = {
  onSubmit: (values: any) => void;
  disabled: boolean;
};

type ForwardRefProps = {
  setError: (name: string, error: any) => void;
};

const SignUp: React.ForwardRefRenderFunction<ForwardRefProps, SignUpFormProps> = (
  { onSubmit, disabled }: SignUpFormProps,
  ref
) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useInitForm(ref);


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field label='Email' error={errors.email?.message}>
        <Input
          {...register('email', {
            required: 'The field is required'
          })}
          error={!!errors.email}
        />
      </Field>
      <Field label='Password' error={errors.password?.message}>
        <Input
          type='password'
          {...register('password', {
            required: 'The field is required',
            maxLength: {
              value: 30,
              message: 'The password should be 8-30 characters long'
            },
            minLength: {
              value: 8,
              message: 'The password should be 8-30 characters long'
            }
          })}
          error={!!errors.password}
        />
      </Field>
      <br />
      <Field error={errors.policy?.message}>
        <Controller
          control={control}
          name='policy'
          rules={{ required: 'The field is required' }}
          render={({ field }) => (
            <MyCheckbox value={field.value} onChange={field.onChange} error={!!errors.policy}>
              I allow the website to collect and store the data I submit through this form
            </MyCheckbox>
          )}
        />
      </Field>
      <div className='session-wrapper-footer signup-sbt'>
        <Button
          block={false}
          type='primary'
          htmlType='submit'
          className='submitButton'
          disabled={disabled}
        >
          Create account
        </Button>
      </div>
    </form>
  );
};

export const SignUpForm = React.forwardRef(SignUp);
