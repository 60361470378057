import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { createTextMaskInputElement } from 'text-mask-core';
import { Input, InputProps } from 'layout/components/input';

type MaskedInputProps = Omit<InputProps, 'onChange'> & {
  mask: any[];
  onChange: (value: string, e: any) => void;
}

export const MaskedInput = React.forwardRef<any, MaskedInputProps>(({mask, value, onChange, ...restProps}, ref) => {
  const maskRef = useRef(null);
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState('');

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current?.focus();
    },
    resetSelection: () => {
      if (inputRef.current) {
        inputRef.current.selectionStart = 0;
        inputRef.current.selectionEnd = 0;
      }
    }
  }));

  useEffect(() => {
    if (inputRef.current) {
      maskRef.current = createTextMaskInputElement({
        mask,
        inputElement: inputRef.current,
        guide: false,
        showMask: false,
        // placeholderChar: PLACEHOLDER_CHAR,
        rawValue: '',
        currentCaretPosition: 0,
        previousConformedValue: '',
      });
    }
  }, [mask]);

  useEffect(() => {
    if (maskRef.current && inputRef.current) {
      maskRef.current.update(value);
      setInputValue(inputRef.current.value);
    }
  }, [value]);

  return (
    <Input
      {...restProps}
      ref={inputRef}
      value={inputValue}
      onChange={(e) => {
        maskRef.current.update(e.target.value);
        setInputValue(inputRef.current.value);

        onChange(inputRef.current.value, e);
      }}
    />
  );
});
