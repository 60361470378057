import React, { ReactNode, useRef } from 'react';
import classNames from 'utils/class-names';
import './Navbar.scss';

export type NavProps = {
  className?: string;
  children?: ReactNode;
};

const Navbar = ({
  children,
  className = '',
}: NavProps) => {
  const containerRef = useRef(null);

  const navClasses = classNames({
    [className]: !!className,
    navbar: true
  });

  return (
    <div ref={containerRef} className={navClasses}>
      <div className='navbar-wrap'>{children}</div>
    </div>
  );
};

export default Navbar;
