import { IRoute } from 'interfaces';
import loadable from '@loadable/component';
import DefaultPage from './Default';

const PricingPage = loadable(() => import('./PricingPage/PricingPage'));
const TrackPage = loadable(() => import('./TrackPage/TrackPage'));
const StreamersPage = loadable(() => import('./StreamersPage'));
const MyGeneratedTracks = loadable(() => import('./MyGeneratedTracks'));
const MyDownloadsPage = loadable(() => import('./MyDownloadsPage'));
const ProfilePage = loadable(() => import('./ProfilePage'));
const TagsPage = loadable(() => import('./TagsPage'));
const SubcategoryPage = loadable(() => import('./SubcategoryPage'));
const CategoryPage = loadable(() => import('./CategoryPage'));
const CategoriesPage = loadable(() => import('./CategoriesPage'));
const ArtistsPage = loadable(() => import('./ArtistsPage'));
const ArtistPage = loadable(() => import('./ArtistPage'));
const FAQPage = loadable(() => import('./FAQPage'));

const DEFAULT_BREAD_CRUMBS = [
  {
    title: 'Home',
    route: '/'
  }
];

export const routes: IRoute[] = [
  {
    path: 'artists',
    component: ArtistsPage,
    exact: true,
    getBreadcrumbs: () => [
      ...DEFAULT_BREAD_CRUMBS,
      {
        title: 'Artists',
        route: 'artists'
      }
    ]
  },
  {
    path: 'artists/:id',
    component: ArtistPage
  },
  {
    path: 'pricing',
    component: PricingPage,
    exact: true,
    getBreadcrumbs: () => [
      ...DEFAULT_BREAD_CRUMBS,
      {
        title: 'Pricing',
        route: '/render/pricing'
      }
    ]
  },
  {
    path: 'my-downloads',
    component: MyDownloadsPage,
    internal: true,
    exact: true,
    getBreadcrumbs: () => [
      ...DEFAULT_BREAD_CRUMBS,
      {
        title: 'My Downloads',
        route: '/render/my-downloads'
      }
    ]
  },
  {
    path: 'streamers',
    component: StreamersPage,
    exact: true,
    getBreadcrumbs: () => [
      ...DEFAULT_BREAD_CRUMBS,
      {
        title: 'Endless Streams',
        route: '/render/streamers'
      }
    ]
  },
  {
    path: 'faq',
    component: FAQPage,
    exact: true,
    getBreadcrumbs: () => [
      ...DEFAULT_BREAD_CRUMBS,
      {
        title: 'FAQ',
        route: '/render/faq'
      }
    ]
  },
  {
    path: 'profile',
    component: ProfilePage,
    internal: true,
  },
  {
    path: 'my-generated-tracks',
    component: MyGeneratedTracks,
    exact: true,
    getBreadcrumbs: () => [
      ...DEFAULT_BREAD_CRUMBS,
      {
        title: 'My generated tracks',
        route: '/render/my-generated-tracks'
      }
    ]
  },
  {
    path: 'tracks/:id',
    component: TrackPage,
    exact: true
  },
  {
    path: 'tags/:id',
    component: TagsPage
  },
  {
    path: ':categories/:category/:subcategory',
    component: SubcategoryPage,
    exact: true
  },
  {
    path: ':categories/:category',
    exact: true,
    component: CategoryPage
  },
  {
    path: ':categories',
    exact: true,
    component: CategoriesPage
  },
  {
    path: '',
    exact: true,
    component: DefaultPage,
    getBreadcrumbs: () => []
  }
];
