import { RefObject, useEffect } from 'react';

export const useOutsideClick = (ref: RefObject<HTMLElement>, callback: () => void, additionalCheck?: (target: any) => boolean) => {
  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      if (!additionalCheck || additionalCheck(event.target)) {
        callback();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};
