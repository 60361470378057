import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'layout/components/nav-link';
import { Tooltip } from '../tooltip';
import './track-name-dropdown.scss';

type TrackNameDropdownProps = {
  record: any;
  name: string;
  status?: any;
};

export function TrackNameDropdown({ record, name, status }: TrackNameDropdownProps) {
  const textRef = useRef(null);
  const [showOverlay, setShowOverlay] = useState<boolean>(false);

  useEffect(() => {
    const getTextWidth = () => {
      if (textRef.current) {
        const width = textRef.current.offsetWidth;

        setShowOverlay(width < textRef.current.scrollWidth);
      }
    };

    getTextWidth();

    window.addEventListener('resize', getTextWidth);

    return () => {
      window.removeEventListener('resize', getTextWidth);
    };
  }, []);

  return (
    <Tooltip
      showOverlay={showOverlay}
      text={name}
    >
      <div ref={textRef} className='track-name-dropdown__wrapper'>
        {record.status_track || status ? (
          <NavLink className='track-name' to={`/render/tracks/${record.session_id}`}>
            {name}
          </NavLink>
        ) : (
          <span className='track-name'>{name}</span>
        )}
      </div>
    </Tooltip>
  );
}
