import React from 'react';
import classNames from 'utils/class-names';
import './camera-icon.scss';

type CameraIconProps = {
  onClick?: () => void;
};

export function CameraIcon({ onClick }: CameraIconProps) {

  const className = classNames({
    'camera-icon': true
  });

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      className={className}
      onClick={onClick}
    >
      <path
        d='M23.5 3.5H23.7C25.4913 3.5 26.387 3.5 26.9435 4.0565C27.5 4.61299 27.5 5.50866 27.5 7.3V7.5'
        stroke='var(--main-text-color)'
        strokeLinecap='round'
      />
      <path
        d='M23.5 26.5H23.7C25.4913 26.5 26.387 26.5 26.9435 25.9435C27.5 25.387 27.5 24.4913 27.5 22.7V22.5'
        stroke='var(--main-text-color)'
        strokeLinecap='round'
      />
      <path
        d='M6.5 3.5H6.3C4.50866 3.5 3.61299 3.5 3.0565 4.0565C2.5 4.61299 2.5 5.50866 2.5 7.3V7.5'
        stroke='var(--main-text-color)'
        strokeLinecap='round'
      />
      <path
        d='M6.5 26.5H6.3C4.50866 26.5 3.61299 26.5 3.0565 25.9435C2.5 25.387 2.5 24.4913 2.5 22.7V22.5'
        stroke='var(--main-text-color)'
        strokeLinecap='round'
      />
      <path
        d='M6 12.5639C6 11.5621 6.81213 10.75 7.81394 10.75V10.75C8.49141 10.75 9.11242 10.3725 9.42427 9.77105L10.213 8.25C10.2985 8.08511 10.3412 8.00266 10.3875 7.92967C10.7215 7.40238 11.2826 7.06129 11.9045 7.00745C11.9905 7 12.0834 7 12.2692 7H17.2308C17.4166 7 17.5095 7 17.5955 7.00745C18.2174 7.06129 18.7785 7.40238 19.1125 7.92967C19.1588 8.00266 19.2015 8.08511 19.287 8.25L20.0757 9.77105C20.3876 10.3725 21.0086 10.75 21.6861 10.75V10.75C22.6879 10.75 23.5 11.5621 23.5 12.5639V17.1786C23.5 18.879 23.5 19.7291 23.1653 20.3769C22.8798 20.9296 22.4296 21.3798 21.8769 21.6653C21.2291 22 20.379 22 18.6786 22H10.8214C9.12105 22 8.27086 22 7.62305 21.6653C7.0704 21.3798 6.62017 20.9296 6.33466 20.3769C6 19.7291 6 18.879 6 17.1786V12.5639Z'
        stroke='var(--main-text-color)'
      />
      <circle cx='14.75' cy='15.75' r='3.25' stroke='var(--main-text-color)' />
    </svg>
  );
}
