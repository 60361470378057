import { IAppState } from 'interfaces';
import React from 'react';
import { useSelector } from 'react-redux';

type ArtistConfirmedIconProps = {
  className?: string
}

export function ArtistConfirmedIcon({className}: ArtistConfirmedIconProps) {
    const  isDarkMode = useSelector((state: IAppState) => state.settings.isDarkTheme);

  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
      <path
        d='M12.7498 1.98161C14.111 -0.660536 17.8887 -0.660536 19.2499 1.98161C20.0679 3.56923 21.9131 4.33357 23.6141 3.78931C26.4449 2.88357 29.1163 5.55477 28.2104 8.3856C27.6661 10.0866 28.4304 11.9319 30.0181 12.7498C32.6603 14.111 32.6603 17.8887 30.0181 19.2499C28.4304 20.0679 27.6661 21.9131 28.2104 23.6141C29.1163 26.4449 26.4449 29.1163 23.6141 28.2104C21.9131 27.6661 20.0679 28.4304 19.2499 30.0181C17.8887 32.6603 14.111 32.6603 12.7498 30.0181C11.9319 28.4304 10.0866 27.6661 8.3856 28.2104C5.55477 29.1163 2.88357 26.4449 3.78931 23.6141C4.33357 21.9131 3.56923 20.0679 1.98161 19.2499C-0.660536 17.8887 -0.660536 14.111 1.98161 12.7498C3.56923 11.9319 4.33357 10.0866 3.78931 8.3856C2.88357 5.55477 5.55477 2.88357 8.3856 3.78931C10.0866 4.33357 11.9319 3.56923 12.7498 1.98161Z'
        fill={isDarkMode ? '#008dff' :'#000DFF' }
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.5055 11.3062C23.0565 11.8376 23.0724 12.7149 22.5411 13.2659L14.416 21.692L9.45859 16.551C8.92728 16 8.94323 15.1226 9.49422 14.5913C10.0452 14.0601 10.9226 14.076 11.4539 14.627L14.416 17.6988L20.5458 11.3419C21.0771 10.7909 21.9545 10.7749 22.5055 11.3062Z'
        fill='white'
      />
    </svg>
  );
}
