import React from 'react';
import classNames from 'utils/class-names';
import './license-active.scss';

type LicenseActiveIconProps = {
  pending?: boolean;
};

export function LicenseActiveIcon({ pending }: LicenseActiveIconProps) {
  const className = classNames({
    'license-active-icon': true,
    'license-active_pending': !!pending
  });

  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M5.5 7.5C5.5 5.61438 5.5 4.67157 6.08579 4.08579C6.67157 3.5 7.61438 3.5 9.5 3.5H11.8431C12.6606 3.5 13.0694 3.5 13.4369 3.65224C13.8045 3.80448 14.0935 4.09351 14.6716 4.67157L17.3284 7.32843C17.9065 7.90649 18.1955 8.19552 18.3478 8.56306C18.5 8.9306 18.5 9.33935 18.5 10.1569V16.5C18.5 18.3856 18.5 19.3284 17.9142 19.9142C17.3284 20.5 16.3856 20.5 14.5 20.5H9.5C7.61438 20.5 6.67157 20.5 6.08579 19.9142C5.5 19.3284 5.5 18.3856 5.5 16.5V7.5Z'
        fill='url(#paint0_linear_10523_2868)'
      />
      <path
        d='M12.5 3.5V7.1C12.5 7.94008 12.5 8.36012 12.6635 8.68099C12.8073 8.96323 13.0368 9.1927 13.319 9.33651C13.6399 9.5 14.0599 9.5 14.9 9.5H18.5'
        stroke='white'
      />
      <path
        d='M15.8977 13.5009C15.5597 13.5009 15.3843 13.2595 15.1249 12.8639C14.8728 12.4791 14.5586 12 13.9301 12C13.3017 12 12.9874 12.4791 12.7353 12.8639C12.4759 13.2577 12.3005 13.5009 11.9625 13.5009C11.6246 13.5009 11.4492 13.2595 11.1898 12.8639C10.9377 12.4791 10.6234 12 9.99498 12C9.36652 12 9.0523 12.4791 8.80018 12.8639C8.54076 13.2577 8.36538 13.5009 8.0274 13.5009C8.01827 13.5009 8.00913 13.5009 8 13.4991V14C8.00913 14 8.01827 14 8.0274 14C8.65586 14 8.97008 13.5209 9.2222 13.1361C9.48161 12.7423 9.657 12.4991 9.99498 12.4991C10.333 12.4991 10.5083 12.7405 10.7678 13.1361C11.0199 13.5209 11.3341 14 11.9625 14C12.591 14 12.9052 13.5209 13.1573 13.1361C13.4168 12.7423 13.5921 12.4991 13.9301 12.4991C14.2681 12.4991 14.4435 12.7405 14.7029 13.1361C14.955 13.5209 15.2692 14 15.8977 14C15.9324 14 15.9671 13.9982 16 13.9946V13.49C15.9671 13.4973 15.9324 13.5009 15.8977 13.5009Z'
        fill='white'
      />
      <defs>
        <linearGradient
          id='paint0_linear_10523_2868'
          x1='4.95833'
          y1='17.6667'
          x2='19.6027'
          y2='17.7704'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#04B662' />
          <stop offset='1' stopColor='#69C7DB' />
        </linearGradient>
      </defs>
    </svg>
  );
}
