import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Controller } from 'react-hook-form';
import { useInitForm } from 'hooks';
import { Button } from 'layout/components/app-button';
import { useMediaQuery } from 'react-responsive';
import './refined-generate-track.scss';
import { Field } from 'layout/components/field';
import { useGetTrackMusicTreeQuery } from '../../../redux/api/track-music-tree';
import { Textarea } from 'layout/components/textarea';

import { CameraIcon, CloseIcon } from 'layout/components/icons';

import { TrackOptions } from './track-options';

import { AppSelect } from 'layout/components/app-select';
import { TRACK_TYPES, TRACK_HINT, JINGLE_HINT, LOOP_HINT } from 'constants/track-types';
import { IconBase } from 'layout/components/tracklist/IconBase';
import { TimeInput } from 'layout/components/time-input';
import { ImageDropdown } from 'layout/components/image-dropdown';
import { TrackOptionsDropdown } from './track-options-dropdown';
import { showModal } from '../../../redux/modals/actions';
import { ModalNames } from 'constants/modal-names';
import { storage } from 'utils/storage';
import useTranslation from 'hooks/useTranslations';
import SelectArrow from 'assets/icons/select-arrow.svg';

type GenerateTrackProps = {
  onSubmit: (values: any) => void;
  className?: string;
  isLoading?: boolean;
  buttonText?: string;
  isPopupVisible?: boolean;
  setIsPopUpVisible?: (value: boolean) => void;
  onOptionSelected?: (value: string) => void;
  disabled?: boolean;
};

type ForwardRefProps = {
  setError: (name: string, error: any) => void;
};

function trackValueNormalization(value?: string) {
  if (!value) {
    return value;
  }

  return value.replace(/[^A-Za-z\s0-9\.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g, '');
}

const useIsImageFeatureViewed = () => {
  const [isImageFeatureViewed, setIsImageFeatureViewed] = useState(true);

  useEffect(() => {
    if (storage.getItem('image-feature-viewed') !== 'true') {
      setIsImageFeatureViewed(false);
    }
  }, []);

  const handleSetIsImageFeatureViewed = useCallback(
    (value) => {
      storage.setItem('image-feature-viewed', value);
      return setIsImageFeatureViewed(value);
    },
    [setIsImageFeatureViewed]
  );

  return [isImageFeatureViewed, handleSetIsImageFeatureViewed] as const;
};

const RefinedGenerateTrack: React.ForwardRefRenderFunction<ForwardRefProps, GenerateTrackProps> = (
  {
    onSubmit,
    onOptionSelected,
    isLoading = false,
    buttonText = 'Make a Track Now',
    className,
    isPopupVisible,
    setIsPopUpVisible,
    disabled
  },
  ref
) => {
  const [isImageFeatureViewed, setIsImageFeatureViewed] = useIsImageFeatureViewed();
  const isMediumScreen = useMediaQuery({ maxWidth: 767 });
  const isButtonShouldBeHidden = useMediaQuery({ maxWidth: 1199 }) && isPopupVisible;
  const { data: menu } = useGetTrackMusicTreeQuery();
  const [selectedValue, setSelectedValue] = useState<string>(null);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [isInputClicked, setIsInputClicked] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [hasContent, setHasContent] = useState(false);
  const secondsInputRef = useRef<any | null>(null);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    formState: { errors, isSubmitted },
    control,
    setValue,
    watch,
    getValues,
    clearErrors,
    trigger
  } = useInitForm(ref, {
    defaultValues: {
      minutes: '00',
      seconds: '45',
      trackType: 'track'
    }
  });
  const translate = useTranslation();

  const trackType = watch('trackType');

  const hints = {
    jingle: JINGLE_HINT,
    loop: LOOP_HINT,
    default: TRACK_HINT
  };

  const durationHint = translate(hints[trackType] || hints.default);

  const trackLimits = {
    jingle: { maxMinutes: 0, maxSeconds: 45 },
    loop: { maxMinutes: 5, maxSeconds: undefined },
    default: { maxMinutes: 25, maxSeconds: undefined }
  };

  const { maxMinutes, maxSeconds } = trackLimits[trackType] || trackLimits.default;

  useEffect(() => {
    if (isSubmitted) {
      trigger('minutes');
      trigger('seconds');
    }
  }, [durationHint, trigger, isSubmitted]);

  function handleTrackOptionSelect({ value, categories }: { value: string; categories: string[] }) {
    setSelectedValue(value);
    setSelectedImage(null);
    setSelectedCategories(categories);
    setValue('prompt', '');
    setValue('category', categories[0]);
    setValue('playlist', value);
    clearErrors();
    onOptionSelected?.(value);
  }

  function handleTrackOptionDelete() {
    setSelectedImage(null);
    setSelectedValue(null);
    setSelectedCategories([]);
    setValue('prompt', '');
    setValue('category', '');
    setValue('playlist', '');
  }

  const TRANSLATED_TRACK_TYPES = TRACK_TYPES.map(({ title, value }) => ({
    title: translate(title),
    value
  }));

  return (
    <form
      onSubmit={handleSubmit((data) =>
        onSubmit({
          ...(data as any),
          file: selectedImage
        })
      )}
      className={className}
    >
      <div className='refined-generate-track-form'>
        <Field label={`1. ${translate('Enter prompt or upload image')}`} error={!!errors.prompt}>
          <Controller
            name='prompt'
            control={control}
            rules={{
              validate: (value) => {
                if (getValues('playlist') || value || selectedImage) {
                  return true;
                }

                return 'Field is required';
              }
            }}
            render={({ field }) => (
              <div className='refined-generate-track-form__prompt-input-container'>
                <Textarea
                  {...field}
                  disabled={!!selectedValue || !!selectedImage}
                  className='refined-generate-track-form__prompt-input'
                  placeholder={
                    selectedValue || selectedImage ? null : translate('Type anything in English')
                  }
                  onChange={(e) => {
                    field.onChange(trackValueNormalization(e.target.value));
                  }}
                  error={!!errors.prompt}
                  autoComplete='off'
                />
                <div className='refined-generate-track-form__camera-btn'>
                  {isMediumScreen ? (
                    <IconBase
                      onClick={() => {
                        if (!isImageFeatureViewed) {
                          setIsImageFeatureViewed(true);
                        }

                        dispatch(
                          showModal({
                            name: ModalNames.GenerateByImageModal,
                            extraSmall: true,
                            showMask: false,
                            additionalProps: {
                              onSubmit: (value: any) => {
                                setSelectedImage(value);
                                setSelectedValue(null);
                                setSelectedCategories([]);
                                setValue('prompt', '');
                                setValue('category', '');
                                setValue('playlist', '');
                              }
                            }
                          })
                        );
                      }}
                      className={isImageFeatureViewed ? '' : 'table-icon-base_calling-to-action'}
                    >
                      <CameraIcon />
                    </IconBase>
                  ) : (
                    <ImageDropdown
                      onOpen={() => {
                        if (!isImageFeatureViewed) {
                          setIsImageFeatureViewed(true);
                        }
                      }}
                      onSubmit={(value: any) => {
                        setSelectedImage(value);
                        setSelectedValue(null);
                        setSelectedCategories([]);
                        setValue('prompt', '');
                        setValue('category', '');
                        setValue('playlist', '');
                      }}
                    >
                      <IconBase
                        className={isImageFeatureViewed ? '' : 'table-icon-base_calling-to-action'}
                      >
                        <CameraIcon />
                      </IconBase>
                    </ImageDropdown>
                  )}
                </div>
                {selectedImage && (
                  <div className='refined-generate-track-form__selected-categories'>
                    <div className='refined-generate-track-form__selected-item'>
                      {translate('Image is selected')}
                    </div>
                    <div>
                      <IconBase onClick={handleTrackOptionDelete}>
                        <CloseIcon />
                      </IconBase>
                    </div>
                  </div>
                )}
                {selectedCategories.length > 0 && (
                  <div className='refined-generate-track-form__selected-categories'>
                    {selectedCategories.map((item, i) => (
                      <div
                        className='refined-generate-track-form__selected-item'
                        key={`${item}_${i}`}
                      >
                        {translate(`${item}`)}
                      </div>
                    ))}
                    <div>
                      <IconBase onClick={handleTrackOptionDelete}>
                        <CloseIcon />
                      </IconBase>
                    </div>
                  </div>
                )}
              </div>
            )}
          />
          <div className='refined-generate-track-form__suggestions-block'>
            <span className='generator-choose'>{translate('or choose')}:</span>
            <TrackOptions
              menu={menu}
              setIsPopUpVisible={setIsPopUpVisible}
              selectedItem={selectedItem}
              setSelectedItem={(value) => {
                setSelectedItem(value);
                onOptionSelected?.(value);
              }}
            />
          </div>
        </Field>
        {!isButtonShouldBeHidden && (
          <>
            <Field label={`2. ${translate('Set type')}`}>
              <Controller
                name='trackType'
                control={control}
                render={({ field }) => (
                  <AppSelect
                    renderDropDownToBody
                    isNewSelector
                    options={TRANSLATED_TRACK_TYPES}
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    customArrow={<img src={SelectArrow} alt='select' />}
                  />
                )}
              />
            </Field>
            <Field
              label={`3. ${translate('Set duration')}`}
              hint={durationHint}
              error={errors.minutes?.message || errors.seconds?.message}
            >
              <div className='refined-generate-track-form__duration  generator__duration'>
                <TimeInput
                  maxMinutes={maxMinutes}
                  maxSeconds={maxSeconds}
                  errorMessage={durationHint}
                  control={control}
                  isInputClicked={isInputClicked}
                  setIsInputClicked={setIsInputClicked}
                  secondsInputRef={secondsInputRef}
                />
              </div>
            </Field>
            <Field
              label={
                <span>
                  <br />
                </span>
              }
              className='refined-generate-track-form__button-field'
            >
              <Button
                type='primary'
                htmlType='submit'
                className='refined-generate-track-form__button'
                loading={isLoading}
                disabled={isLoading || disabled}
                block
              >
                {buttonText}
              </Button>
            </Field>
          </>
        )}
      </div>
      {isPopupVisible && (
        <TrackOptionsDropdown
          menu={menu}
          selectedItem={selectedItem}
          setSelectedItem={(value) => {
            setSelectedItem(value);
            onOptionSelected?.(value);
          }}
          setIsPopUpVisible={setIsPopUpVisible}
          onSelectOption={handleTrackOptionSelect}
        />
      )}
    </form>
  );
};

RefinedGenerateTrack.displayName = 'RefinedGenerateTrack';

export const RefinedGenerateTrackForm = React.forwardRef(RefinedGenerateTrack);

RefinedGenerateTrackForm.displayName = 'RefinedGenerateTrackForm';
