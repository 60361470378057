import React from 'react';
import { ISinglePurchasePricing } from 'interfaces';
import { getPriceTitle } from 'utils/price-helpers';
import { PurchaseOption } from './PurchaseOption';
import { PurchaseButtons } from './PurchaseButtons';
import { PurchaseFieldset } from './PurchaseFieldset';
import useTranslation from 'hooks/useTranslations';

type TrackOptionsProps = {
  items: ISinglePurchasePricing[];
};

export const TrackOptions = ({ items }: TrackOptionsProps) => {
  const translate = useTranslation();
  return (
    <PurchaseFieldset>
      {items.map((item) => {
        return (
          <PurchaseOption
            id={item.priceId}
            key={item.name}
            name={item.name}
            description={item.popupDescription}
            currentTier={item.currentTier}
            price={item.currentTier ? translate('Free') : translate(getPriceTitle(item))}
            isActive={item.currentTier}
          />
        );
      })}
      <PurchaseButtons fullWidth={items.length % 2 === 0}>
        <PurchaseButtons.Cancel />
        <PurchaseButtons.Submit />
      </PurchaseButtons>
    </PurchaseFieldset>
  );
};
