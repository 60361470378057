import React from 'react';
import classNames from 'utils/class-names';
import './progress-bar.scss';

type ProgressBarProps = {
  progress?: number;
}

export function ProgressBar({progress}: ProgressBarProps) {
  const progressValue = !progress || progress <= 10 ? 10 : progress;
  const className = classNames({
    'progress-bar': true
  });

  return (
    <div className={className}>
      <div className='progress-bar-inner'>
        <div className='progress-bar-bg' style={{ width: `${progressValue}%` }} />
      </div>
    </div>
  );
}
