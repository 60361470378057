import React, { useEffect, useState } from 'react';
import './search-progress.scss';
import { ProgressBar } from 'layout/components/progress-bar';
import { GradientText } from 'layout/components/gradient-text';
import classNames from 'utils/class-names';

type SearchProgressProps = {
  progress?: number;
  text: string;
  visible?: boolean;
}

function useIsMounted(visible: boolean): boolean {
  const [isMounted, setIsMounted] = useState<boolean>(visible);

  useEffect(() => {
    let timeout;
    if (!visible) {
      setTimeout(() => {
        setIsMounted(visible);
      }, 1000);
    } else {
      setIsMounted(visible);
    }

    return () =>  {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [visible]);

  return isMounted;
}

export function SearchProgress({progress, text, visible}: SearchProgressProps) {
  const isMounted = useIsMounted(visible);
  const className = classNames({
    'search-progress': true,
    'search-progress_visible': visible
  });

  if (!isMounted) {
    return null;
  }

  return (
    <div className={className}>
      <ProgressBar progress={progress} />
      <div className='search-progress__description'>
        <GradientText>{text || 'Sending data'}</GradientText>
      </div>
    </div>
  );
}
