import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GenerateByImageLayout, ImageLoadStatus } from './generate-by-image-layout';
import { hideModal } from '../../../../../redux/modals/actions';

type GenerateByImageProps = {
  onSubmit?: (value: any) => void;
};

export function GenerateByImage({ onSubmit }: GenerateByImageProps) {
  const dispatch = useDispatch();
  const [file, setFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [imageLoadStatus, setImageLoadStatus] = useState<ImageLoadStatus>('idle');

  useEffect(() => {
    const onPaste = async (e) => {
      if (e.target.id === 'insert-input') {
        return;
      }
      console.log('call onPaste');
      e.preventDefault();

      const clipboardItems = e.clipboardData.files;

      for (const clipboardItem of clipboardItems) {
        let blob;
        // For files from `navigator.clipboard.read()`.

        if (clipboardItem.type?.startsWith('image/')) {
          blob = clipboardItem;
          setFile(blob as any);
          setImageUrl(null);
        }
      }
    };

    document.addEventListener('paste', onPaste);

    return () => document.removeEventListener('paste', onPaste);
  }, []);

  const inputRef = useRef<any>();
  const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files[0]);
  };
  const handleRemoveFile = () => {
    setFile(null);
    setImageUrl(null);
    setImageLoadStatus('idle');
    inputRef.current.value = '';
  };

  const handleAddUrl = (value: string) => {
    if (value !== imageUrl) {
      setImageUrl(value);
      setImageLoadStatus('loading');
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
    setImageUrl(null);
  };

  const handleFinish = () => {
    dispatch(hideModal());
    onSubmit?.(imageUrl || file);

    setTimeout(() => {
      setImageUrl(null);
      setFile(null);
    }, 1000);
  };

  const imgPreview = useMemo(() => {
    if (imageLoadStatus === 'success' && imageUrl) {
      return imageUrl;
    }

    if (!file) {
      return null;
    }

    return URL.createObjectURL(file);
  }, [file, imageLoadStatus, imageUrl]);

  return (
    <>
      <input
        style={{ display: 'none' }}
        ref={inputRef}
        accept='image/png, image/jpeg, image/webp'
        type='file'
        onChange={handleUploadFile}
      />
      <GenerateByImageLayout
        onRemoveFile={handleRemoveFile}
        onUploadFile={() => {
          if (inputRef.current) {
            inputRef.current.click();
          }
        }}
        onFinish={handleFinish}
        onDrop={handleDrop}
        onAddUrl={handleAddUrl}
        imgPreview={imgPreview}
        imageLoadStatus={imageLoadStatus}
      />
      <img
        alt='test'
        src={imageUrl}
        style={{ display: 'none' }}
        onLoad={() => {
          setImageLoadStatus('success');
        }}
        onError={() => {
          setImageLoadStatus('failed');
        }}
      />
    </>
  );
}
