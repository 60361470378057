import React from 'react';
import {Row, Col} from 'layout/components/grid';

const SoundPlayerLoader = () => (
  <div className='sound-player-skeleton' style={{display: 'flex'}}>
    <Row align='middle'>
      <Col span={24} md={12}>
        <ul className='page-breadcrumbs'>
          <li className='item bg-1 animated-bg' />
          <li className='item bg animated-bg' />
        </ul>
      </Col>

      <Col span={24} md={12}>
        <div className='info justify-content-md-end'>
          <div className='version bg animated-bg' />
          <div className='settings animated-bg' />
        </div>
      </Col>
    </Row>
  </div>
);

export default SoundPlayerLoader;
