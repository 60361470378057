import { useEffect, useState } from 'react';
import { POLLING_TIMEOUT } from 'constants/common';
import  { useGetSimilarSearchQuery } from '../redux/api/similar-search';

export function useFetchSimSearchTracks() {
  const [pollingInterval, setPollingInterval] = useState(null);

  const { data, isFetching, refetch } = useGetSimilarSearchQuery(undefined, {
    pollingInterval: pollingInterval
  });
  const isTracksSearching = data && typeof data.progress !== 'undefined' && data.progress !== 100;

  useEffect(() => {
    const interval = isTracksSearching ? POLLING_TIMEOUT : null;
    setPollingInterval(interval);
  }, [isTracksSearching]);


  return {data, isFetching, isTracksSearching, refetch};
}
