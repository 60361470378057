import React from 'react';
import classNames from 'utils/class-names';
import './checkbox.scss';

type CheckboxProps = {
  children: React.ReactNode;
  value?: boolean;
  onChange?: (value: boolean) => void;
  error?: boolean;
};

export function Checkbox({children, value, onChange, error}: CheckboxProps) {
  const className = classNames({
    'app-checkbox': true,
    'app-checkbox_checked': !!value,
    'app-checkbox_error': !!error
  });

  return (
    <div className={className} onClick={() => onChange(!value)}>
      <div className='app-checkbox__element-wrapper'>
        <input type='checkbox' checked={value} />
        <div className='app-checkbox__inner' />
      </div>
      <div className='app-checkbox__content'>
        {children}
      </div>
    </div>
  )
}
