import React from 'react';
import BetaSign from 'assets/icons/beta-sign-new.svg';
import './search-by-reference-title.scss';
import useTranslation from 'hooks/useTranslations';

type SearchByReferenceTitleProps = {
  onClick?: () => void;
  className?: string;
  forwardRef?: React.RefObject<HTMLDivElement>;
};

export function SearchByReferenceTitle({
  onClick,
  className,
  forwardRef
}: SearchByReferenceTitleProps) {
  const translate = useTranslation()
  return (
    <div onClick={onClick} className={`search-by-reference-title ${className}`}>
      <span ref={forwardRef}>{translate("Search by reference")}</span> <img alt='' src={BetaSign} />
    </div>
  );
}
