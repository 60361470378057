import React from 'react';
import TurnstileComponent from 'react-turnstile';
import { TURNSTILE_SITE_KEY } from 'constants/common'
import classNames from 'utils/class-names';
import { useSelector } from 'react-redux';
import { IAppState } from 'interfaces';

import './turnstile.scss';


type TurnstileProps = {
  onLoad?: () => void;
  onVerify: (token: string) => void;
  onError?: (err: any) => void;
  fullWidth?: boolean;
  hidden?: boolean;
  onBeforeInteractive?: () => void;
};

export function Turnstile({ onVerify, onError, fullWidth, hidden, onBeforeInteractive, onLoad}: TurnstileProps) {
  const className = classNames({
    turnstile: true,
    'turnstile_full-width': !!fullWidth,
    'turnstile_hidden': !!hidden
  })

  const isDarkTheme = useSelector((state: IAppState) => state.settings.isDarkTheme);
  
  return (
    <TurnstileComponent
      theme={isDarkTheme ? 'dark' : 'light'} 
      className={className}
      fixedSize={true}
      onVerify={onVerify}
      sitekey={TURNSTILE_SITE_KEY}
      onError={onError}
      onBeforeInteractive={onBeforeInteractive}
      onLoad={onLoad}
    />
  );
}
