import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  useSelectAvailableSubscriptions,
  useSelectAvailableTrackPrices,
  useSelectBestHighestAvailableSubscription,
  useSelectPricesMap
} from '../../../redux/pricing-info/selectors';
import { hideModal } from '../../../redux/modals/actions';
import { setPurchase } from '../../../redux/purchase/actions';
import { stripeSubscribe, stripeOrder } from '../../../redux/purchase/actions';

import { useAppSelector, useSortedForColumnsDisplay } from 'hooks';

import { trackAddToMyDownloadsOrPurchase } from 'utils/metrics';
import { getPriceTitle } from 'utils/price-helpers';
import { PriceType, SubscriptionPeriod } from 'interfaces';
import { SINGLE_TRACK_TYPEFORM, SUBSCRIPTION_TYPEFORM } from 'constants/common';
import { Tabs } from 'layout/components/tabs';
import { SubscriptionSwitcher } from 'layout/components/subscription-switcher';
import { AvailableSubscription } from './AvailableSubscription';
import { TrackOptions } from './TrackOptions';
import { SubscriptionOptions } from './SubscriptionOptions';
import useTranslation from 'hooks/useTranslations';
import { useDefaultPrice } from './useDefaultPrice';
import { usePush } from 'hooks/usePush';

import './purchase.scss';

const { TabPane } = Tabs;

export function Purchase() {
  const dispatch = useDispatch();
  const loggedIn = useAppSelector((state) => state.loggedIn);
  const purchase = useAppSelector((state) => state.purchase.data);
  const pricesMap = useSelectPricesMap();
  const subscriptions = useSelectAvailableSubscriptions();
  const singlePurchases = useSelectAvailableTrackPrices();
  const defaultPrice = useDefaultPrice();
  const push = usePush();
  const methods = useForm({
    defaultValues: {
      licence: defaultPrice ? defaultPrice.priceId : undefined
    }
  });
  const [activeTab, setActiveTab] = useState('single-purchase');

  const handleCancel = () => {
    dispatch(hideModal());
  };

  const onFinish = async (values) => {
    const selectedPriceId = values['licence'];
    const entityToPurchase = pricesMap[selectedPriceId];
    const isBlackFriday = activeTab === 'holiday-deal';

    if (!entityToPurchase) {
      return;
    } else {
      const isAbleToDownload =
        entityToPurchase &&
        (entityToPurchase.isActive ||
          entityToPurchase.currentTier ||
          getPriceTitle(entityToPurchase).toLowerCase() === 'free');

      trackAddToMyDownloadsOrPurchase({
        elementClicked: 'pricing_popup_button',
        trackId: entityToPurchase.type === PriceType.SINGLE_PURCHASE ? purchase.session_id : '',
        trackType: 'unknown',
        trackListIndex: 0,
        pricingOption: entityToPurchase.name,
        event: isAbleToDownload ? 'add_to_my_downloads_click' : 'purchase_click'
      });

      if (entityToPurchase.email) {
        const link =
          entityToPurchase.type === PriceType.SINGLE_PURCHASE
            ? SINGLE_TRACK_TYPEFORM
            : SUBSCRIPTION_TYPEFORM;

        window.open(link, '_blank', 'noopener,noreferrer');
        return;
      }

      if (entityToPurchase.type === PriceType.SINGLE_PURCHASE) {
        if (!loggedIn) {
          dispatch(setPurchase({ price_id: selectedPriceId, session_id: purchase.session_id }));
          dispatch(hideModal());
          push('/render/sign-up');
        } else {
          await dispatch(
            stripeOrder({
              sessionId: purchase.session_id,
              priceId: selectedPriceId,
              callback: handleCancel
            })
          );
        }
      } else {
        if (!loggedIn) {
          dispatch(
            setPurchase({
              price_id: selectedPriceId,
              is_black_friday: isBlackFriday,
              session_id: purchase.session_id
            })
          );
          dispatch(hideModal());
          push('/render/sign-up');
        } else if (entityToPurchase.isActive) {
          await dispatch(
            stripeOrder({
              sessionId: purchase.session_id,
              priceId: selectedPriceId,
              callback: handleCancel
            })
          );
        } else {
          await dispatch(
            stripeSubscribe({
              priceId: selectedPriceId,
              sessionId: purchase.session_id,
              isBlackFriday
            })
          );
        }
      }
    }
  };



  const isLoggedIn = useAppSelector((state) => state.loggedIn);
  const availableSubscription = useSelectBestHighestAvailableSubscription();

  const translate = useTranslation();
  const [priceType, setPriceType] = useState<'Monthly' | 'Annual'>('Monthly');
  const subscriptionsToShow = subscriptions.filter(
    (item) => item.period === (priceType.toLowerCase() as SubscriptionPeriod)
  );
  const sortedSubscriptions = useSortedForColumnsDisplay(subscriptionsToShow)
  const sortedPurchases = useSortedForColumnsDisplay(singlePurchases);

  useEffect(() => {
    methods.reset();
  }, [activeTab, methods]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onFinish)}>
        {isLoggedIn && availableSubscription && (
          <AvailableSubscription
            avialableSub={availableSubscription}
            priceId={defaultPrice.priceId}
          />
        )}
        <div className='purchase'>
          <Tabs size='large' activeKey={activeTab} onChange={setActiveTab}>
            <TabPane tab={translate('Single Purchase')} key='single-purchase'>
              <TrackOptions items={sortedPurchases} />
            </TabPane>
            <TabPane tab={translate('Upgrade your subscription')} key='subscriptions'>
              <SubscriptionSwitcher
                isTrackToggle
                initialValue={priceType}
                onChange={(value) => setPriceType(value)}
              />
              <SubscriptionOptions items={sortedSubscriptions} />
            </TabPane>
          </Tabs>
        </div>
      </form>
    </FormProvider>
  );
}
