import { storage } from 'utils/storage';
import { useEffect, useState } from 'react';
import { TURNTILE_TOKEN_KEY } from 'constants/cookies-names';
import { useDispatch } from 'react-redux';
import { setTurnstileToken, turnstileIsLoaded } from '../redux/cloudfare/actions';
import { useAppSelector } from 'hooks/store';

export const useShowTurnstile = () => {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (!storage.getItem(TURNTILE_TOKEN_KEY)) {
      setShow(true)
    } else {
      dispatch(turnstileIsLoaded());
    }
  }, [dispatch]);

  return show;
}

export const useTurnstileToken = () => {
  const token = useAppSelector(state => state.turnstileToken.token);
  const dispatch = useDispatch()

  useEffect(() => {
    if (storage.getItem(TURNTILE_TOKEN_KEY)) {
      dispatch(setTurnstileToken(storage.getItem(TURNTILE_TOKEN_KEY)));
    }
  }, [dispatch]);

  return token
}