import { SET_TURNSTILE_TOKEN, SET_TURNSTILE_LOADED } from './types';

export const setTurnstileToken = (token: string) => ({
  type: SET_TURNSTILE_TOKEN,
  payload: token
});

export const turnstileIsLoaded = () => ({
  type: SET_TURNSTILE_LOADED,
});
