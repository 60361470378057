import React from 'react';
import { ISubscriptionPricing, SubscriptionPeriod } from 'interfaces';
import { PurchaseOption } from './PurchaseOption';
import { PurchaseButtons } from './PurchaseButtons';
import { PurchaseFieldset } from './PurchaseFieldset';
import './SubscriptionOptions.scss';

type SubscriptionOptionsProps = {
  items: ISubscriptionPricing[];
};

export const SubscriptionOptions = ({ items }: SubscriptionOptionsProps) => {
  return (
    <PurchaseFieldset className='subscription-options'>
      {items.map((item) => {
        const isFree = item.isFree;
        const { priceTitle, expDate, isActive, isCanceled } = item;
        return (
          <PurchaseOption
            key={item.name}
            id={item.priceId}
            name={item.name}
            description={item.popupDescription}
            price={isFree ? 'Free' : `$${priceTitle}/mo`}
            expDate={expDate}
            isActive={isActive}
            isCanceled={isCanceled}
            isFree={item.isFree}
          />
        );
      })}
      <PurchaseButtons fullWidth={items.length % 2 === 0}>
        <PurchaseButtons.Cancel />
        <PurchaseButtons.Submit />
      </PurchaseButtons>
    </PurchaseFieldset>
  );
};
