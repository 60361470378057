import React, { CSSProperties } from 'react';
import classNames from 'utils/class-names';
import './col.scss'

type ColProps = {
  span?: number;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
  className?: string;
  style?: CSSProperties;
  children: React.ReactNode;
};

export function Col({span, xs, sm, md, lg, xl, xxl, children, className: propClassName, style}: ColProps) {
  const className = classNames({
    'app-col': true,
    [`app-col-${span}`]: !!span,
    [`app-col-xs-${xs}`]: !!xs,
    [`app-col-sm-${sm}`]: !!sm,
    [`app-col-md-${md}`]: !!md,
    [`app-col-lg-${lg}`]: !!lg,
    [`app-col-xl-${xl}`]: !!xl,
    [`app-col-xxl-${xxl}`]: !!xxl,
    [propClassName]: !!propClassName
  });

  return (
    <div className={className} style={style}>
      {children}
    </div>
  )
}
