import React from 'react';
import './download-license.scss';
import classNames from 'utils/class-names';

type DownloadLicenseProps = {
  disabled?: boolean;
  pending?: boolean;
};

export function DownloadLicenseIcon({ disabled, pending }: DownloadLicenseProps) {
  const className = classNames({
    'download-license-icon': true,
    'download-license-icon_disabled': !!disabled,
    'download-license-icon_pending': !!pending
  });

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
      <path
        d='M7.9 4H12.5059C12.8966 4 13.0104 4.00321 13.1121 4.02763C13.2142 4.05213 13.3117 4.09253 13.4012 4.14736C13.4904 4.20201 13.5731 4.28024 13.8494 4.5565L17.4435 8.15061C17.7198 8.42687 17.798 8.50964 17.8526 8.59882C17.9075 8.68829 17.9479 8.78584 17.9724 8.88788C17.9968 8.98958 18 9.10343 18 9.49411V18.1C18 18.5283 17.9996 18.8194 17.9812 19.0445C17.9633 19.2637 17.9309 19.3758 17.891 19.454C17.7951 19.6422 17.6422 19.7951 17.454 19.891C17.3758 19.9309 17.2637 19.9633 17.0445 19.9812C16.8194 19.9996 16.5283 20 16.1 20H7.9C7.47171 20 7.18056 19.9996 6.95552 19.9812C6.73631 19.9633 6.62421 19.9309 6.54601 19.891C6.35785 19.7951 6.20487 19.6422 6.10899 19.454C6.06915 19.3758 6.03669 19.2637 6.01878 19.0445C6.00039 18.8194 6 18.5283 6 18.1V5.9C6 5.47171 6.00039 5.18056 6.01878 4.95552C6.03669 4.73631 6.06915 4.62421 6.10899 4.54601C6.20487 4.35785 6.35785 4.20487 6.54601 4.10899C6.62421 4.06915 6.73631 4.03669 6.95552 4.01878C7.18056 4.00039 7.47171 4 7.9 4Z'
        stroke='#363C43'
        className='stroke'
      />
      <path
        d='M12.5 3.5V7.1C12.5 7.94008 12.5 8.36012 12.6635 8.68099C12.8073 8.96323 13.0368 9.1927 13.319 9.33651C13.6399 9.5 14.0599 9.5 14.9 9.5H18.5'
        stroke='#363C43'
        className='stroke'
      />
      <path
        d='M15.8977 13.5009C15.5597 13.5009 15.3843 13.2595 15.1249 12.8639C14.8728 12.4791 14.5586 12 13.9301 12C13.3017 12 12.9874 12.4791 12.7353 12.8639C12.4759 13.2577 12.3005 13.5009 11.9625 13.5009C11.6246 13.5009 11.4492 13.2595 11.1898 12.8639C10.9377 12.4791 10.6234 12 9.99498 12C9.36652 12 9.0523 12.4791 8.80018 12.8639C8.54076 13.2577 8.36538 13.5009 8.0274 13.5009C8.01827 13.5009 8.00913 13.5009 8 13.4991V14C8.00913 14 8.01827 14 8.0274 14C8.65586 14 8.97008 13.5209 9.2222 13.1361C9.48161 12.7423 9.657 12.4991 9.99498 12.4991C10.333 12.4991 10.5083 12.7405 10.7678 13.1361C11.0199 13.5209 11.3341 14 11.9625 14C12.591 14 12.9052 13.5209 13.1573 13.1361C13.4168 12.7423 13.5921 12.4991 13.9301 12.4991C14.2681 12.4991 14.4435 12.7405 14.7029 13.1361C14.955 13.5209 15.2692 14 15.8977 14C15.9324 14 15.9671 13.9982 16 13.9946V13.49C15.9671 13.4973 15.9324 13.5009 15.8977 13.5009Z'
        fill='#363C43'
        className='fill'
      />
    </svg>
  );
}
