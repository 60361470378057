import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { IBreadcrumb } from 'interfaces';
import Breadcrumbs from 'layout/components/footer/Breadcrumbs';

type PageWithBreadcrumbsProps = {
  children: React.ReactNode;
  getBreadcrumbs?: (params: any) => IBreadcrumb[];
};

export function PageWithBreadcrumbs({children, getBreadcrumbs}: PageWithBreadcrumbsProps) {
  const locationParams = useParams();
  const location = useLocation();
  const breadcrumbs = getBreadcrumbs ? getBreadcrumbs({
    params: locationParams,
    location
  }) : null;

  return (
    <>
      {
        breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />
      }
      {children}
    </>
  )
}
