import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useLocation } from 'hooks/useLocation';
import { NavLink } from 'layout/components/nav-link';
import { IMenuItem, IMenuItemSub } from 'interfaces';
import { useOutsideClick } from 'hooks';
import StarPremium from 'assets/icons/star.svg';
import './Menu.scss';
import {
  useSelectIsLoggedIn,
  useSelectIsSubscriptionsPresent
} from '../../../redux/login/selectors';
import FireIcon from 'assets/icons/fire.png';
import RockeIcon from 'assets/icons/Rocket.png';
import GamepadIcon from 'assets/icons/gamepad.png';
import { TrackCount } from 'layout/components/track-count';
import { DownloadIcon } from 'layout/components/icons';
import { LogoGenerateIcon } from '../icons/logo-generate-icon';
import useTranslation from 'hooks/useTranslations';
import { MUBERT_API_URL } from 'constants/common';

type MenuProps = {
  data?: IMenuItem[];
};

const haveActive = (sub: IMenuItemSub[], route: string) =>
  !!sub.find((item) => item.routing === route);

export const Menu = ({ data }: MenuProps) => {
  const location = useLocation();
  const initialMenu = useMemo(() => {
    const currentRoute = location.pathname.split('/').slice(-2).join('/');
    const updatedMenu = data
      ? data
          .filter((x) => !!x)
          .map((item) => {
            if (item.sub) {
              return { ...item, active: haveActive(item.sub, currentRoute) };
            }

            return { ...item, active: item.routing === currentRoute };
          })
      : [];

    return updatedMenu;
  }, [location, data]);
  const [menu, setMenu] = useState<IMenuItem[]>(initialMenu);
  const loggedIn = useSelectIsLoggedIn();
  const isSubscriptionsPresent = useSelectIsSubscriptionsPresent();
  const translate = useTranslation();

  useEffect(() => {
    const currentRoute = location.pathname.split('/').slice(-2).join('/');
    const updatedMenu = data
      ? data
          .filter((x) => !!x)
          .map((item) => {
            if (item.sub) {
              return { ...item, active: haveActive(item.sub, currentRoute) };
            }

            return { ...item, active: item.routing === currentRoute };
          })
      : [];

    setMenu(updatedMenu);
  }, [location, data]);

  const menuItems = menu.map((item: IMenuItem, i: number) => {
    return (
      <li className='menu-item' key={i}>
        <NavLink
          className='item-link'
          to={`/render${item.routing}`}
          activeClassName='active'
          replace
        >
          {translate(`${item.title}`)}
        </NavLink>
      </li>
    );
  });

  const containerRef = useRef(null);

  const handleClickOutside = () => {
    const updatedMenu = menu.map((item) => ({ ...item, active: false }));

    setMenu(updatedMenu);
  };

  useOutsideClick(containerRef, handleClickOutside);

  return (
    <div className='main-menu' ref={containerRef}>
      {!!menuItems.length && (
        <nav>
          <div className='menu-ul'>
            <ul className='menu-ul__card_first-card menu-ul__card'>
              <li>
                <NavLink to='/render/my-generated-tracks'>
                  <div className='menu-ul__icon'>
                    <LogoGenerateIcon />
                  </div>
                  {translate('My generated tracks')}
                  <TrackCount />
                </NavLink>
              </li>
              {loggedIn && (
                <li>
                  <NavLink to='/render/my-downloads'>
                    <div className='menu-ul__icon'>
                      <DownloadIcon />
                    </div>
                    {translate('My downloads')}
                  </NavLink>
                </li>
              )}
              {!isSubscriptionsPresent && (
                <li>
                  <NavLink to='/render/pricing'>
                    <div className='menu-ul__icon'>
                      <img src={StarPremium} alt='prem' width={16} height={16} />
                    </div>
                    <span>{translate('Go Premium')}</span>
                  </NavLink>
                </li>
              )}
            </ul>
            <div className='menu-ul__card explore'>
              <div className='menu-ul__heading'>{translate('EXPLORE')}</div>
              <ul>{menuItems}</ul>
            </div>
            <div className='menu-ul__card menu-ul__card_beta'>
              <div className='menu-ul__heading'>BETA</div>
              <NavLink to='/render/artists' className='item-link' activeClassName='active'>
                <div className='menu-ul__icon'>
                  <img src={FireIcon} alt='fire-icon' width={18} height={18} />
                </div>
                <span>{translate('Artists')}</span>
              </NavLink>
              <NavLink to='/render/streamers' className='item-link' activeClassName='active'>
                <div className='menu-ul__icon'>
                  <img src={GamepadIcon} alt='endless-streams-icon' width={15} height={15} />
                </div>
                <span>{translate('Endless Stream')}</span>
              </NavLink>
            </div>
            <a className='menu-ul__card card_api' href={MUBERT_API_URL} >
              <div className='item-link'>
                <div className='menu-ul__icon'>
                  <img src={RockeIcon} alt='rocket-icon' width={18} height={18} />
                </div>
                <span>{translate('Mubert API')}</span>
              </div>
            </a>
          </div>
        </nav>
      )}
    </div>
  );
};
