import React from 'react';

export const fmtMSS = (s) => {
  return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
};


export const renderDuration = (duration) => {
  return <span className='track-duration'>{fmtMSS(duration)}</span>;
};
