import { useSelector } from 'react-redux';
import { IAppState } from '../../interfaces/app-state';


export function useSelectIsSubscriptionsPresent() {
  const loggedIn = useSelector<IAppState, object>((state) => state.loggedIn);
  return Boolean(loggedIn && loggedIn['subs'] && Object.keys(loggedIn['subs']).length);
}

export function useSelectIsLoggedIn() {
  const loggedIn = useSelector<IAppState, object>((state) => state.loggedIn);

  return !!loggedIn;
}
