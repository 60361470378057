import React from 'react';

type BinIconProps = {
  color?: string;
};

export function BinIcon({ color = 'var(--main-text-color)' }: BinIconProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      className='bin-icon'
    >
      <ellipse
        cx='12.5'
        cy='7'
        rx='7'
        ry='3'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path d='M7.5 18L5.5 7L8.5 10H16.5L19.5 7L17.5 18L14.5 20H10.5L7.5 18Z' fill={color} />
      <path
        d='M5.5 7L7.49621 17.9792C7.49868 17.9927 7.50522 18.0052 7.51497 18.015V18.015C10.2681 20.7681 14.7319 20.7681 17.485 18.015V18.015C17.4948 18.0052 17.5013 17.9927 17.5038 17.9792L19.5 7'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
}
