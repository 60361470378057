export const TRACK_TYPES = [
  {
    title: 'track',
    value: 'track'
  },
  {
    title: 'loop',
    value: 'loop'
  },
  {
    title: 'mix',
    value: 'mix'
  },
  {
    title: 'jingle',
    value: 'jingle'
  }
];

export const JINGLE_HINT = 'from 5 sec to 45 sec';
export const LOOP_HINT = 'from 5 sec to 5 min';
export const TRACK_HINT = 'from 5 sec to 25 min';
