import React from 'react';
import classNames from 'utils/class-names';
import './input.scss';

export type InputProps = {
  value?: any;
  id?: string;
  name?: string;
  placeholder?: string;
  type?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  autoComplete?: string;
  className?: string;
  disabled?: boolean;
  onFocus?: () => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  postIcon?: React.ReactNode;
  prefixIcon?: React.ReactNode;
  readonly?: boolean;
};

function InputInternal(
  {
    name,
    placeholder,
    type = 'text',
    onChange,
    value,
    error,
    autoComplete,
    disabled,
    className: classNameProp,
    onFocus,
    onBlur,
    onKeyUp,
    id,
    postIcon,
    prefixIcon,
    readonly,
  }: InputProps,
  ref
) {
  const className = classNames({
    'app-input': true,
    'app-input_error': !!error,
    'app-input app-input__width-prefix': !!prefixIcon,
    [classNameProp]: !!classNameProp

  });

  return (
    <div className='input'>
      {prefixIcon && <div className='app-input__prefix'>{prefixIcon}</div>}
      <input
        ref={ref}
        value={value}
        className={className}
        type={type}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        autoComplete={autoComplete}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        id={id}
        readOnly={readonly}
      />
      {postIcon && <div className='app-input__post'>{postIcon}</div>}
    </div>
  );
}

export const Input = React.forwardRef(InputInternal);
