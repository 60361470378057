import React, { useMemo, useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Collapse } from 'react-collapse';
import { RefinedGenerateTrackForm, SearchTrackForm } from 'layout/forms';
import {
  useAppSelector,
  useFetchGeneratedTrackList,
  useIsGeneratorVisible,
  useFetchSimSearchTracks,
  useLanguage
} from 'hooks';
import './generator.scss';
import TrackList from 'layout/components/tracklist/TrackList';
import { LinkButton } from 'layout/components/link-button';
import { trackGenerateTrack } from 'utils/metrics';
import {
  useCreateTrackMutation,
  useCreateTrackWithFormDataMutation,
} from '../../../redux/api/tracks';
import { useSearchTrackByUrlMutation } from '../../../redux/api/similar-search';
import { addNotification } from '../../../redux/notifications/actions';
import {
  addTrackToGenerating,
  toggleGenerator,
  hideByUser
} from '../../../redux/generator/actions';

import { showModal, showTurnstileModal } from '../../../redux/modals/actions';
import { ModalNames } from 'constants/modal-names';
import { ExpandIcon } from 'layout/components/icons';
import classNames from 'utils/class-names';
import { useLocation } from 'hooks/useLocation';
import { SearchByReferenceTitle } from './search-by-reference-title';
import { SearchProgress } from './search-progress';
import { TrackCount } from '../track-count';
import { useTurnstileToken } from 'hooks/useTurnstileToken';
import useTranslation from 'hooks/useTranslations';
import { usePush } from 'hooks/usePush';

enum GeneratorTabs {
  Generate,
  Search
}

export function Generator({ className: propClassName }: { className?: string }) {
  const [tab, setTab] = useState<GeneratorTabs>(GeneratorTabs.Generate);
  const [selectedInnerOption, setSelectedInnerOption] = useState(null);
  const turnstileTokenRedux = useTurnstileToken();
  const isTurnstileLoaded = useAppSelector((state) => state.turnstileToken.isLoaded);
  const isGeneratorOpen = useIsGeneratorVisible();
  const [loading, setLoading] = useState(false);
  const [isPopupVisible, setIsPopUpVisible] = useState(false);
  const dispatch = useDispatch();
  const { data: tracks, refetch, setDownloaded, downloaded } = useFetchGeneratedTrackList();
  const [createTrack] = useCreateTrackMutation();
  const [createTrackWithFormData] = useCreateTrackWithFormDataMutation();
  const translate = useTranslation();
  const [underlineWidth, setUnderlineWidth] = useState(0);
  const [underlineOffset, setUnderlineOffset] = useState(0);
  const underlineRef = useRef(null);
  const language = useLanguage();

  const {
    data: simSearchData,
    isTracksSearching,
    isFetching: isSimTracksLoading,
    refetch: refetchSimSearch
  } = useFetchSimSearchTracks();
  const searchFormRef = useRef<any>();
  const [searchTrack, { isLoading: isSendSearchRequest }] = useSearchTrackByUrlMutation();
  const generatedTracks = useMemo(() => {
    if (!simSearchData) {
      return {
        tracks: [],
        tracksCount: 0
      };
    }

    return {
      tracks: simSearchData.tracks,
      tracksCount: simSearchData.tracks_count
    };
  }, [simSearchData]);

  const { pathname } = useLocation();
  const topTracks = useMemo(
    () => ({
      tracks: tracks.slice(0, 5)
    }),
    [tracks]
  );

  const handleCloseClick = () => {
    dispatch(hideByUser());
  };

  const handleSearchFormSubmit = async (data) => {
    if (!turnstileTokenRedux) {
      dispatch(
        showTurnstileModal((token) => {
          searchFormSubmit(data, token);
        })
      );
    } else {
      searchFormSubmit(data, turnstileTokenRedux);
    }
  };

  const searchFormSubmit = async (data, token) => {
    searchTrack({
      url: data.linkUrl,
      token
    })
      .then((response: any) => {
        if (response?.data?.data?.code === 2 || response?.data?.data?.code === 4) {
          if (searchFormRef.current !== undefined) {
            searchFormRef.current.setError('linkUrl', {
              type: 'manual',
              message: response.data.data.text
            });
          }
        } else if (response?.data?.error?.code === 'ERROR_CLOUDFLARE') {
          dispatch(
            showTurnstileModal((token) => {
              searchFormSubmit(data, token);
            })
          );
        }
      })
      .catch(() => {
        if (searchFormRef.current !== undefined) {
          searchFormRef.current.setError('linkUrl', {
            type: 'manual',
            message: 'Unknown error. Try again'
          });
        }
      });
  };

  const handleGenerateFormSubmit = async (values: any) => {
    if (!turnstileTokenRedux) {
      dispatch(
        showTurnstileModal((token) => {
          generateFormSubmit(values, token);
        })
      );
    } else {
      generateFormSubmit(values, turnstileTokenRedux);
    }
  };

  const generateFormSubmit = async (values: any, token: string) => {
    try {
      setLoading(true);

      let seconds = +values.minutes * 60 + +values.seconds;
      let payload;

      if (values.file) {
        if (typeof values.file === 'string') {
          payload = {
            duration: seconds,
            trackType: values.trackType as any,
            url: values.file,
            token
          };
        } else {
          payload = new FormData();
          payload.append('duration', seconds);
          payload.append('track_type', values.trackType);
          payload.append('file', values.file);
          payload.append('token', token);
        }
      } else {
        payload = values.prompt
          ? {
              duration: seconds,
              trackType: values.trackType as any,
              text: values.prompt,
              token
            }
          : {
              duration: seconds,
              menuChoice: values.category,
              playlist: values.playlist,
              trackType: values.trackType as any,
              token
            };
      }

      trackGenerateTrack({
        filterCategory: values.category,
        filterSubcategory: values.playlist,
        trackLength: seconds
      });

      const data: any =
        payload instanceof FormData
          ? await createTrackWithFormData(payload)
          : await createTrack(payload);

      if (data?.data?.data) {
        const code = data.data.data.code;

        if (code === 2) {
          dispatch(
            showModal({
              name: ModalNames.UnauthorizedTracksRestrictions,
              small: true
            })
          );
        } else if (code === 4) {
          dispatch(
            showModal({
              name: ModalNames.FreePlanTracksRestrictions,
              small: true,
              additionalProps: {
                message: data.data.data.text
              }
            })
          );
        } else if (code !== 1) {
          dispatch(
            addNotification({
              title: data?.data?.data?.text || 'Error during track generation'
            })
          );
        } else {
          const sessionId = data.data.data.session_id;
          dispatch(addTrackToGenerating(sessionId));
        }
      }

      if (data?.data?.error?.code === 'ERROR_CLOUDFLARE') {
        dispatch(
          showTurnstileModal((token) => {
            generateFormSubmit(values, token);
          })
        );
      }
    } catch (e) {
      console.log(e);

      dispatch(
        addNotification({
          title: 'Error during track generation'
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const className = classNames({
    generator: true,
    generator_closed: !isGeneratorOpen,
    [propClassName]: propClassName
  });

  const headerClassName = classNames({
    generator__header: true,
    'generator__header_sim-search': tab === GeneratorTabs.Search
  });

  const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    const handleResize = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (underlineRef.current) {
      const activeTabElement = underlineRef.current;
      const activeTabWidth = activeTabElement.offsetWidth;
      const activeTabOffset = activeTabElement.offsetLeft;
      setUnderlineWidth(activeTabWidth);
      setUnderlineOffset(activeTabOffset);
    }
  }, [tab, language, screenSize]);
  const push = usePush();

  return (
    <div style={{ flexShrink: 0, width: '100%' }}>
      <div
        className={className}
        onClick={() => {
          if (!isGeneratorOpen) {
            if (pathname === '/render/my-generated-tracks') {
              push('/render');
              setTimeout(() => {
                dispatch(toggleGenerator());
              });
              return;
            }
            dispatch(toggleGenerator());
          }
        }}
      >
        <ExpandIcon
          onClick={handleCloseClick}
          isExpanded={isGeneratorOpen}
          className='generator__expand-icon'
        />
        <div className={headerClassName} style={language === 'ko' ? {justifyContent: 'center'} : {}}>
          <div>
            <span
              className={`generator__gradient-text ${
                tab === GeneratorTabs.Generate ? 'generator__header_active-tab' : ''
              }`}
              onClick={() => {
                setTab(GeneratorTabs.Generate);
              }}
              ref={tab === GeneratorTabs.Generate ? underlineRef : null}
            >
              {translate("Generate track")}
            </span>
          </div>
          <div >
            <SearchByReferenceTitle
              forwardRef = {tab === GeneratorTabs.Search ? underlineRef : null}
              onClick={() => {
                setTab(GeneratorTabs.Search);
              }}
              className={tab === GeneratorTabs.Search ? 'generator__header_active-tab' : ''}
            />
          </div>
          <div className='underline-container'>
            <div
              className='underline'
              style={{
                width: underlineWidth,
                transform: `translateX(${underlineOffset}px)`
              }}
            ></div>
          </div>
        </div>
        <Collapse isOpened={isGeneratorOpen}>
          {tab === GeneratorTabs.Generate && (
            <>
              <RefinedGenerateTrackForm
                onSubmit={handleGenerateFormSubmit}
                isLoading={loading}
                buttonText={translate("Generate track")}
                className='generator__form'
                isPopupVisible={isPopupVisible}
                setIsPopUpVisible={setIsPopUpVisible}
                onOptionSelected={setSelectedInnerOption}
                disabled={!isTurnstileLoaded && !turnstileTokenRedux}
              />
              {topTracks.tracks.length > 0 && !isPopupVisible && (
                <TrackList
                  className='generator__track-list'
                  tracks={topTracks}
                  tab={1}
                  reload={refetch}
                  isGenerator
                  isTrackEditable
                  setDownloaded={setDownloaded}
                  downloaded={downloaded}
                  page={1}
                />
              )}
              {topTracks.tracks.length < tracks.length && !isPopupVisible && (
                <div className='generator__footer'>
                  <TrackCount downloaded={downloaded} />
                  <LinkButton
                    className='generator__all-tracks-btn'
                    onClick={() => push('/render/my-generated-tracks')}
                  >
                    {translate("See all generated tracks")}
                  </LinkButton>
                </div>
              )}
            </>
          )}
          {tab === GeneratorTabs.Search && (
            <div className='search-track-content'>
              <SearchTrackForm
                initialUrl={simSearchData?.url}
                ref={searchFormRef}
                isLoading={isSimTracksLoading || isSendSearchRequest || isTracksSearching}
                disabled={!isTurnstileLoaded && !turnstileTokenRedux}
                onSubmit={handleSearchFormSubmit}
              />
              <SearchProgress
                text={simSearchData?.text}
                progress={simSearchData?.progress}
                visible={isTracksSearching}
              />
              {!isTracksSearching && simSearchData?.tracks?.length > 0 && (
                <TrackList
                  className='generator__track-list'
                  tracks={generatedTracks}
                  tab={null}
                  reload={refetchSimSearch}
                  isGenerator
                  isTrackEditable
                  page={1}
                />
              )}
            </div>
          )}
        </Collapse>
      </div>
    </div>
  );
}
