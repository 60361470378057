import React from 'react';
import { Redirect } from 'layout/components/redirect';
import { useSelectIsLoggedIn } from '../../../redux/login/selectors';

type ExternalRouteWrapperProps = {
  children: React.ReactNode;
  redirectUrl?: string;
};

export function ExternalRouteWrapper({
  children,
  redirectUrl = '/render'
}: ExternalRouteWrapperProps) {
  const isLoggedIn = useSelectIsLoggedIn();

  if (isLoggedIn) {
    return <Redirect to={redirectUrl} />;
  }

  return <>{children}</>;
}
