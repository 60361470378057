import React from 'react';
import { useDispatch } from 'react-redux';
import { DeleteIcon } from 'layout/components/icons';
import { showModal } from '../../../../redux/modals/actions';
import { ModalNames } from 'constants/modal-names';
import useTranslation from 'hooks/useTranslations';
import {
  SocialLinkItem,
  useUserSocialLinkDeleteMutation,
} from '../../../../redux/api/social-links';
import { SOCIAL_LINK_NAMES } from './ConnectChannel';

import './ChannelsList.scss';
import { BinIcon } from 'layout/components/icons/bin-icon';


type ChannelsListProps = {
  data: SocialLinkItem[];
};

export function ChannelsList({ data }: ChannelsListProps) {
  const dispatch = useDispatch();
  const translate = useTranslation();
  const [deleteUserSocialLink] = useUserSocialLinkDeleteMutation();

  return (
    <div className='channels-list'>
      {data.map((item) => (
          <div key={item.id} className='channels-list__item'>
            <div className='channels-list__info'>
              <div className='channels-list__info_type'>{SOCIAL_LINK_NAMES[item.type]}</div>
              <div className='channels-list__info_link'>{item.link}</div>
            </div>

            <div
              className='channels-list__delete'
              onClick={() =>
                dispatch(
                  showModal({
                    name: ModalNames.ConfirmModal,
                    onComplete: () => deleteUserSocialLink(item.id),
                    small: true,
                    extraSmall: true,
                    additionalProps: {
                      title: translate('Delete the link'),
                      icon: BinIcon,
                      content: (
                        <div className='confirm-delete'>
                          <div className='confirm-delete__subtitle'>
                            {translate('Are you sure you want to delete your link?')}
                          </div>
                          <div className='confirm-delete__text'>
                            {translate(
                              'When you unlink the channel, you will not be able to download the track without re-linking'
                            )}
                          </div>
                        </div>
                      ),
                      confirmButtonText: translate('Delete')
                    }
                  })
                )
              }
            >
              <DeleteIcon />
            </div>
          </div>
        ))}
    </div>
  );
}
