import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useInitForm } from 'hooks';
import { Button } from 'layout/components/app-button';
import { Input } from 'layout/components/input';
import './search-track.scss';
import { Field } from 'layout/components/field';
import useTranslation from 'hooks/useTranslations';

type GenerateTrackProps = {
  onSubmit: (values: any) => void;
  className?: string;
  isLoading?: boolean;
  buttonText?: string;
  initialUrl: string;
  disabled?: boolean;
};

type ForwardRefProps = {
  setError: (name: string, error: any) => void;
};

const SearchTrack: React.ForwardRefRenderFunction<ForwardRefProps, GenerateTrackProps> = (
  { onSubmit, isLoading = false, buttonText = 'Search tracks', className, initialUrl, disabled },
  ref
) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useInitForm(ref, {
    defaultValues: {
      linkUrl: initialUrl
    }
  });

  const translate = useTranslation();

  useEffect(() => {
    reset({
      linkUrl: initialUrl
    });
  }, [initialUrl, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={className}>
      <div className='search-track-form'>
        <Field label={`1. ${translate("Input URL")}`} error={errors.linkUrl?.message}>
          <Controller
            name='linkUrl'
            control={control}
            rules={{
              validate: (value) => {
                if (!value) {
                  return 'Field is required';
                }

                if (!value.match(/(https?:\/\/)(www\.)?(youtube\.com|youtu.be)\//i)) {
                  return 'You have to provide link to a youtube video';
                }

                return true;
              }
            }}
            render={({ field }) => (
              <Input
                {...field}
                autoComplete='off'
                disabled={isLoading}
                placeholder={translate('Place here link to youtube video')}
                onChange={(value) => {
                  field.onChange(value);
                }}
                error={!!errors.linkUrl}
              />
            )}
          />
        </Field>
        <Field
          label={
            <span>
              <br />
            </span>
          }
          className='search-track-form__button-field'
        >
          <Button
            type='primary'
            loading={isLoading}
            disabled={disabled}
            className='search-track-form__button'
            block
          >
            {translate(buttonText)}
          </Button>
        </Field>
      </div>
    </form>
  );
};

SearchTrack.displayName = 'SearchTrack';

export const SearchTrackForm = React.forwardRef(SearchTrack);

SearchTrackForm.displayName = 'SearchTrackForm';
