import React from 'react';
import { MoreIcon } from 'layout/components/icons';
import { Dropdown } from 'layout/components/dropdown';
import './MoreDropdown.scss';

type MoreDropdownProps = {
  children: React.ReactNode;
  pending?: boolean;
  style?: React.CSSProperties;
};

export function MoreDropdown({ children, pending, style}: MoreDropdownProps) {
  return (
    <Dropdown
      trigger={['click']}
      placement='topLeft'
      overlay={<div className='more-dropdown' style={style}>{children}</div>}
    >
      <MoreIcon pending={pending} />
    </Dropdown>
  );
}
