import React from 'react';
import { useDispatch } from 'react-redux';
import { Turnstile } from 'layout/components/turnstile/turnstile';
import { storage } from 'utils/storage';
import { setTurnstileToken, turnstileIsLoaded } from '../../../redux/cloudfare/actions';
import { useShowTurnstile } from 'hooks/useTurnstileToken';
import { TURNTILE_TOKEN_KEY } from 'constants/cookies-names';

type TurnstilePageWrapperProps = {
  children: React.ReactNode;
}

export function TurnstilePageWrapper({ children }: TurnstilePageWrapperProps) {
  const dispatch = useDispatch();
  const isTurnstileVisible = useShowTurnstile();

  return (
    <>
      {children}
      {isTurnstileVisible && (
        <Turnstile
          onVerify={(token) => {
            storage.setItem(TURNTILE_TOKEN_KEY, token)
            dispatch(setTurnstileToken(token));
            dispatch(turnstileIsLoaded());
          }}
          hidden={true}
          onError={() => {
            dispatch(turnstileIsLoaded());
          }}
          onBeforeInteractive={() => {
            dispatch(turnstileIsLoaded());
          }}
        />
      )}
    </>
  )
}