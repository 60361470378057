import React from 'react';
import classNames from 'utils/class-names';
import './radio.scss';
import useTranslation from 'hooks/useTranslations';

type RadioProps = {
  name: string;
  value: string;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  children: React.ReactNode;
  disabled?: boolean;
  isActive?: boolean;
};

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  ({ value, checked, onChange, children, name, disabled, isActive }, ref) => {
    const wrapperClassName = classNames({
      'radio-btn-wrapper': true,
      'radio-btn-wrapper_checked': !!checked,
      'radio-btn-wrapper_active': !!isActive
    });

    const radioClassName = classNames({
      'radio-btn': true,
      'radio-btn_checked': !!checked,
      'radio-btn_disabled': !!disabled
    });

    const radioInnerClass = classNames({
      'radio-btn-inner': true,
      'radio-btn-inner_active': !!isActive
    });

    const translate = useTranslation();

    return (
      <label className={wrapperClassName} htmlFor={value}>
        <span className={radioClassName}>
          <input
            type='radio'
            className="radio-btn-input"
            value={value}
            id={value}
            checked={checked}
            onChange={onChange}
            name={name}
            ref={ref}
          />
          <span className={radioInnerClass} />
        </span>
        {isActive && <div className='radio-btn-active'>{translate("Active")}</div>}
        <span className='radio-btn-content'>{children}</span>
      </label>
    );
  }
);
