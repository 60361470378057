const generateWaveformMap = () => {
  let map = {};
  let startCode = 48;
  for (let i = 0; i < 32; i++) {
    let h = i;
    if (!(h % 2))
      h++;
    map[String.fromCharCode(startCode)] = h;
    if (startCode === 57)
      startCode = 96;
    startCode++;
  }
  return map;
};

const waveFormMap = generateWaveformMap();

export const generateWaveForm = (waveform) => {
  const result = [];
  if (typeof waveform !== 'string')
    waveform = '';
  for (let i = 0; i < waveform.length; i++) {
    const h = waveFormMap[waveform[i]] || 1;
    result.push(h);
  }
  if (!result.length) {
    return [1, 1, 1, 1, 1, 1, 1, 1];
  }
  return result;
};
