import React, { useMemo } from 'react';
import {
  useCallStripeFromLocationParams,
  useFetchPricingInfo,
  useLanguage,
  useShowRedirectingLayout
} from 'hooks';
import className from 'utils/class-names';
import BaseLayout from '../base/BaseLayout';
import Navbar from '../components/navbar/Navbar';
import Header from '../components/header/Header';
import { Menu } from '../components/menu/Menu';
import BottomNav from '../components/bottom-nav/BottomNav';
import SoundPlayer from '../components/sound-player/SoundPlayer';
import { Modals } from '../components/modals';
import { Generator } from '../components/generator';

import './Vertical.scss';
import { useGetSideBarStructureQuery } from '../../redux/api/side-bar-structure';
import { Redirecting } from 'layout/components/redirecting';
import { useLocation } from 'hooks/useLocation';
import classNames from 'utils/class-names';
import { BottomFooter } from 'layout/components/bottom-footer';

type Props = {
  children: React.ReactNode;
};

const VerticalLayout = ({ children }: Props) => {
  const language = useLanguage()
  const { data: sideMenuData = [] } = useGetSideBarStructureQuery(language);
  useFetchPricingInfo();
  useCallStripeFromLocationParams();
  const isRedirecting = useShowRedirectingLayout();
  const { pathname } = useLocation();
  const isNewLayout = useMemo(() => {
    return pathname.includes('/render');
  }, [pathname]);

  const header = <Header />;

  const sideNav = (
    <Navbar>
      <Menu data={sideMenuData.filter((item) => !item.title.includes('Artist'))} />
    </Navbar>
  );

  const bottomNav = <BottomNav data={sideMenuData} />;

  const footer = <BottomFooter />;

  const player = <SoundPlayer />;

  const mainContentClasses = className({
    'main-content': true
  });

  if (isRedirecting) {
    return <Redirecting />;
  }

  const appContainerClassNames = classNames({
    'app-container': true,
    'app-container_new': isNewLayout
  });


  return (
    <>
      <BaseLayout nav={header} bottomNav={bottomNav} footer={footer} player={player}>
        <div className={appContainerClassNames}>
          {sideNav}
          <main className={mainContentClasses}>
            <Generator className={!isNewLayout ? 'extension-generator' : ''} />
            <div
              className={
                isNewLayout
                  ? 'main-content-wrap main-content-wrap_new'
                  : 'main-content-wrap'
              }
            >
              {children}
            </div>
          </main>
        </div>
        <Modals />
      </BaseLayout>
    </>
  );
};

type CustomVerticalLayoutProps = {
  children: React.ReactNode;
  sideNav: React.ReactNode;
};

export function CustomVerticalLayout({ sideNav, children }: CustomVerticalLayoutProps) {
  const header = <Header />;
  const footer = <BottomFooter  />;

  return (
    <BaseLayout nav={header} bottomNav={null} footer={footer} player={null}>
      <div className='app-container'>
        {sideNav}
        <main className='main-content'>
          <div className='main-content-wrap'>{children}</div>
        </main>
      </div>
      <Modals />
    </BaseLayout>
  );
}

export default VerticalLayout;
