import React from 'react';
import { Button } from 'layout/components/app-button';
import { useDispatch } from 'react-redux';
import { usePush } from 'hooks/usePush';
import { setRedirectUrl } from '../../../../redux/settings/actions';
import { hideModal } from '../../../../redux/modals/actions';


export function LogIn() {
  const dispatch = useDispatch();
  const push = usePush();

  const handleLoginClick = () => {
    dispatch(setRedirectUrl(`${window.location.href}?download=true`));
    push('/render/sign-in');
    dispatch(hideModal);
  }

  const handleSighUpClick = () => {
    dispatch(setRedirectUrl(`${window.location.href}?download=true`));
    push('/render/sign-up');
    dispatch(hideModal());
  };

  return (
    <div className='info-modal'>
      To download the Mubert extension, please log in or create a free account
      <div className='info-modal__buttons'>
        <Button onClick={handleLoginClick}>
          Log In
        </Button>
        <Button onClick={handleSighUpClick}>
          Create a free account
        </Button>
      </div>
    </div>
  )
}
