type GenerateTrackProps = {
  filterCategory: string;
  filterSubcategory: string;
  trackLength: number;
};

export function trackGenerateTrack({
  filterCategory,
  filterSubcategory,
  trackLength
}: GenerateTrackProps) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    page_location: window.location.href,
    filter_category: filterCategory,
    filter_subcategory: filterSubcategory,
    track_length: trackLength
  });

  window.dataLayer.push({ event: 'generate_track' });
}

type AddToMyDownloadsProps = {
  elementClicked: string;
  trackId: string;
  trackType: string;
  trackListIndex: number;
  pricingOption: string;
  event: 'add_to_my_downloads_click' | 'purchase_click';
};

export function trackAddToMyDownloadsOrPurchase({
  elementClicked,
  trackId,
  trackType,
  trackListIndex,
  pricingOption,
  event
}: AddToMyDownloadsProps) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    page_location: window.location.href,
    element_clicked: elementClicked,
    track_id: trackId,
    track_type: trackType,
    track_list_index: trackListIndex,
    pricing_option: pricingOption
  });

  window.dataLayer.push({ event });
}

type TrackLpButtonClickProps = {
  buttonIndex: number;
  buttonText: string;
};

export function trackLpButtonClick({ buttonIndex, buttonText }: TrackLpButtonClickProps) {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    button_index: buttonIndex,
    button_text: buttonText
  });

  window.dataLayer.push({ event: 'render_lp_button_click' });
}

type ShowPopupProps = {
  trackId: string;
};

export function trackShowPopupAmbassador({ trackId }: ShowPopupProps) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    page_location: window.location.href,
    track_id: trackId,
  });

  window.dataLayer.push({ event: 'popup_ambassador' });
}

export function trackShowPopupChannels({ trackId }: ShowPopupProps) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    page_location: window.location.href,
    track_id: trackId,
  });

  window.dataLayer.push({ event: 'popup_channels' });
}

type TrackPopupModalProps = {
  trackId: string;
  event: string;
};

export function trackPopupModal({trackId, event}: TrackPopupModalProps) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    page_location: window.location.href,
    track_id: trackId,
  });

  window.dataLayer.push({ event });
}

type TrackSignUpParams = {
  label: string;
  email: string;
  id: string;
};

export function trackSignUp({label, email, id}: TrackSignUpParams) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    category: 'conversion',
    label,
    email,
    id
  });

  window.dataLayer.push({ event: 'sign_up' });
}
