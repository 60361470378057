import React, { useRef } from 'react';
import { Dropdown } from 'layout/components/dropdown';
import { ProfileAvatar } from 'layout/components/profile-avatar';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../interfaces/app-state';
import './ProfileDropdown.scss';
import AuthBtns from '../AuthBtns';
import { ProfileDropdownContent } from './ProfileDropdownContent';
import classNames from 'utils/class-names';
import { useAppSelector } from 'hooks';

const ProfileDropdown = () => {
  const profile = useSelector((state: IAppState) => state.loggedIn);
  const isSubscriptionsLoading = useAppSelector((state) => state.pricingInfo.isLoading);
  const dropdownRef = useRef<any>();

  const handleClick = () => {
    dropdownRef.current?.hideDropdown();
  };

  const className = classNames({
    'profile-dropdown': true,
    'profile-dropdown__disabled': isSubscriptionsLoading
  });

  if (!profile) {
    return <AuthBtns />;
  }

  return (
    <Dropdown
      overlayType='fixed'
      overlay={<ProfileDropdownContent onClick={handleClick} />}
      trigger={['click']}
      placement='bottomLeft'
      ref={dropdownRef}
    >
      <div className={className}>
        <ProfileAvatar />
      </div>
    </Dropdown>
  );
};

export default ProfileDropdown;
