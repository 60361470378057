import React from 'react';
import {ArtistConfirmedIcon} from '../icons/artist-confirmed-icon';
import classNames from 'utils/class-names';
import './artist-photo.scss';

type ArtistPhotoProps = {
  name: string;
  src: string;
  confirmed: boolean;
  className?: string;
};

export function ArtistPhoto({ name, src, confirmed, className: propClassName }: ArtistPhotoProps) {
  const className = classNames({
    'artist-photo': true,
    [propClassName]: !!propClassName
  });

  return (
    <div className={className}>
      <img src={src} alt={name} width={120} height={120} className='artist-photo__img' />
      {confirmed && (
        <ArtistConfirmedIcon className='artist-photo__confirmed-icon' />
      )}
    </div>
  );
}
