import React, { useCallback, useMemo, useState } from 'react';
import './FormSnippet.scss';
import TrackList from '../tracklist/TrackList';
import { useGetStaffPicksTracksListQuery } from '../../../redux/api/staff-picks-tracks';
import { ITrack } from 'interfaces';
import classNames from 'utils/class-names';
import useTranslation from 'hooks/useTranslations';

type FormSnippetProps = {
  staffPicksTags: string;
  title?: string;
  isPreviewTracks?: boolean;
  className?: string;
};

function useInfiniteScrolledTracks(tags: string): [ITrack[], () => void, boolean, boolean, number] {
  const [page, setPage] = useState(1);
  const [savedTracks, setSavedTracks] = useState([]);
  const {data, isFetching} = useGetStaffPicksTracksListQuery({
    page,
    tags: [tags]
  });
  const hasMorePages = page < data?.pages_count;
  const resultTracks = useMemo(() => {
    if (isFetching) {
      return savedTracks;
    }

    if (data) {
      return [...savedTracks, ...data.tracks];
    }

    return savedTracks;
  }, [savedTracks, data, isFetching]);
  const loadMoreTracks = useCallback(() => {
    setSavedTracks([
      ...savedTracks,
      ...data?.tracks
    ]);
    setPage(page + 1);
  }, [data?.tracks, savedTracks, page])

  return [resultTracks, loadMoreTracks, hasMorePages, isFetching, data?.tracks_count];
}

const FormSnippet = ({
  staffPicksTags,
  isPreviewTracks,
  className: propClassName,
  title = 'Staff picks'
}: FormSnippetProps) => {
  const className = classNames({
    [propClassName]: !!propClassName,
    'snipet-tab-wrap': true
  });
  const [tracksStaffPicks, fetchMorePages, hasMorePages, pageLoading, tracksCount] =
    useInfiniteScrolledTracks(staffPicksTags);
    const translate = useTranslation();

  return (
    <div className={className}>
      {(pageLoading || tracksCount > 0) && <div>{translate(title)}</div>}
      <TrackList
        tracks={{ tracks: tracksStaffPicks, tracksCount }}
        tab={0}
        hasMorePages={hasMorePages}
        loadMorePages={fetchMorePages}
        pageLoading={pageLoading}
      />
    </div>
  );
};

export default FormSnippet;
