import React from 'react';
import classNames from 'utils/class-names';
import './edit-pen.scss';


type EditPenProps ={
    onClick? : (e: any) => void;
    disabled?: boolean;
};



export function EditPen({onClick, disabled}: EditPenProps) {
    const className = classNames({
        'edit-icon': true,
        'edit-icon_disabled': disabled
      });
    
    
  return (
    <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg' className={className} onClick={onClick}>
      <path
        d='M10.5011 20.1192L10.2514 19.6783L10.5011 20.1192C10.6887 20.0129 10.8392 19.8622 11.0092 19.6918C11.0205 19.6805 11.032 19.669 11.0435 19.6574L18.5853 12.1156L18.6097 12.0912C18.9383 11.7627 19.2214 11.4796 19.4177 11.2224C19.6279 10.9468 19.7872 10.6402 19.7872 10.2624C19.7872 9.88463 19.6279 9.57807 19.4177 9.30244C19.2214 9.04526 18.9383 8.76218 18.6097 8.43363L18.5853 8.40922L18.4055 8.22936L18.3811 8.20496C18.0525 7.87637 17.7694 7.59325 17.5122 7.39703C17.2366 7.18673 16.93 7.0275 16.5522 7.0275C16.1744 7.0275 15.8679 7.18673 15.5923 7.39702C15.3351 7.59325 15.052 7.87637 14.7234 8.20496L14.699 8.22936L7.15725 15.7711C7.14569 15.7827 7.13421 15.7942 7.12282 15.8055C6.9525 15.9755 6.80172 16.1259 6.69546 16.3136C6.5892 16.5013 6.53775 16.708 6.47964 16.9415C6.47575 16.9571 6.47184 16.9729 6.46787 16.9887L5.77227 19.7711C5.76995 19.7804 5.76762 19.7897 5.76527 19.7991C5.72413 19.9634 5.68019 20.1388 5.66578 20.2861C5.64991 20.4483 5.65361 20.722 5.87315 20.9415C6.09268 21.1611 6.36635 21.1648 6.52856 21.1489C6.67589 21.1345 6.85133 21.0905 7.01559 21.0494C7.02496 21.0471 7.03428 21.0447 7.04357 21.0424L9.82597 20.3468C9.84182 20.3428 9.85756 20.3389 9.87317 20.335C10.1067 20.2769 10.3134 20.2255 10.5011 20.1192Z'
        stroke='#363C43'
        strokeWidth='1.04833'
        className='stroke'
      />
      <path
        d='M13.9315 8.68993L17.0765 6.59326L20.2215 9.73826L18.1248 12.8833L13.9315 8.68993Z'
        fill='#EAEBFF'
        stroke='#363C43'
        strokeWidth='1.04833'
        className='stroke'
      />
    </svg>
  );
}
