import React, { useState } from 'react';
import { Button } from 'layout/components/app-button';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../../../../redux/modals/actions';
import { Input } from 'layout/components/input';
import './edit-track-name.scss';
import useTranslation from 'hooks/useTranslations';


type EditTrackNameProps = {
  record: any;
  onConfirm: Function;
};

export function EditTrackName({ record, onConfirm }: EditTrackNameProps) {
  const dispatch = useDispatch(); 
  const [editedName, setEditedName] = useState<string>(record.name);
  let originalName = record.name;
  const translate = useTranslation();

  const handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setEditedName(originalName);
    dispatch(hideModal());
  };

  const handleSave = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    onConfirm({ name: editedName });
    dispatch(hideModal());
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setEditedName(e.target.value);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSave(e);
    }
  }

  return (
    <div className='edit-track-name__content'>
      <div className='edit-track-name__title'>{translate("Can edit")}</div>
      <Input
        type='text'
        value={editedName}
        onChange={handleNameChange}
        onKeyUp={handleKeyUp}
        className='edit-track-name__input'
      />
      <div className='edit-track-name__button'>
        <Button className='edit-track-name__button-cancel' onClick={handleCancel}>
          {translate("Cancel")}
        </Button>
        <Button className='edit-track-name__button-save' onClick={handleSave}>
        {translate("Save")}
        </Button>
      </div>
    </div>
  );
}
