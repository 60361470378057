import React, { useState, useRef } from 'react';

import className from 'utils/class-names';
import useTranslation from 'hooks/useTranslations';

import './verification-code.scss';
const CodeVerification = ({ onComplete }) => {
  const [code, setCode] = useState(['', '', '', '']);
  const inputRefs = useRef([]);

  const handleChange = (value, index) => {
    if (!/^\d+$/.test(value) && value !== '') return;

    const newDigit = value[0];

    const newCode = [...code];
    newCode[index] = newDigit;
    setCode(newCode);

    if (newDigit !== '' && index < 3) {
      inputRefs.current[index + 1].focus();
      inputRefs.current[index + 1].setSelectionRange(0, 0);
    } else if (index === 3 && newCode.every((digit) => digit !== '')) {
      onComplete(newCode.join(''));
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !code[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <div className='verification-code__inputs'>
      {code.map((digit, index) => (
        <input
          key={index}
          ref={(el) => (inputRefs.current[index] = el)}
          type='text'
          value={digit}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          className='verification-code__input'
        />
      ))}
    </div>
  );
};

type VerificationCodeProps = {
  onSubmit: (code: string) => Promise<void>;
  onResend?: () => Promise<void>;
};

export function VerificationCode({ onSubmit }: VerificationCodeProps) {
  //   const [timeLeft, setTimeLeft] = useState(50);
  const [error, setError] = useState('');
  const translate = useTranslation();
  const titleClassName = className({
    'verification-code__title': true,
    'verification-code__title_error': !!error
  });

  //   useEffect(() => {
  //     if (timeLeft === 0) return;

  //     const timer = setInterval(() => {
  //       setTimeLeft((prevTime) => prevTime - 1);
  //     }, 1000);

  //     return () => clearInterval(timer);
  //   }, [timeLeft]);

  //   const handleResend = async () => {
  //     await onResend();

  //     setTimeLeft(50);
  //   };

  const handleComplete = (code: string) => {
    onSubmit(code).catch((error) => {
      setError(error.message);
    });
  };

  const title = error || translate('We have sent the code to your email');

  return (
    <div className='verification-code'>
      <div className={titleClassName}>{title}</div>
      <CodeVerification onComplete={handleComplete} />
      {/* <div className='verification-code__timer'>
        {timeLeft > 0 && `Get the code again after ${timeLeft}`}
        {timeLeft === 0 && (
          <div className='verification-code__resend' onClick={handleResend}>
            Get the code again
          </div>
        )}
      </div> */}
    </div>
  );
}
