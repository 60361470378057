import React from 'react';
import { NavLink } from 'layout/components/nav-link';
import { ArtistPhoto } from 'layout/components/artist-photo';
import './artist-card.scss';
import useTranslation from 'hooks/useTranslations';

type ArtistCardProps = {
  img: string;
  confirmed: boolean;
  name: string;
  nickname: string;
  tracksCount: number;
  link: string;
  isPreviewArtist?: boolean;
};

export function ArtistCard({ img, isPreviewArtist, confirmed, name, nickname, tracksCount, link }: ArtistCardProps) {
  const translate = useTranslation();
  return (
    <div className='artist-card'>
      <NavLink to={`/render${link}`}>
        <ArtistPhoto name={name} src={img} confirmed={confirmed} />
        <div className='artist-card__title' translate='no'>{name}</div>
        {!isPreviewArtist && <span className='artist-card__link'  translate='no'>@{nickname}</span>}
        <div className='artist-card__tracks-count'>
          {tracksCount} {tracksCount === 1 ? translate(' Sample pack') : translate(' Sample packs')}
        </div>
      </NavLink>
    </div>
  );
}
