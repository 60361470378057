import React from 'react';
import classNames from 'utils/class-names';
import './field.scss';

type FieldProps = {
  label?: React.ReactNode;
  children: React.ReactNode;
  error?: any;
  hint?: React.ReactNode;
  className?: string;
  unmountErrorElement?: boolean;
};

export function Field({ label, children, error, hint, className: propClassName, unmountErrorElement }: FieldProps) {
  const className = classNames({
    'app-field': true,
    'app-field_error': !!error,
    [propClassName]: !!propClassName
  });
  
  return (
    <div className={className}>
      {!!label && <div className='app-field__label'>{label}</div>}
      <div className='app-field__content'>{children}</div>
      {hint && !error && <div className='app-field__hint'>{hint}</div>}
      {
        (error || !unmountErrorElement) && <div className='app-field__error'>{error}</div>
      }
    </div>
  );
}
