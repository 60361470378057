import React from 'react';
import { Dropdown } from 'layout/components/dropdown';
import { useLocation } from 'hooks/useLocation';
import { NavLink } from 'layout/components/nav-link';
import Nav from '../nav/Nav';
import ProfileDropdown from './actions/ProfileDropdown';
import AppMenu from '../app-menu/AppMenu';
import './DesktopNav.scss';
import { MUBERT_API_URL } from 'constants/common';
import { HeaderToggle } from '../header-toggle/HeaderToggle';
import { BellIcon } from '../icons/bell-icon/bell-icon';
import { FaqIcon } from '../icons/faq-icon/faq-icon';
import { IconBase } from '../tracklist/IconBase';
import { Tooltip } from '../tooltip';
import { WhatsNewDropdown } from './WhatsNewDropdown';
import useTranslation from 'hooks/useTranslations';
import { NewButton } from '../new-button';

export function DesktopNav({ onToggleTopMenu, settings }) {
  const { pathname } = useLocation();
  const isRenderRoute = pathname.startsWith('/render');
  const isExtensionRoute = pathname.startsWith('/extension');
  const translate = useTranslation();

  const menu = (
    <AppMenu className='desktop-nav__dropdown-item'>
      <AppMenu.LinkItem link='/render' target='_blank' text={translate('Mubert Render')} />
      <AppMenu.LinkItem
        link='https://beta.mubert.com'
        target='_blank'
        text={translate('Mubert Studio')}
        rel='noopener noreferrer'
      />
      <AppMenu.LinkItem
        link={MUBERT_API_URL}
        target='_blank'
        text='Mubert API'
        rel='noopener noreferrer nofollow'
      />
      <AppMenu.LinkItem
        link='https://playapp.mubert.com/?utm_source=mubert.com&utm_medium=cpc&utm_content=mubert_play'
        target='_blank'
        text={translate('Mubert Play')}
        rel='noopener noreferrer nofollow'
      />
      <AppMenu.LinkItem
        link='/render/streamers'
        target='_blank'
        text={translate('Mubert for Streamers')}
      />
      <AppMenu.LinkItem
        link='https://business.mubert.com'
        target='_blank'
        text={translate('Mubert Business')}
        rel='noopener noreferrer nofollow'
      />
    </AppMenu>
  );

  return (
    <>
      <Nav className='desktop-only'>
        {isRenderRoute && <Nav.LinkItem link='/render/pricing' text={translate('Pricing')} />}
        {isExtensionRoute && (
          <Nav.LinkItem link={'?scrollTo=pricing'} text={translate('Pricing')} />
        )}
        {!isRenderRoute && !isExtensionRoute && (
          <Dropdown overlay={menu}>
            <li className='app-nav-link-item'>
              <a href='#' className='app-dropdown-link'>
                {translate('Products')}
              </a>
            </li>
          </Dropdown>
        )}
        <Nav.LinkItem link='https://mubert.com/blog/' text={translate('Blog')} />
      </Nav>
      <Nav className='desktop-nav__show-everywhere'>
        <NewButton
          accentColor
          className='desktop-nav__become-affiliate'
          onClick={() =>
            window.open(
              'https://mubert.getrewardful.com/signup?_ga=2.77387285.1430724933.1666705492-2140651192.1666705492',
              '_blank',
              'noopener,noreferrer'
            )
          }
        >
          {translate('Become Affiliate')}
        </NewButton>
        <div className='desktop-nav__icons-block'>
          <WhatsNewDropdown>
            <div className='desktop-nav__whats-new'>
              <a href='#' className='app-dropdown-link'>
                <Tooltip text='Whats New'>
                  <IconBase className='desktop-nav__whats-new-link'>
                    <BellIcon />
                  </IconBase>
                </Tooltip>
              </a>
            </div>
          </WhatsNewDropdown>
          <div className='desktop-nav__whats-new'>
            <NavLink to='/render/faq' className='app-dropdown-link'>
              <Tooltip text='FAQ'>
                <IconBase className='desktop-nav__whats-new-link'>
                  <FaqIcon />
                </IconBase>
              </Tooltip>
            </NavLink>
          </div>
        </div>
        <ProfileDropdown />
        <HeaderToggle onClick={onToggleTopMenu} opened={settings.topMenuOpened} />
      </Nav>
    </>
  );
}
