import { SET_CURRENT_TRACK, SET_TIME_PLAYING, SET_TRACK_PLAYING, SET_TRACK_INFO } from './types';
import { removeTrackFromGenerating } from '../generator/actions';

export const setCurrentTrack = (track:string) => (dispatch, getState) => {
  document.querySelector('body').setAttribute('is-player-visible', String(!!track));

  dispatch({
    type: SET_CURRENT_TRACK,
    payload: track
  });

  dispatch(removeTrackFromGenerating(track));
};

export const setTrackInfo = (track: any) => ({
  type: SET_TRACK_INFO,
  payload: track
});

export const setPlaying = (playing: boolean) => ({
  type: SET_TRACK_PLAYING,
  payload: playing
});

export const setTimePlaying = (time: number) => ({
  type: SET_TIME_PLAYING,
  payload: time
});

