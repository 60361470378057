import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Radio } from 'layout/components/radio';
import classNames from 'utils/class-names';

import './PurchaseOption.scss';

type PurchaseOptionProps = {
  id: string;
  name: string;
  description: string;
  price: string;
  isActive?: boolean;
  isFree?: boolean;
  expDate?: number;
  isCanceled?: boolean;
  currentTier?: boolean;
  isBlackFriday?: boolean;
  className?: string;
};

export const PurchaseOption = ({
  id,
  name,
  description,
  price,
  isActive,
  className
}: PurchaseOptionProps) => {
  const { control, watch } = useFormContext();
  const selectedLicenseId = watch('licence');
  const wrapperClassName = classNames({
    'purchase-option': true,
    'purchase-option_active': isActive,
    'purchase-option_checked': selectedLicenseId === id,
    [className]: !!className
  });

  return (
    <Controller
      control={control}
      name='licence'
      render={({ field }) => (
        <div className={wrapperClassName}>
          <Radio {...field} checked={field.value === id} value={id} isActive={isActive}>
            <div className='purchase-option__content'>
              <div className='purchase-option__name'>{name}</div>
              <div className='purchase-option__description'>{description}</div>
            </div>
            <div className='purchase-option__price'>{price}</div>
          </Radio>
        </div>
      )}
    />
  );
};
