import { useLocation } from 'hooks/useLocation';
import { useSelectPricingInfo } from '../redux/pricing-info/selectors';
import { useAppSelector } from 'hooks/store';

export function useShowRedirectingLayout() {
  const location = useLocation();
  const loggedIn = useAppSelector(state => state.loggedIn);
  const {isLoadedForLoggedInUser} = useSelectPricingInfo();
  const query = new URLSearchParams(location.search);
  const isRedirectPreviewVisible = useAppSelector(state => state.settings.isRedirectPreviewVisible);

  if (!query.get('state') || !loggedIn) {
    return false;
  }

  const state = JSON.parse(query.get('state').replace('#', ''));
  const priceId = state['price_id'];

  if (!priceId || priceId === 'ambassador') {
    return false;
  }

  if (!isLoadedForLoggedInUser) {
    return true;
  }

  return isRedirectPreviewVisible;
}
