import React from 'react';
import { Controller } from 'react-hook-form';
import { MaskedInput } from 'layout/components/masked-input';
import './time-input.scss';

const MASK = [/\d/, /\d/];

export const TimeInput = ({
  control,
  isInputClicked,
  setIsInputClicked,
  secondsInputRef,
  errorMessage,
  maxMinutes,
  maxSeconds
}) => {
  return (
    <>
      <Controller
        name='minutes'
        control={control}
        rules={{
          required: errorMessage,
          validate: (value) => {
            const minutes = parseInt(value);
            if (isNaN(minutes) || minutes < 0 || minutes > maxMinutes) {
              return errorMessage;
            }
            return true;
          }
        }}
        render={({ field }) => (
          <MaskedInput
            {...field}
            type='text'
            mask={MASK}
            placeholder='00'
            className={`track-duration__input ${
              isInputClicked ? 'track-duration__input_clicked' : ''
            }`}
            autoComplete='off'
            onFocus={() => setIsInputClicked(true)}
            onBlur={() => {
              if (!field.value) {
                field.onChange('00');
              }
              setIsInputClicked(false)
            }}
            onKeyUp={(e) => {
              if (e.key === 'Tab') {
                secondsInputRef.current.focus();
                secondsInputRef.current.resetSelection();
              }
            }}
            onChange={(value, e) => {
              field.onChange(value);

              if (e.target.selectionStart === 2) {
                secondsInputRef.current.focus();
                secondsInputRef.current.resetSelection();
              }
            }}
          />
        )}
      />
      <span className='track-duration__separator'>:</span>
      <Controller
        name='seconds'
        control={control}
        rules={{
          required: errorMessage,
          validate: (value, { minutes }) => {
            const seconds = parseInt(value);
            if (isNaN(seconds) || seconds < 0 || seconds > 59) {
              return 'Invalid seconds value';
            }
            const parsedMinutes = parseInt(minutes);
            let isTrackDurationSmall = parsedMinutes === 0 && seconds < 5;
            if (
              (parsedMinutes === maxMinutes &&
              ((maxSeconds === undefined && seconds !== 0) || seconds > maxSeconds)) || isTrackDurationSmall
            ) {
              return errorMessage;
            }
            return true;
          }
        }}
        render={({ field }) => (
          <MaskedInput
            autoComplete='off'
            {...field}
            type='text'
            mask={MASK}
            placeholder='00'
            onKeyUp={(e) => {
              if (e.key === 'Tab') {
                secondsInputRef.current.focus();
                secondsInputRef.current.resetSelection();
              }
            }}
            onFocus={() => setIsInputClicked(true)}
            onBlur={() => {
              if (!field.value) {
                field.onChange('00');
              }
              setIsInputClicked(false)
            }}
            className={`track-duration__input ${
              isInputClicked ? 'track-duration__input_clicked' : ''
            }`}
            ref={(input) => {
              secondsInputRef.current = input;
            }}
          />
        )}
      />
    </>
  );
};

export default TimeInput;
