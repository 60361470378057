import React from 'react';

export function DeleteSubscription() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 44 44' fill='none'>
      <rect width='44' height='44' rx='22' fill='url(#paint0_linear_13647_33417)' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M31 22C31 26.9706 26.9706 31 22 31C17.0294 31 13 26.9706 13 22C13 17.0294 17.0294 13 22 13C26.9706 13 31 17.0294 31 22ZM26.1716 24.7574C26.6951 23.967 27 23.0191 27 22C27 19.2386 24.7614 17 22 17C20.9809 17 20.033 17.3049 19.2426 17.8284L26.1716 24.7574ZM17.8284 19.2426L24.7574 26.1716C23.967 26.6951 23.0191 27 22 27C19.2386 27 17 24.7614 17 22C17 20.9809 17.3049 20.033 17.8284 19.2426ZM22 15C18.134 15 15 18.134 15 22C15 25.866 18.134 29 22 29C25.866 29 29 25.866 29 22C29 18.134 25.866 15 22 15Z'
        fill='#FAFAFA'
      />
      <defs>
        <linearGradient
          id='paint0_linear_13647_33417'
          x1='93.5'
          y1='-27.8666'
          x2='-4.11106'
          y2='-21.7648'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3027EF' />
          <stop offset='1' stopColor='#B733E2' />
        </linearGradient>
      </defs>
    </svg>
  );
}
