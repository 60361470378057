import React from 'react';
import './download-icon.scss';
import classNames from 'utils/class-names';

type DownloadIconProps = {
  disabled?: boolean;
  onClick?: (e: any) => void;
  pending?: boolean;
};

export function DownloadIcon({ disabled, pending, onClick }: DownloadIconProps) {
  const className = classNames({
    'download-icon': true,
    'download-icon_disabled': disabled,
    'download-icon_pending': pending
  });

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 30 30'
      fill='none'
      className={className}
      onClick={onClick}
    >
      <path
        className='fill'
        d='M15 18L14.6515 18.3585L15 18.6974L15.3485 18.3585L15 18ZM15.5 7.5C15.5 7.22386 15.2761 7 15 7C14.7239 7 14.5 7.22386 14.5 7.5L15.5 7.5ZM8.65146 12.5252L14.6515 18.3585L15.3485 17.6415L9.34854 11.8082L8.65146 12.5252ZM15.3485 18.3585L21.3485 12.5252L20.6515 11.8082L14.6515 17.6415L15.3485 18.3585ZM15.5 18L15.5 7.5L14.5 7.5L14.5 18L15.5 18Z'
        fill='#363C43'
      />
      <path
        className='stroke'
        d='M7.5 19.5L7.5 20.5C7.5 21.6046 8.45939 22.5 9.64286 22.5L20.3571 22.5C21.5406 22.5 22.5 21.6046 22.5 20.5V19.5'
        stroke='#363C43'
      />
    </svg>
  );
}
