import React from 'react';
import { NewButton } from 'layout/components/new-button';
import { usePush } from 'hooks/usePush';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../../../../redux/modals/actions';
import './limit-exceeded.scss';

export function LimitExceeded() {
  const push = usePush();
  const dispatch = useDispatch();
  return (
    <div className='limit-exceeded'>
      <h3> Upgrade to Unlock More Power!</h3>
      <p>
        You're currently downloading a track. Upgrade your plan to download multiple tracks
        simultaneously and enjoy faster download processing speeds!
      </p>
      <h6>Features You'll Unlock:</h6>
      <div className='limit-exceeded__features'>
        <div className='limit-exceeded__features_item'>
          <div>🚀 Unlimited Concurrent Downloads</div>
          <p>Download multiple tracks at the same time without waiting.</p>
        </div>
        <div className='limit-exceeded__features_item'>
          <div className='limit-exceeded__features_title'>📈 Higher Track Generation Limits</div>
          <p>Generate up to 1,000 tracks/month with dedicated support.</p>
        </div>
        <div className='limit-exceeded__features_item'>
          <div className='limit-exceeded__features_title'>⚡ Priority Download Processing</div>
          <p>Get your generated music downloads processed faster with priority in the queue.</p>
        </div>
        <div className='limit-exceeded__features_item'>
          <div className='limit-exceeded__features_title'>❌ No Attribution Needed</div>
          <p>
            Use your music anywhere with expanded licensing options without needing to credit
            Mubert.
          </p>
        </div>
        <div className='limit-exceeded__features_title'>...and more</div>
      </div>
      <p>
        Upgrading supports the artists who make Mubert possible. Thank you for being part of our
        Creators community!
      </p>
      <div className='limit-exceeded__buttons'>
        <NewButton transparent onClick={() => dispatch(hideModal())}>
          Continue with Current Plan
        </NewButton>
        <NewButton
          gradient
          onClick={() => {
            push('/render/pricing');
            dispatch(hideModal());
          }}
        >
          Upgrade Now
        </NewButton>
      </div>
    </div>
  );
}
