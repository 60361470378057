import VisaIcon from 'assets/icons/visa.svg';
import MasterCardIcon from 'assets/icons/master-card.svg';
import GoogleIcon from 'assets/icons/g-pay.svg';
import PayPalIcon from 'assets/icons/paypal.svg';
import Revolut from 'assets/icons/revolut.svg';
import UnionPay from 'assets/icons/union-pay.svg';
import LinkedPayment from 'assets/icons/link-payment.svg';
import AppleIcon from 'assets/icons/apple-pay.svg';

export const usePaymentIcon = (type) => {
  switch (type) {
    case 'visa':
      return { icon: VisaIcon, text: 'Visa card' };
    case 'mastercard':
      return { icon: MasterCardIcon, text: 'MasterCard' };
    case 'paypal':
      return { icon: PayPalIcon, text: 'Paypal card' };
    case 'revolut':
      return { icon: Revolut, text: 'Revolut card' };
    case 'unionpay':
      return { icon: UnionPay, text: 'UnionPay' };
    case 'link':
      return { icon: LinkedPayment, text: '' };
    case 'google_pay':
      return { icon: GoogleIcon, text: 'Google Pay' };
    case 'apple_pay':
      return { icon: AppleIcon, text: 'Apple Pay' };
    default:
      return { icon: VisaIcon, text: 'Bank card' };
  }
};
