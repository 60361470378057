import React from 'react';
import RcTabs, {TabPane, TabsProps as RcTabsProps} from 'rc-tabs';
import classNames from 'utils/class-names';
import './tabs.scss';

type SizeType = 'small' | 'middle' | 'large' | undefined;

interface TabsProps extends Omit<RcTabsProps, 'editable'> {
  size?: SizeType;
}


export function Tabs({
  className,
  size: propSize,
  ...props
}: TabsProps) {
  const size = propSize;

  const tabsClassName = classNames(
    {
      [`app-tabs-${size}`]: size,
      [className]: !!className
    }
  );

  return (
    <RcTabs
      moreTransitionName={'app-slide-up'}
      {...props}
      className={tabsClassName}
      prefixCls='app-tabs'
    />
  );
}

Tabs.TabPane = TabPane;
