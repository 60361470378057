import React, { useState } from 'react';
import { Dropdown } from 'layout/components/dropdown';
import { DropdownOverlay } from 'layout/components/dropdown/dropdown-overlay';
import { GenerateByImage } from 'layout/components/modals';
import './image-dropdown.scss';

type ImageDropdownProps = {
  children: React.ReactNode;
  onSubmit: (value: any) => void;
  onOpen?: () => void;
};

export function ImageDropdown({ children, onSubmit, onOpen }: ImageDropdownProps) {
  const [isVisible, setIsVisible] = useState(false);
  const handleSubmit = (value: any) => {
    setIsVisible(false);
    onSubmit(value);
  }

  return (
    <Dropdown
      trigger={['click']}
      placement='topCenter'
      visible={isVisible}
      onVisibleChange={(isVisible) => {
        setIsVisible(isVisible);

        if (isVisible) {
          onOpen?.();
        }
      }}
      overlay={
        <DropdownOverlay
          onClose={() => setIsVisible(false)}
          shadowed
          className='image-dropdown__overlay'
        >
          <GenerateByImage onSubmit={handleSubmit} />
        </DropdownOverlay>
      }
    >
      {children}
    </Dropdown>
  );
}
