import React from 'react';
type LogoHeaderProps = {
  width?: number;
  height?: number;
};

export function LogoHeader({ width, height }: LogoHeaderProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='120'
      height='40'
      viewBox='0 0 120 40'
      fill='none'
    >
      <path
        d='M107.19 26.0109C103.098 26.0109 100.975 23.1143 97.834 18.3666C94.7814 13.7496 90.977 8 83.3682 8C75.7595 8 71.9551 13.7496 68.9027 18.3666C65.7619 23.0926 63.6385 26.0109 59.5466 26.0109C55.4547 26.0109 53.3312 23.1143 50.1904 18.3666C47.138 13.7496 43.3336 8 35.7249 8C28.1161 8 24.3117 13.7496 21.2593 18.3666C18.1185 23.0926 16.0919 26 12 26C10.3431 26 9 27.3431 9 29C9 30.6569 10.3431 32 12 32C19.518 32 23.2274 26.3869 26.2591 21.7991L26.3687 21.6334C29.5095 16.9074 31.633 13.9891 35.7249 13.9891C39.8168 13.9891 41.9402 16.8857 45.081 21.6334C48.1334 26.2505 51.9378 32 59.5466 32C67.1553 32 70.9597 26.2505 74.0121 21.6334C77.1529 16.9074 79.2764 13.9891 83.3682 13.9891C87.4602 13.9891 89.5836 16.8857 92.7248 21.6334C95.7766 26.2505 99.5815 32 107.19 32H108C109.657 32 111 30.6569 111 29C111 27.3431 109.657 26 108 26C107.773 26 107.667 26 107.56 26.0016C107.465 26.003 107.37 26.0058 107.19 26.0109Z'
        fill='var(--logo-color)'
      />
    </svg>
  );
}
