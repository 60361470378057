import React from 'react';
import { useInitForm } from 'hooks';
import {Input} from 'layout/components/input';
import {Field} from 'layout/components/field';
import { Button } from 'layout/components/app-button';

type InsertCodeProps = {
  onSubmit: (values: any) => void;
};

type ForwardRefProps = {
  setError: (name: string, error: any) => void;
};

const InsertCode: React.ForwardRefRenderFunction<ForwardRefProps, InsertCodeProps> = ({onSubmit}, ref) => {
  const {register, handleSubmit, formState: { errors }} = useInitForm(ref);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field label='Email verification code (check your inbox)' error={errors.code?.message}>
        <Input
          {...register('code', {
            required: 'The field is required'
          })}
          error={!!errors.code}
          autoComplete='off'
        />
      </Field>
      <div className='session-wrapper-footer'>
        <Button
          block={false}
          type='primary'
          htmlType='submit'
          className='submitButton'
        >
          Create account
        </Button>
      </div>
    </form>
  );
}

export const InsertCodeForm = React.forwardRef(InsertCode);
