import React from 'react';
import classNames from 'utils/class-names';
import './delete-icon.scss';

type DeleteIconProps = {
  disabled?: boolean;
  pending?: boolean;
  onClick?: (e: any) => void;
};

export function DeleteIcon({ disabled, pending, onClick }: DeleteIconProps) {
  const className = classNames({
    'delete-icon': true,
    'delete-icon_disabled': disabled,
    'delete-icon_pending': pending,
  });

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 30 30' fill='none' className={className} onClick={onClick}>
      <path className='stroke' d='M12.5 18.7502L12.5 15.0002' stroke='#363C43' strokeLinecap='round' />
      <path  className='stroke' d='M17.5 18.7502L17.5 15.0002' stroke='#363C43' strokeLinecap='round' />
      <path className='stroke'
        d='M3.74951 8.75H26.2495V8.75C26.0542 8.75 25.9565 8.75 25.8739 8.75386C24.0489 8.83912 22.5886 10.2994 22.5034 12.1244C22.4995 12.207 22.4995 12.3046 22.4995 12.5V17.9208C22.4995 21.258 22.4995 22.9265 21.4628 23.9633C20.4261 25 18.7575 25 15.4203 25H14.5787C11.2415 25 9.57296 25 8.53624 23.9633C7.49951 22.9265 7.49951 21.258 7.49951 17.9208V12.5C7.49951 12.3046 7.49951 12.207 7.49566 12.1244C7.41039 10.2994 5.95012 8.83912 4.12509 8.75386C4.04254 8.75 3.94487 8.75 3.74951 8.75V8.75Z'
        stroke='#363C43'
        strokeLinecap='round'
      />
      <path className='stroke'
        d='M12.5852 4.21324C12.7276 4.08034 13.0415 3.96291 13.4781 3.87915C13.9147 3.7954 14.4497 3.75 15 3.75C15.5503 3.75 16.0853 3.7954 16.5219 3.87915C16.9585 3.96291 17.2724 4.08034 17.4148 4.21324'
        stroke='#363C43'
        strokeLinecap='round'
      />
    </svg>
  );
}
