import React from 'react';
import playBtn from 'assets/icons/play.svg';
import pauseBtn from 'assets/icons/pause.svg';
import playBtnWhite from 'assets/icons/play-white.svg';
import pauseBtnWhite from 'assets/icons/pause-white.svg';
import classNames from 'utils/class-names';

import './PlayerButton.scss';

type PlayerButtonProps = {
  size: number;
  url: string;
  onClick: () => void;
  focused?: boolean;
  playing?: boolean;
  showNotifier?: boolean;
  className?: string;
  name: string;
  useWhiteButtons?: boolean;
  color?: string;
  buttonSize?: number;
};

const PlayerButton = ({
  buttonSize,
  size = 40,
  url,
  playing,
  focused,
  onClick,
  showNotifier,
  name,
  useWhiteButtons,
  color,
  className: propClassName = ''
}: PlayerButtonProps) => {
  const className = classNames({
    'player-button': true,
    'player-button_focused': focused,
    'player-button_colored': !!color,
    [propClassName]: !!propClassName
  });

  return (
    <div onClick={onClick} className={className} style={{ width: size, height: size }}>
      {showNotifier && <div className='player-button__notify' />}
      <div>
        {url ? (
            <img src={url} alt={name} className='avatar-img' />
        ) : (
          <div className='avatar-img' style={{ backgroundColor: color }} />
        )}
        {((focused && !playing) || !focused) &&
          (useWhiteButtons ? (
            <img
              src={playBtnWhite}
              style={{ width: buttonSize ? buttonSize : 12, display: 'block' }}
              className='player-control play-btn'
              alt='play button'
            />
          ) : (
            <img src={playBtn} className='player-control play-btn' alt='play button' />
          ))}
        {focused &&
          playing &&
          (useWhiteButtons ? (
            <img
              src={pauseBtnWhite}
              style={{ width: 12 }}
              className='player-control'
              alt='pause button'
            />
          ) : (
            <img src={pauseBtn} className='player-control' alt='pause button' />
          ))}
      </div>
    </div>
  );
};

export default PlayerButton;
