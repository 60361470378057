import React from 'react';
import Preloader from 'layout/components/preloader/Preloader';
import { SpinningCircle } from 'layout/components/spinning-circle';
import './PlayerSpinner.scss';

export const PlayerSpinner = () => {
  return (
    <div className='player-spinner'>
      <SpinningCircle />
      <div className='player-spinner__wave'>
        <Preloader width={32} height={32} />
      </div>
    </div>
  );
};
