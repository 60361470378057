import React from 'react';
import { IAppState } from 'interfaces';
import { useSelector } from 'react-redux';
import './exit-icon.scss';

export function ExitIcon() {
  const isDarkTheme = useSelector((state: IAppState) => state.settings.isDarkTheme);
  const color = isDarkTheme ? 'white' : '#363C43';
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' className='exit-icon'>
      <path d='M18 6L6 18' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6 6L18 18' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
}
