import React from 'react';
import { useInitForm } from 'hooks';
import {Input} from 'layout/components/input';
import {Field} from 'layout/components/field';
import { Button } from 'layout/components/app-button';
import './update-password.scss';

type UpdatePasswordProps = {
  onSubmit: (values: any) => void;
  email: string;
};

type ForwardRefProps = {
  setError: (name: string, error: any) => void;
};

const UpdatePassword: React.ForwardRefRenderFunction<ForwardRefProps, UpdatePasswordProps> = ({onSubmit, email}, ref) => {
  const {register, handleSubmit, formState: { errors }} = useInitForm(ref);

  return (
    <form onSubmit={handleSubmit(onSubmit)} name="test">
      <div className='message-code-sent'>Password recovery code was send to email:<br />
        <span className='message-code-sent__email'>{email}</span></div>
      <Field label='Code from email' error={errors.code?.message}>
        <Input
          {...register('code', {
            required: 'The field is required'
          })}
          autoComplete='one-time-code'
        />
      </Field>
      <Field label='Password' error={errors.password?.message}>
        <Input
          {...register('password', {
            required: 'The field is required'
          })}
          type='password'
          autoComplete='new-password'
        />
      </Field>
      <div className='session-wrapper-footer'>
        <Button
          block={false}
          type='primary'
          htmlType='submit'
          className='submitButton'
        >
          Set password
        </Button>
      </div>
    </form>
  );
}

export const UpdatePasswordForm = React.forwardRef(UpdatePassword);
