import React from 'react';

export function SearchIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <circle cx='11' cy='11' r='7' stroke='var(--main-text-color)' strokeWidth='2' />
      <path d='M20 20L17 17' stroke='var(--main-text-color)'  strokeWidth='2' strokeLinecap='round' />
    </svg>
  );
}
