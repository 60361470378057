import React from 'react';
import { useFormContext } from 'react-hook-form';
import useTranslation from 'hooks/useTranslations';
import { useSelectPricesMap } from '../../../redux/pricing-info/selectors';
import { useAppSelector } from 'hooks';
import { Button } from 'layout/components/app-button';
import { getPriceTitle } from 'utils/price-helpers';
import { useDispatch } from 'react-redux';
import classNames from 'utils/class-names';
import { hideModal } from '../../../redux/modals/actions';

import './PurchaseButtons.scss';

type PurchaseButtonsProps = {
  fullWidth?: boolean;
  children: React.ReactNode;
};

export const PurchaseButtons = ({ fullWidth, children }: PurchaseButtonsProps) => {
  const className = classNames({
    'purchase-buttons': true,
    'purchase-buttons_full-width': fullWidth
  });

  return <div className={className}>{children}</div>;
};

const CancelButton = () => {
  const dispatch = useDispatch();
  const translate = useTranslation();

  return (
    <Button
      className='purchase-buttons__cancel'
      onClick={(e) => {
        e.preventDefault();
        dispatch(hideModal());
      }}
    >
      {translate('Cancel')}
    </Button>
  );
};

const SubmitButton = () => {
  const pricesMap = useSelectPricesMap();
  const { watch } = useFormContext();
  const selectedPriceId = watch('licence');
  const priceEntity = pricesMap[selectedPriceId];
  const translate = useTranslation();

  const isAbleToDownload =
    priceEntity &&
    (priceEntity.isActive ||
      priceEntity.currentTier ||
      getPriceTitle(priceEntity).toLowerCase() === 'free');
  const buttonText = priceEntity?.email
    ? 'Get a quote'
    : isAbleToDownload
    ? 'Add to My downloads'
    : 'Select';
  const isPurchasing = useAppSelector((state) => state.purchase.isPurchasing);

  return (
    <Button
      className='purchase-submit'
      htmlType='submit'
      type='primary'
      disabled={!selectedPriceId}
      loading={isPurchasing}
    >
      {translate(buttonText)}
    </Button>
  );
};

PurchaseButtons.Submit = SubmitButton;
PurchaseButtons.Cancel = CancelButton;