import React, { useMemo, useState, useCallback } from 'react';
import { renderDuration } from 'utils/formatters';
import { Table } from 'layout/components/table';
import { useDispatch } from 'react-redux';
import { setCurrentTrack, setPlaying } from '../../../redux/current-track/actions';
import { useMediaQuery } from 'react-responsive';

import './TrackList.scss';
import PlayerButton from '../player-button/PlayerButton';
import Preloader from '../preloader/Preloader';
import { trackAddToMyDownloadsOrPurchase } from 'utils/metrics';
import { useSelectBestHighestAvailableSubscription } from '../../../redux/pricing-info/selectors';
import { LinkButton } from 'layout/components/link-button';
import CheckMark from 'assets/icons/check-mark.svg';
import { Helmet } from 'react-helmet';
import { renderTrackListScript } from 'utils/helmet-helpers';
import { showModal } from '../../../redux/modals/actions';
import { ITrack } from 'interfaces';
import {
  useAppSelector,
  useIsChannelsInfoSaved,
  useShowAttributionInfoPopup,
  useShowChannelsInfoPopup
} from 'hooks';
import classNames from 'utils/class-names';
import { Tags } from 'layout/components/tags';
import { IconBase } from './IconBase';
import { DeleteIcon, DownloadIcon, EditPen } from 'layout/components/icons';
import { GenerateDropdownIcon } from '../icons/generate-dropdown-icon';

import {
  useEditGeneratedTrackNameMutation,
  useRemoveTrackByIdMutation
} from '../../../redux/api/tracks';
import { removeTrackFromGenerating } from '../../../redux/generator/actions';
import { ModalNames } from 'constants/modal-names';
import { fetchPricing } from '../../../redux/pricing-info/actions';
import { stripeOrder } from '../../../redux/purchase/actions';
import { getColorBySessionId } from 'utils/track';
import { PlayerSpinner } from '../player-spinner';
import { MoreDropdown } from './MoreDropdown';
import { GenerateTrackDropdown } from './GenerateTrackDropdown';
import { TrackNameDropdown } from '../track-name-dropdown';
import useTranslation from 'hooks/useTranslations';
import ToggleGenerator from 'pages/render/ToggleGenerator';
import { useGetDownloadedTrackListQuery } from '../../../redux/api/my-downloaded-tracks';
import { Tooltip } from '../tooltip';

const EMPTY_ARRAY = [];

type TrackListProps = {
  className?: string;
  tracks: {
    tracksCount?: number;
    tracks: ITrack[];
  };
  tab: number;
  hasMorePages?: boolean;
  loadMorePages?: () => void;
  pageLoading?: boolean;
  reload?: () => void;
  isGenerator?: boolean;
  isTrackEditable?: boolean;
  page?: number;
  downloaded?: boolean;
  setDownloaded?: (downloaded: boolean) => void;
};

const TrackList = ({
  className: classNameProps,
  tracks,
  tab,
  hasMorePages = false,
  loadMorePages = () => {},
  pageLoading = false,
  reload,
  isGenerator,
  isTrackEditable,
  page,
  downloaded,
  setDownloaded
}: TrackListProps) => {
  const { data } = useGetDownloadedTrackListQuery({
    page: 1
  });

  const dispatch = useDispatch();
  const [deletingTracks, setDeletingTracks] = useState({});
  const [downloadingTracks, setDownloadingTracks] = useState({});
  const [tracksTooltips, setTracksTooltips] = useState({});
  const downloadingCount = Object.values(downloadingTracks).filter(
    (value) => value === true
  ).length;
  const availableSubscription = useSelectBestHighestAvailableSubscription();
  const [deleteTrack] = useRemoveTrackByIdMutation();
  const [editTrack] = useEditGeneratedTrackNameMutation();
  const [downloadedCount, setDownloadedCount] = useState(0);
  const isChannelsInfoSaved = useIsChannelsInfoSaved();
  const showAttributionInfoPopup = useShowAttributionInfoPopup();
  const showChannelsInfoPopup = useShowChannelsInfoPopup();

  const generatingTracks = useAppSelector((state) => state.generator.generatedTracks);
  const isLoggedIn = useAppSelector((state) => state.loggedIn);
  const isMediumScreen = useMediaQuery({ maxWidth: 767 });

  const trackList = useMemo(() => {
    if (tracks && tracks.tracks) {
      return tracks.tracks.map((item) => ({
        ...item,
        color: getColorBySessionId(item.session_id)
      }));
    }

    return EMPTY_ARRAY;
  }, [tracks]);

  const checkLicense = async (record, index) => {
    if (!isLoggedIn) {
      dispatch(
        showModal({
          name: ModalNames.DownloadTrack,
          small: true
        })
      );

      return;
    }

    if (!record.status_track) return;

    setDownloadingTracks((currentDownloadingTracks) => ({
      ...currentDownloadingTracks,
      [record.session_id]: true
    }));

    try {
      await trackAddToMyDownloadsOrPurchase({
        elementClicked: 'track_list_download_button',
        pricingOption: '',
        trackId: record.session_id,
        trackType: 'unknown',
        trackListIndex: index,
        event: 'add_to_my_downloads_click'
      });

      if ((availableSubscription?.isFree || !availableSubscription) && downloadingCount > 0) {
        dispatch(
          showModal({
            name: ModalNames.LimitExceeded,
            maskClosable: false,
            extraSmall: true
          })
        );

        return;
      }

      if (record.flag_artist) {
        dispatch(
          showModal({
            name: ModalNames.PurchaseArtist,
            additionalProps: {
              sessionId: record.session_id,
              trackName: record.name,
              purchases: record.purchases ? Object.keys(record.purchases) : [],
              artistUrl: record.artist.routing,
              artistName: record.artist.fullname,
              artistImage: record.artist.image?.medium
            }
          })
        );
      } else {
        setTracksTooltips((currentTrackTooltips) => ({
          ...currentTrackTooltips,
          [record.session_id]: true
        }));

        setTimeout(() => {
          setTracksTooltips((currentTrackTooltips) => ({
            ...currentTrackTooltips,
            [record.session_id]: false
          }));
        }, 3000);

        await dispatch(
          stripeOrder({
            sessionId: record.session_id,
            isDownloadTrack: true,
            priceId: availableSubscription ? availableSubscription.priceId : 'ambassador',
            callback: () => {
              const tracksCount = data?.tracks_count || 0;
              const totalTracksCount = tracksCount + downloadedCount;

              if (totalTracksCount === 2 && !isChannelsInfoSaved) {
                showChannelsInfoPopup({
                  trackId: record.session_id
                });
              } else {
                if (availableSubscription?.isFree || !availableSubscription) {
                  showAttributionInfoPopup({
                    trackId: record.session_id,
                    licenseName: availableSubscription
                      ? availableSubscription.priceId
                      : 'ambassador'
                  });
                }
              }

              if (totalTracksCount < 3) {
                setDownloadedCount(downloadedCount + 1);
              }

              if (!availableSubscription) {
                dispatch(fetchPricing());
              }

              if (reload) {
                reload();
              }
            }
          })
        );
      }
    } finally {
      setDownloadingTracks((currentDownloadingTracks) => ({
        ...currentDownloadingTracks,
        [record.session_id]: false
      }));
    }
  };

  const handleEditTrack = async ({
    sessionId,
    name,
    record
  }: {
    sessionId: string;
    name: string;
    record: any;
  }) => {
    try {
      if (record.name !== name) {
        setDeletingTracks({
          ...deletingTracks,
          [sessionId]: true
        });
        await editTrack({
          sessionId,
          page,
          name,
          add_downloaded: record.add_downloaded
        });
      }
    } finally {
      setDeletingTracks({
        ...deletingTracks,
        [sessionId]: false
      });
    }
  };

  const playing = useAppSelector((state) => state.playing);
  const currentTrack = useAppSelector((state) => state.currentTrack);

  const renderPlayBtn = (callback, record) => {
    const size = 36;

    if (record.status_track === 0) {
      return <PlayerSpinner />;
    }

    return (
      <PlayerButton
        size={size}
        url={record.image_url}
        focused={currentTrack === record.session_id}
        playing={playing}
        onClick={() => callback(record)}
        showNotifier={generatingTracks.includes(record.session_id)}
        name={record.name}
        useWhiteButtons={true}
        color={!record.flag_staffpicks ? record.color : undefined}
      />
    );
  };

  const setAndPlayTrack = (record) => {
    if (!record.status_track) return;
    if (currentTrack === record.session_id) {
      dispatch(setPlaying(!playing));
    } else {
      dispatch(setCurrentTrack(record.session_id));
    }
  };

  const handleDeleteTrackClick = async (sessionId: string) => {
    try {
      dispatch(removeTrackFromGenerating(sessionId));
      setDeletingTracks({
        ...deletingTracks,
        [sessionId]: true
      });
      await deleteTrack(sessionId);
    } finally {
      if (currentTrack === sessionId) {
        dispatch(setCurrentTrack(''));
      }
    }
  };

  function getColumns() {
    return [
      {
        dataIndex: 'url',
        width: isMediumScreen ? 55 : 66,
        render: (url, record) => renderPlayBtn(setAndPlayTrack, record)
      },
      {
        dataIndex: 'name',

        render: (name, record) => {
          return (
            <div className='track-name-container'>
              <TrackNameDropdown record={record} name={name} />
              {isMediumScreen && Array.isArray(record.tags) && (
                <div className='track-description'>
                  <Tags tags={record.tags?.slice(0, 4)} />
                </div>
              )}
            </div>
          );
        }
      },
      {
        dataIndex: 'tags',
        className: 'table-cell_tags',
        render: (tags) => <Tags tags={tags?.slice(0, 4)} />
      },
      {
        dataIndex: 'track_type',
        width: 80,
        className: 'table-cell_track-type',
        render: (type) => <span className='track-type'>{translate(type)}</span>
      },
      {
        dataIndex: 'duration',
        width: 100,
        className: 'table-cell_duration',
        render: renderDuration
      },
      {
        dataIndex: 'random',
        width: tab ? (isMediumScreen ? 120 : 180) : 100,

        render: (_, record, index) => {
          //TODO refactor
          const isDeleteBtnPresent = record.session_id && !!tab;
          const isDownloading = !!downloadingTracks[record.session_id];
          const isTooltipVisible = !!tracksTooltips[record.session_id];
          const isDeleting = !!deletingTracks[record.session_id];
          const isTrackReady = record.status_track === 1 || record.status_mp3 === 4;
          const isDeleteBtnVisible = isDeleteBtnPresent && !isMediumScreen && !isTrackReady;
          const isMoreDotsVisible = isDeleteBtnPresent && !isMediumScreen && isTrackReady;
          const tooltipDuration = record.duration / 600;

          return (
            <div className='download-btn-container' style={{ position: 'relative' }}>
              {isTrackReady && (
                <>
                  {!isMediumScreen && (
                    <div className='generate-track'>
                      <GenerateTrackDropdown track={record}>
                        <IconBase>
                          <GenerateDropdownIcon width={30} height={30} />
                        </IconBase>
                      </GenerateTrackDropdown>
                    </div>
                  )}
                  {isDownloading ? (
                    <Tooltip
                      text={`Your track is preparing for download.\nIt may take up to ${Math.ceil(
                        tooltipDuration
                      )} minute${tooltipDuration > 1 ? 's' : ''}.`}
                      visible={isTooltipVisible}
                      position='bottom-right'
                    >
                      <IconBase
                        onClick={() => checkLicense(record, index)}
                        pending={isDownloading}
                        className='download-btn-container__download'
                      >
                        <>
                          <DownloadIcon pending={isDownloading} />

                          {Boolean(record.flag_downloaded) && (
                            <div className='download-btn-container__check-mark'>
                              <img src={CheckMark} alt='check-mark' className='check-mark' />
                            </div>
                          )}
                        </>
                      </IconBase>
                    </Tooltip>
                  ) : (
                    <IconBase
                      onClick={() => checkLicense(record, index)}
                      pending={isDownloading}
                      className='download-btn-container__download'
                    >
                      <>
                        <DownloadIcon pending={isDownloading} />

                        {Boolean(record.flag_downloaded) && (
                          <div className='download-btn-container__check-mark'>
                            <img src={CheckMark} alt='check-mark' className='check-mark' />
                          </div>
                        )}
                      </>
                    </IconBase>
                  )}
                </>
              )}
              {isDeleteBtnVisible && (
                <IconBase
                  className={isTrackReady ? '' : 'download-btn-container__delete-icon'}
                  onClick={(e) =>
                    dispatch(
                      showModal({
                        name: ModalNames.ConfirmDeletion,
                        small: true,
                        extraSmall: true,
                        additionalProps: {
                          onConfirm: () => handleDeleteTrackClick(record.session_id)
                        }
                      })
                    )
                  }
                  pending={isDeleting}
                >
                  <DeleteIcon />
                </IconBase>
              )}
              {isMoreDotsVisible && (
                <IconBase pending={isDeleting}>
                  <MoreDropdown style={!isMediumScreen ? { width: 140, top: -10 } : {}}>
                    {isTrackEditable && (
                      <li
                        onClick={() => {
                          dispatch(
                            showModal({
                              name: ModalNames.EditTrackName,
                              small: true,
                              extraSmall: true,
                              additionalProps: {
                                record,
                                onConfirm: ({ name }) => {
                                  handleEditTrack({
                                    name,
                                    sessionId: record.session_id,
                                    record
                                  });
                                }
                              }
                            })
                          );
                        }}
                      >
                        <EditPen />
                        {translate('Rename')}
                      </li>
                    )}
                    <li
                      onClick={() => {
                        if (deletingTracks[record.session_id]) {
                          return;
                        }
                        dispatch(
                          showModal({
                            name: ModalNames.ConfirmDeletion,
                            small: true,
                            extraSmall: true,
                            additionalProps: {
                              onConfirm: () => handleDeleteTrackClick(record.session_id)
                            }
                          })
                        );
                      }}
                    >
                      <DeleteIcon disabled={deletingTracks[record.session_id]} />
                      {translate('Delete track')}
                    </li>
                  </MoreDropdown>
                </IconBase>
              )}
              {isMediumScreen && isTrackReady && (
                <IconBase pending={isDeleting}>
                  <MoreDropdown>
                    {isTrackEditable && (
                      <li
                        onClick={() => {
                          dispatch(
                            showModal({
                              name: ModalNames.EditTrackName,
                              small: true,
                              extraSmall: true,
                              additionalProps: {
                                record,
                                onConfirm: ({ name }) => {
                                  handleEditTrack({
                                    name,
                                    sessionId: record.session_id,
                                    record
                                  });
                                }
                              }
                            })
                          );
                        }}
                      >
                        <EditPen />
                        {translate('Rename')}
                      </li>
                    )}
                    <li
                      onClick={() => {
                        dispatch(
                          showModal({
                            name: ModalNames.TrackDuration,
                            small: true,
                            extraSmall: true,
                            additionalProps: {
                              track: record
                            }
                          })
                        );
                      }}
                    >
                      <GenerateDropdownIcon
                        width={30}
                        height={30}
                        style={{ position: 'relative', left: -4 }}
                      />
                      {translate('Generate similar')}
                    </li>
                    <li
                      onClick={() => {
                        if (deletingTracks[record.session_id]) {
                          return;
                        }

                        dispatch(
                          showModal({
                            name: ModalNames.ConfirmDeletion,
                            small: true,
                            extraSmall: true,
                            additionalProps: {
                              onConfirm: () => handleDeleteTrackClick(record.session_id)
                            }
                          })
                        );
                      }}
                    >
                      <DeleteIcon disabled={deletingTracks[record.session_id]} />
                      {translate('Delete track')}
                    </li>
                  </MoreDropdown>
                </IconBase>
              )}
            </div>
          );
        }
      }
    ].filter(Boolean);
  }

  const className = classNames({
    'track-list': true,
    'track-list_generator': isGenerator,
    [classNameProps]: !!classNameProps
  });

  let columns = getColumns();

  if (isMediumScreen) {
    columns = columns.filter(
      (item) => item.dataIndex !== 'duration' && item.dataIndex !== 'track_type'
    );
  }

  const getRowClassName = (record) => {
    const status = record.status_track;
    const isLoaderVisible = status !== 1;

    return isLoaderVisible ? 'row-loading' : '';
  };

  const getRowKey = useCallback((record) => `${record.session_id}_${record.name}`, []);
  const translate = useTranslation();

  return (
    <div className={className}>
      {tab === 0 && <Helmet>{renderTrackListScript(trackList, tracks.tracksCount)}</Helmet>}
      {isGenerator && (
        <div className='track-list__header'>
          <div className='track-list__header-title'>{translate('Generated tracks')}</div>
          <ToggleGenerator
            downloaded={downloaded}
            setDownloaded={(downloaded) => setDownloaded(downloaded)}
            id='downloaded_generated_page'
          />
        </div>
      )}
      <Table
        columns={columns}
        dataSource={trackList}
        rowKey={getRowKey}
        className='table-track-list'
        getRowClassName={getRowClassName}
      />
      {pageLoading && (
        <div>
          <Preloader width={100} height={100} />
        </div>
      )}
      {hasMorePages && !pageLoading ? (
        <div className='load-more-btn-wrapper'>
          <LinkButton className='load-more-btn' onClick={loadMorePages}>
            {translate('Load more')}
          </LinkButton>
        </div>
      ) : null}
    </div>
  );
};

export default TrackList;
