import React from 'react';
import { CloseIcon } from 'layout/components/icons';
import classNames from 'utils/class-names';
import './dropdown-overlay.scss';

type DropdownOverlayProps = {
  children: React.ReactNode;
  className?: string;
  shadowed?: boolean;
  onClose: () => void;
};

export function DropdownOverlay({children, onClose, shadowed, className: propClassName}: DropdownOverlayProps) {
  const className = classNames({
    'dropdown-overlay': true,
    'dropdown-overlay_shadowed': shadowed,
    [propClassName]: !!propClassName
  });

  return (
    <div className={className}>
      <CloseIcon
        className='dropdown-overlay__close'
        onClick={onClose}
      />
      {children}
    </div>
  );
}