import { useAppSelector } from 'hooks';
import React from 'react';
import classNames from 'utils/class-names';
import { fmtMSS } from 'utils/formatters';
import './Wave.scss';

export function Wave({ url, duration, time, hoverRef, waveformRef, songRef }) {
  const isDarkTheme = useAppSelector((state) => state.settings.isDarkTheme);

  const hoverPlaceholderClassName = classNames({
    'wave__hover-placeholder': true,
    'wave__hover-placeholder_dark-theme': isDarkTheme
  });

  const waveClassName = classNames({
    'wave': true,
    'wave_inactive': !duration
  })

  return (
    <div className={waveClassName}>
      <div className='wave__duration'>{fmtMSS(Math.floor(time))}</div>
      <div ref={waveformRef} className='wave__player-block'>
        <div className={hoverPlaceholderClassName} ref={hoverRef} />
        <audio ref={songRef} style={{ display: 'none' }} preload='false' src={url} />
      </div>
      <div className='wave__duration'>{fmtMSS(Math.floor(duration))}</div>
    </div>
  );
}
