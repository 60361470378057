import React from 'react';
import './logo-footer.scss';
import { useAppSelector } from 'hooks';

export function LogoFooter() {
  const isDarkTheme = useAppSelector((state) => state.settings.isDarkTheme);

  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='92'
        height='24'
        viewBox='0 0 92 24'
        fill='none'
      >
        <path
          d='M90.8235 18.0109C86.9367 18.0109 84.9198 15.1143 81.9365 10.3666C79.0372 5.74955 75.4236 0 68.1964 0C60.9692 0 57.3555 5.74955 54.4563 10.3666C51.4729 15.0926 49.456 18.0109 45.5693 18.0109C41.6826 18.0109 39.6657 15.1143 36.6823 10.3666C33.7831 5.74955 30.1694 0 22.9422 0C15.715 0 12.1014 5.74955 9.2021 10.3666C6.21877 15.0926 4.20187 18.0109 0.31514 18.0109C0.210094 18.0109 0.105047 18.0109 0 17.9891V24C0.105047 24 0.210094 24 0.31514 24C7.54236 24 11.156 18.2505 14.0553 13.6334C17.0386 8.90744 19.0555 5.98911 22.9422 5.98911C26.8289 5.98911 28.8459 8.88567 31.8292 13.6334C34.7285 18.2505 38.3421 24 45.5693 24C52.7965 24 56.4101 18.2505 59.3094 13.6334C62.2928 8.90744 64.3097 5.98911 68.1964 5.98911C72.0831 5.98911 74.1 8.88567 77.0834 13.6334C79.9827 18.2505 83.5962 24 90.8235 24C91.2226 24 91.6218 23.9782 92 23.9347V17.8802C91.6218 17.9673 91.2226 18.0109 90.8235 18.0109Z'
          fill={isDarkTheme ? 'white' : '#000DFF'}
        />
      </svg>
    </>
  );
}
