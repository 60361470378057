import { ISinglePurchasePricing, ISubscriptionPricing, PriceType, SubscriptionPeriod } from 'interfaces/pricing-info';

export function isSinglePurchase(
  pricing: ISubscriptionPricing | ISinglePurchasePricing
): pricing is ISinglePurchasePricing {
  return pricing.type === PriceType.SINGLE_PURCHASE;
}

export function isSubscriptionPricing(
  pricing: ISubscriptionPricing | ISinglePurchasePricing
): pricing is ISubscriptionPricing {
  return pricing.type === PriceType.SUBSCRIPTION;
}

export function getButtonText({isActive, isCanceled, email, type}: { isActive: boolean, isCanceled: boolean, email?: string, type: PriceType }) {
  if (isCanceled && isActive) {
    return 'Cancelled';
  }

  if (isActive) {
    return 'Cancel';
  }

  if (email) {
    return 'Get a quote';
  }

  return type === PriceType.SINGLE_PURCHASE ? 'Select a track' : 'Get started';
}


export function getPriceTitle(pricing: ISubscriptionPricing | ISinglePurchasePricing): string {
  if (pricing.isBlackFriday && isSubscriptionPricing(pricing)) {
    return String((pricing.price * 0.77) / 100);
  }

  if (pricing.email) {
    return 'Custom';
  }

  if (pricing.isFree) {
    return 'Free';
  }

  if (isSinglePurchase(pricing)) {
    const { price } = pricing;

    return `$${price / 100}`;
  } else {
    if (pricing.period === SubscriptionPeriod.Monthly) {
      return `$${pricing.price / 100}/month`;
    }

    if (pricing.period === SubscriptionPeriod.Annual) {
      return `$${pricing.price / 100}/year`;
    }

    return '';
  }
}
