import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import classNames from 'utils/class-names';
import './Carousel.scss';

type CarouselProps = {
  slides: ReactNode[];
  wasToggled?: number;
  isPricingPage?: boolean;
  currentImageIndex?: number;
  onIndexChange?: (index: number) => void;
  className?: string;
};

export const Carousel = ({
  slides,
  wasToggled,
  isPricingPage,
  currentImageIndex: currentImageIndexProp,
  onIndexChange,
  className
}: CarouselProps) => {
  const [internalIndex, setInternalIndex] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);
  const containerRefs = useRef<(HTMLDivElement | null)[]>([]);
  const isControlled = currentImageIndexProp !== undefined;
  const currentImageIndex = isControlled ? currentImageIndexProp : internalIndex;
  const setCurrentImageIndex = useCallback(
    (index: number) => {
      if (isControlled) {
        onIndexChange?.(index);
      } else {
        setInternalIndex(index);
      }
    },
    [setInternalIndex, isControlled, onIndexChange]
  );

  useEffect(() => {
    setCurrentImageIndex(0);
  }, [wasToggled]);

  useEffect(() => {
    if (isPricingPage) {
      const heights = containerRefs.current.map((container) => container?.clientHeight || 0);
      const maxContainerHeight = Math.max(...heights);
      setMaxHeight(maxContainerHeight);
    }
  }, [slides, isPricingPage, wasToggled]);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentImageIndex < slides.length - 1) {
        setCurrentImageIndex(currentImageIndex + 1);
      }
    },
    onSwipedRight: () => {
      if (currentImageIndex > 0) {
        setCurrentImageIndex(currentImageIndex - 1);
      }
    },
    trackMouse: true
  });

  const nextSlide = () => {
    if (currentImageIndex === slides.length - 1) {
      return;
    }
    setCurrentImageIndex(currentImageIndex + 1);
  };

  const prevSlide = () => {
    if (currentImageIndex === 0) {
      return;
    }
    setCurrentImageIndex(currentImageIndex - 1);
  };

  const goToSlide = (index: number) => {
    setCurrentImageIndex(index);
  };

  const prevButtonClassName = classNames({
    'carousel-button prev': true,
    'carousel-button__disabled': currentImageIndex === 0
  });

  const nextButtonClassName = classNames({
    'carousel-button next': true,
    'carousel-button__disabled': currentImageIndex === slides.length - 1
  });

  const carouselClassName = classNames({
    carousel: true,
    [className]: !!className
  });

  return (
    <div className={carouselClassName} {...handlers}>
      <div className='carousel-container'>
        <div
          className='carousel-slide'
          style={{ transform: `translateX(-${currentImageIndex * 100}%)` }}
        >
          {slides.map((slide, index) => (
            <div
              key={index}
              className='carousel-image-container'
              ref={(el) => (containerRefs.current[index] = el)}
              style={isPricingPage && maxHeight ? { height: `${maxHeight}px` } : {}}
            >
              {typeof slide === 'string' ? (
                <img src={slide} alt={`Slide ${index}`} className='carousel-image' />
              ) : (
                slide
              )}
            </div>
          ))}
        </div>
        {slides.length > 1 && (
          <>
            <button className={prevButtonClassName} onClick={prevSlide}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path d='M15 18L9 12L15 6' stroke='white' strokeWidth='2' />
              </svg>
            </button>
            <button className={nextButtonClassName} onClick={nextSlide}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path d='M9 18L15 12L9 6' stroke='white' strokeWidth='2' />
              </svg>
            </button>
          </>
        )}
      </div>
      {slides.length > 1 && (
        <div className='slider-dots'>
          {slides.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentImageIndex ? 'active' : ''}`}
              onClick={() => goToSlide(index)}
            ></span>
          ))}
        </div>
      )}
    </div>
  );
};
