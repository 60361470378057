import { SET_IS_PRICING_LOADING, SET_PRICING_DATA } from './types';
import { IPricingData } from 'interfaces/pricing-info';
import { delay } from 'utils/delay';
import { api } from 'api';
import { addNotification } from '../../redux/notifications/actions';
import { resetPaymentsApiState } from '../../redux/api/payments';
import { IAppState } from 'interfaces';

const setPricingDataAction = (payload: {pricingData: IPricingData, isLoggedIn: boolean}) => ({
  type: SET_PRICING_DATA,
  payload
});

export const setPricingData = (payload: IPricingData) => (dispatch, getState) => {
  const state: IAppState = getState();
  const isLoggedIn = !!state.loggedIn;

  dispatch(setPricingDataAction({
    pricingData: payload,
    isLoggedIn
  }))
}

export const setIsPricingLoading = (payload: boolean) => ({
  type: SET_IS_PRICING_LOADING,
  payload
});


export const fetchPricing = () => async (dispatch, getState) => {
  const language = getState().settings.language;
  
  dispatch(setIsPricingLoading(true))

  try {
    const result = await api.priceInfo(language);
    dispatch(setPricingData(result));
  } finally {
    dispatch(setIsPricingLoading(false));
  }
}

export const cancelSubscribeAndRefetchPricing = (priceId: string) => async (dispatch, getState) => {
  const language = getState().settings.language;

  dispatch(setIsPricingLoading(true));

  try {
    const response = await api.cancelSubscription(priceId);

    if (response.data.data.code === 1) {
      await delay(1000);
      const result = await api.priceInfo(language);
      dispatch(setPricingData(result));
      dispatch(resetPaymentsApiState());

      dispatch(addNotification({
        title: response.data.data.text,
        action: 'view',
        actionUrl: '/render/pricing'
      }));
    } else {
      dispatch(addNotification({
        title: response.data.data.text,
      }));
    }


  } catch {
    dispatch(addNotification({
      title: 'Error during subscription cancel'
    }));
  } finally {
    dispatch(setIsPricingLoading(false));
  }
};
