import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { hideModal, showModal, showTurnstileModal } from '../../../../redux/modals/actions';
import { TrackDurationForm } from 'layout/forms';
import { useCreateTrackMutation } from '../../../../redux/api/tracks';
import { ModalNames } from 'constants/modal-names';
import { addNotification } from '../../../../redux/notifications/actions';
import { addTrackToGenerating } from '../../../../redux/generator/actions';
import { useAppSelector } from 'hooks';
import { useTurnstileToken } from 'hooks/useTurnstileToken';
import { CLOUDFLARE_ERROR_CODE } from 'constants/api';
import useTranslation from 'hooks/useTranslations';

type TrackDurationProps = {
  track: any;
  onSubmit?: () => void;
};

function parseDuration(duration: number) {
  
  let minutes = String(Math.floor(duration / 60));
  let seconds = String(duration % 60);

  while (minutes.length !== 2) {
    minutes = '0' + minutes;
  }

  while (seconds.length !== 2) {
    seconds = '0' + seconds;
  }

  return `${minutes}:${seconds}`;
}

export function TrackDuration({ track, onSubmit }: TrackDurationProps) {
  const dispatch = useDispatch();
  const turnstileToken = useTurnstileToken()
  const isTurnstileLoaded = useAppSelector(state => state.turnstileToken.isLoaded)
  const [createTrack] = useCreateTrackMutation();

  const handleClick = async (values) => {
    if (!turnstileToken) {
      dispatch(
        showTurnstileModal((token) => {
          generateTrack(values, token);
        })
      );
    } else {
      generateTrack(values, turnstileToken);
    }
  }

  const generateTrack = async (values, token) => {
    const seconds = parseInt(values.minutes) * 60 + parseInt(values.seconds);

    dispatch(hideModal());
    onSubmit?.();

    const data: any = await createTrack({
      duration: seconds,
      trackType: values.trackType,
      sessionId: track.session_id,
      token
    });

    try {
      if (data?.data?.data) {
        const code = data.data.data.code;  

        // TODO: fix DRY
        if (code === 2) {
          dispatch(
            showModal({
              name: ModalNames.UnauthorizedTracksRestrictions,
              small: true
            })
          );
        } else if (code === 4) {
          dispatch(
            showModal({
              name: ModalNames.FreePlanTracksRestrictions,
              small: true,
              additionalProps: {
                message: data.data.data.text
              }
            })
          );
        } else if (code !== 1) {
          dispatch(
            addNotification({
              title: data?.data?.data?.text || 'Error during track generation'
            })
          );
        } else {
          const sessionId = data.data.data.session_id;
          dispatch(addTrackToGenerating(sessionId));
        }
      } else if (data?.data?.error?.code === CLOUDFLARE_ERROR_CODE) {
        dispatch (
          showTurnstileModal((token) => {
            generateTrack(values, token);
          })
        );
      }
    } catch {
      dispatch(
        addNotification({
          title: 'Error during track generation'
        })
      );
    }
  };

  const defaultValues = useMemo(() => ({
    minutes: parseDuration(track.duration).split(':')[0],
    seconds: parseDuration(track.duration).split(':')[1],
    trackType: 'track'
  }), [track.duration]);

  const translate = useTranslation()

  return (
    <div className='info-modal'>
      <span className='info-modal__title'>{translate("Set the type and duration of a similar track that you'd like to generate")}</span>
      <TrackDurationForm onSubmit={handleClick} defaultValues={defaultValues} disabled={!isTurnstileLoaded && !turnstileToken} />
    </div>
  );
}
