import React from 'react';
import { ISinglePurchasePricing, ISubscriptionPricing, SubscriptionPeriod } from 'interfaces/pricing-info';
import { isSinglePurchase, isSubscriptionPricing } from 'utils/price-helpers';
import classNames from 'utils/class-names';
import './CardPrice.scss';
import useTranslation from 'hooks/useTranslations';

type CardPriceProps = {
  pricing: ISubscriptionPricing | ISinglePurchasePricing;
  showActivePeriod?: boolean;
  isBlackFriday?: boolean;
};

export function CardPrice({
  pricing,
  showActivePeriod = true,
  isBlackFriday
}: CardPriceProps) {
  let content: React.ReactNode = '';
  const translate = useTranslation();
  const className = classNames({
    'card-price': true,
    'card-price__free': pricing.isFree
  })
  const pricingPeriod = isSubscriptionPricing(pricing) ? pricing.period : undefined;

  if (pricing.isFree) {
    content = translate('free');
  } else if (isSinglePurchase(pricing)) {
    const { price } = pricing;
    const dollarSignClassNames = classNames({
      'card-price__dollar': String(price).startsWith('1')
    });

    content = (
      <>
        {pricing.priceTitle ? (
          pricing.priceTitle
        ) : (
          <>
            <span className={dollarSignClassNames}>$</span>
            {price / 100}
          </>
        )}
      </>
    );
  } else if (isBlackFriday) {
    if (pricing.isActive && showActivePeriod) {
      content = pricingPeriod === 'monthly' ?  translate('Monthly') : translate('Annual');
    } else {
      const priceOption = pricing.priceTitle;
      const dollarSignClassNames = classNames({
        'card-price__dollar': priceOption?.startsWith('1'),
      });


      const originalPrice = parseFloat(priceOption.replace(',', '.'));
      const discountedPrice = (originalPrice * 0.67).toFixed(2).toString();

      content = (
        <div className='card-price__black-friday'>
          <div>
            <span className={dollarSignClassNames}>$</span>
            <span className='card-price__black-friday-whole'>
              <div className='card-price__black-friday-discount'>
                -33%
              </div>
              {discountedPrice}
            </span>
            {pricingPeriod === SubscriptionPeriod.Annual ? <span className='card-price__black-friday-symbol'>{translate("/mo")}<span>*</span></span> :
            <span className='card-price__black-friday-symbol'>{translate("/mo")}</span>}
          </div>
          <div className='card-price__black-friday-deal'>
            {pricingPeriod === SubscriptionPeriod.Monthly ? <div>for 3 months,</div> : <div>for 1 year,</div>}
            <div>then {originalPrice}{translate("/mo")}</div>
          </div>
        </div>
      );
    }
  } else if (pricingPeriod) {
      const priceOption = pricing.priceTitle;
      const dollarSignClassNames = classNames({
        'card-price__dollar': priceOption?.startsWith('1'),
      });
      const [decimal, fractional] = priceOption.split(',')
      const decimalPartClassName = classNames({
        'card-price__whole': true,
        'card-price__whole_eleven': decimal === '11',
      })

      content = (
        <>
          <span className={dollarSignClassNames}>$</span>
          {priceOption.includes(',') ? (
            <>
              <span className={decimalPartClassName}>{decimal}</span>.
              <span className='card-price__fractional'>{fractional}</span>
            </>
          ) : (
            <span className={decimalPartClassName}>{priceOption}</span>
          )}
          <span className='card-price__symbol'>{translate("/mo")}</span>
        </>
      );
    // }
  }

  return <div className={className}>{content}</div>;
}
