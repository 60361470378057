import React from 'react';
import classNames from 'utils/class-names';
import './option-item.scss';
import useTranslation from 'hooks/useTranslations';


type OptionItemProps = {
  active?: boolean;
  title: string;
  onClick: (e: React.MouseEvent) => void;
};

export function OptionItem({active, title, onClick}: OptionItemProps) {
  const classes = classNames({
    'option-item': true,
    'option-item_active': !!active
  });

  const translate = useTranslation()
  return (
    <div
      className={classes}
      onClick={onClick}
    >
      <span className='option-item__title'>
        {translate(title)}
      </span>   
    </div>
  );
}