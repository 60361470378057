import React from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from 'interfaces';

type GenerateDropdownIconProps = {
    width?: number
    height?: number
    style?: React.CSSProperties
}
export function GenerateDropdownIcon({width, height, style}: GenerateDropdownIconProps ) {
    let viewBox = `0 0 ${width} ${height}`
    const isDarkTheme = useSelector((state: IAppState) => state.settings.isDarkTheme)
    
  return (
    <svg
          xmlns='http://www.w3.org/2000/svg'
          width={width}
          height={height}
          viewBox={viewBox}
          style={style}
          fill='none'
        >
          <path
            d='M14.5 17.25H11.8799C10.4714 17.25 9.76721 17.25 9.47228 16.7885C9.17735 16.3269 9.47321 15.6879 10.0649 14.4098L14.0463 5.81006C14.4205 5.00182 14.6075 4.5977 14.8038 4.64091C15 4.68413 15 5.12946 15 6.02013V12.25C15 12.4857 15 12.6036 15.0732 12.6768C15.1464 12.75 15.2643 12.75 15.5 12.75H18.1201C19.5286 12.75 20.2328 12.75 20.5277 13.2115C20.8226 13.6731 20.5268 14.3121 19.9351 15.5902L15.9537 24.1899C15.5795 24.9982 15.3925 25.4023 15.1962 25.3591C15 25.3159 15 24.8705 15 23.9799V17.75C15 17.5143 15 17.3964 14.9268 17.3232C14.8536 17.25 14.7357 17.25 14.5 17.25Z'
            fill={isDarkTheme ?  '#FF00E5' : 'url(#paint0_linear_10577_4807)'}
          />
          <defs>
            <linearGradient
              id='paint0_linear_10577_4807'
              x1='35.3125'
              y1='-10.5'
              x2='7.50726'
              y2='-9.53435'
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor='#3027EF' />
              <stop offset='1' stopColor='#B733E2' />
            </linearGradient>
          </defs>
        </svg>
  )
}
