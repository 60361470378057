import {useMemo} from 'react';
import {useMediaQuery} from 'react-responsive';

export const useSortedForColumnsDisplay = (data: any[]) => {
  const isMobileOrTablet = useMediaQuery(
    { maxWidth: 991 }
  );

  return useMemo(() => {
    if (isMobileOrTablet) {
      return data;
    }

    const pivotIndex = Math.ceil(data.length / 2 );
    const result = [];
    const firstSlice = data.slice(0, pivotIndex);
    const secondSlice = data.slice(pivotIndex);

    for (let i = 0; i < pivotIndex; ++i) {
      if (firstSlice[i]) {
        result.push(firstSlice[i]);
      }

      if (secondSlice[i]) {
        result.push(secondSlice[i]);
      }
    }

    return result;
  }, [data, isMobileOrTablet])
}
