import React, { useState } from 'react';
import { Dropdown } from 'layout/components/dropdown';
import { TrackDuration } from 'layout/components/modals';
import './GenerateTrackDropdown.scss';
import { CloseIcon } from 'layout/components/icons';

type GenerateTrackDropdownProps = {
  track: any;
  children: JSX.Element;
};

export function GenerateTrackDropdown({ track, children }: GenerateTrackDropdownProps) {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Dropdown
      useMask
      trigger={['click']}
      placement='topLeft'
      visible={isVisible}
      onVisibleChange={(isVisible) => setIsVisible(isVisible)}
      overlay={
        <div className='generate-track-dropdown'>
          <CloseIcon
            className='generate-track-dropdown__close'
            onClick={() => setIsVisible(false)}
          />

          <TrackDuration track={track} onSubmit={() => setIsVisible(false)} />
        </div>
      }
    >
      {React.cloneElement(children, {
        style: {
          zIndex: isVisible ? 10000 : 'inherit'
        }
      })}
    </Dropdown>
  );
}
