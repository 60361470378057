import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { IPricingInfo, ISinglePurchasePricing, ISubscriptionPricing, IAppState } from 'interfaces';
import { useLocation } from 'hooks/useLocation';

export function useSelectPricingInfo() {
  return useSelector<IAppState, IPricingInfo>((state) => state.pricingInfo);
}

export function useSelectIsPricingInfoLoading() {
  return useSelector<IAppState, boolean>((state) => state.pricingInfo.isLoading);
}

export function useSelectAvailableTrackPrices() {
  return useSelector<IAppState, ISinglePurchasePricing[]>((state) =>
    state.pricingInfo.data.singlePurchase.filter((item) => !item.isLegacy || item.isActive)
  );
}

export function useSelectAvailableSubscriptions() {
  return useSelector<IAppState, ISubscriptionPricing[]>((state) =>
    state.pricingInfo.data.subscription.filter((item) => !item.isLegacy || item.isActive)
  );
}

export function useSelectBlackFridaySubscriptions() {
  return useSelector<IAppState, ISubscriptionPricing[]>(
    (state) => state.pricingInfo.data.blackFriday
  );
}

const selectBestHighestAvailableSubscription = createSelector(
  (state: IAppState) =>
    state.pricingInfo.data.subscription.filter(
      (item) => item.isActive
    ),
  (activeSubscriptions) => {
    if (activeSubscriptions.length > 0) {
      const subscriptions = activeSubscriptions.slice();

      const sorted = subscriptions.sort(
        (a, b) => b.priceOrder - a.priceOrder
      );

      return sorted[0];
    }

    return null;
  }
);

export function useSelectBestHighestAvailableSubscription() {
  return useSelector<IAppState, ISubscriptionPricing | null>(
    selectBestHighestAvailableSubscription
  );
}

export const selectPricesMap = createSelector(
  (state: IAppState) => state.pricingInfo.data.singlePurchase,
  (state: IAppState) => state.pricingInfo.data.subscription,
  (purchases, subscriptions) => {
    const result = {};

    purchases.forEach((item) => {
      result[item.priceId] = item;
    });

    subscriptions.forEach((item) => {
      result[item.priceId] = item;
    });

    return result;
  }
);

export function useSelectPricesMap() {
  return useSelector(selectPricesMap);
}

export function useIsBlackFridayBannerVisible() {
  return false;

  const loggedIn = useSelector((state: IAppState) => state.loggedIn);
  const subscriptions = useSelector((state: IAppState) => state.pricingInfo.data.subscription);
  const { pathname } = useLocation();

  return (
    pathname !== '/render/sign-in' &&
    pathname !== '/render/sign-up' &&
    pathname !== '/render/forget'
    // (!loggedIn ||
    //   (subscriptions.length > 0 &&
    //     !subscriptions.find((item) => item.name === 'Pro' && item.isActive)))
  );
}
