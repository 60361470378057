import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'layout/components/app-button';
import useTranslation from 'hooks/useTranslations';
import { hideModal } from '../../../../redux/modals/actions';

import './confirm-modal.scss';
import classNames from 'utils/class-names';

type ConfirmModalProps = {
  title: React.ReactNode;
  icon: React.ComponentType<any>;
  content: React.ReactNode;
  cancelButtonText?: string;
  confirmButtonText?: string;
  onSubmit: () => void | Promise<void>;
};

export function ConfirmModal({
  title,
  icon: Icon,
  content,
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  onSubmit
}: ConfirmModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const translate = useTranslation();

  const buttonsClassName = classNames({
    'confirm-modal__buttons': true,
    'confirm-modal__buttons_loading': isLoading
  });

  const handleCancel = () => {
    dispatch(hideModal());
  };

  const handleSubmit = () => {
    const result = onSubmit();

    if (result instanceof Promise) {
      setIsLoading(true);
      result.then(() => {
        dispatch(hideModal());
        setIsLoading(false);
      });
    } else {
      dispatch(hideModal());
    }
  };

  return (
    <div className='confirm-modal'>
      <div className='confirm-modal__title'>
        {Icon && (
          <div className='confirm-modal__icon-wrapper'>
            <Icon className='confirm-modal__icon' color='white' />
          </div>
        )}
        {title}
      </div>
      <div className='confirm-modal__content'>{content}</div>
      <div className={buttonsClassName}>
        <Button className='confirm-modal__button-cancel' onClick={handleCancel}>
          {translate(cancelButtonText)}
        </Button>
        <Button className='confirm-modal__button-delete' onClick={handleSubmit} loading={isLoading}>
          {translate(confirmButtonText)}
        </Button>
      </div>
    </div>
  );
}
