import { useDispatch } from 'react-redux';
import { useLocation } from 'hooks/useLocation';
import { useEffect } from 'react';
import { PriceType } from 'interfaces';
import { useSelectPricesMap, useSelectPricingInfo } from '../redux/pricing-info/selectors';
import { stripeSubscribe, stripeOrder } from '../redux/purchase/actions';

export function useCallStripeFromLocationParams() {
  const dispatch = useDispatch();
  const location = useLocation();
  const {isLoadedForLoggedInUser} = useSelectPricingInfo();
  const pricesMap = useSelectPricesMap();

  useEffect(() => {
    if (!isLoadedForLoggedInUser) {
      return;
    }

    const query = new URLSearchParams(location.search);

    if (query.get('state')) {
      const state = JSON.parse(query.get('state').replace('#', ''));
      const priceId = state['price_id'];
      const sessionId = state['session_id'];
      const isBlackFriday = state['is_black_friday'];
      const trial = state['trial'];
      if (priceId) {
        if (sessionId) {
          if (
            (pricesMap[priceId]?.type === PriceType.SUBSCRIPTION && pricesMap[priceId]?.isActive) ||
            pricesMap[priceId]?.type === PriceType.SINGLE_PURCHASE
          ) {
            dispatch(stripeOrder({
              sessionId,
              priceId
            }));
          } else {
            dispatch(stripeSubscribe({
              priceId,
              sessionId,
              trial,
              isBlackFriday
            }));
          }
        } else {
          dispatch(stripeSubscribe({
            priceId,
            trial,
            isBlackFriday
          }));
        }
      }
    }
  }, [isLoadedForLoggedInUser]);
}
