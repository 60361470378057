import React, { useState } from 'react';
import { v4 } from 'uuid';
import './preloader.scss';

const Preloader = ({ width, height, color = 'rgb(0,13,255)', className }: { width: number, height: number, color?: string, className?: string }) => {
  const [id] = useState(() => v4().slice(0, 8) + v4().slice(0, 4));
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 720 720'
      width={width}
      height={height}
      shapeRendering='geometricPrecision'
      textRendering='geometricPrecision'
      className={className}
    >
      <g mask={`url(#${id})`}>
        <path
          d='M710.792000,405.832000C680.374000,405.832000,664.590000,383.987000,641.242000,348.182000C618.552000,313.361000,590.272000,270,533.711000,270C477.150000,270,448.870000,313.361000,426.179000,348.182000C402.832000,383.823000,387.047000,405.832000,356.629000,405.832000C326.211000,405.832000,310.427000,383.987000,287.079000,348.182000C264.389000,313.361000,236.109000,270,179.548000,270C122.987000,270,94.706500,313.361000,72.016400,348.182000C48.668600,383.823000,32.884200,405.832000,2.466320,405.832000C1.644210,405.832000,0.822106,405.832000,0,405.668000L0,451C0.822106,451,1.644210,451,2.466320,451C59.027200,451,87.307500,407.639000,109.998000,372.819000C133.345000,337.177000,149.130000,315.168000,179.548000,315.168000C209.966000,315.168000,225.750000,337.013000,249.098000,372.819000C271.788000,407.639000,300.068000,451,356.629000,451C413.190000,451,441.471000,407.639000,464.161000,372.819000C487.509000,337.177000,503.293000,315.168000,533.711000,315.168000C564.129000,315.168000,579.913000,337.013000,603.261000,372.819000C625.951000,407.639000,654.231000,451,710.792000,451C713.916000,451,717.040000,450.836000,720,450.507000L720,404.847000C717.040000,405.504000,713.916000,405.832000,710.792000,405.832000Z'
          transform='matrix(1 0 0 1 0.00000000000006 0)'
          fill={color}
          stroke='none'
          strokeWidth='1'
        />
        <mask id={id}>
          <g className='eXtM1V8wye96_to' transform='translate(-30.312096,334.656101)'>
            <g className='eXtM1V8wye96_ts' transform='rotate(-0.012759) scale(0.343859,1.573566)'>
              <rect
                id='eXtM1V8wye96'
                width='61.280375'
                height='352.584187'
                rx='0'
                ry='0'
                transform='translate(-30.640188,-176.292093)'
                fill='rgb(210,219,237)'
                stroke='none'
                strokeWidth='0'
              />
            </g>
          </g>
        </mask>
      </g>
    </svg>
  );
};

export default Preloader;
