import React from 'react';
import { IBreadcrumb } from 'interfaces';

export function renderItemListScript(breadcrumbs: IBreadcrumb[]) {
  const itemListElement = breadcrumbs.map(({ title, route }, index) => {
    const path = route.startsWith('/') ? route.slice(1) : route;

    return {
      '@type': 'ListItem',
      position: index + 1,
      name: title,
      item: `https://mubert.com/${path}`
    };
  });

  const script = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: itemListElement
  };

  return <script type='application/ld+json'>{JSON.stringify(script)}</script>;
}

export function renderTrackScript(track: any) {
  if (!track || !track.url) {
    return null;
  }

  const script = {
    '@context': 'http://schema.org',
    '@id': `https://mubert.com/render/tracks/${track.session_id}`,
    '@type': ['MusicRecording', 'Product'],
    url: `https://mubert.com/render/tracks/${track.session_id}`,
    license: '/license',
    name: track.name,
    brand: 'Mubert',
    audio: {
      '@type': 'AudioObject',
      '@id': track.url.mp3,
      contentUrl: track.url.mp3,
      playerType: 'HTML5',
      duration: `PT${track.duration}S`
    }
  };

  return <script type='application/ld+json'>{JSON.stringify(script)}</script>;
}

export function renderTrackListScript(tracks: any[], tracksCount: number) {
  const script = {
    '@context': 'https://schema.org',
    '@type': 'MusicPlaylist',
    name: 'Soft Piano Waves',
    numTracks: tracksCount,
    track: tracks.map(track => ({
      '@type': 'MusicRecording',
      byArtist: 'Mubert',
      duration: `PT${track.duration}S`,
      name: track.name,
      url: `https://mubert.com/render/tracks/${track.session_id}`
    }))
  };

  return <script type='application/ld+json'>{JSON.stringify(script)}</script>;
}
