import React from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from 'interfaces';

export function TimeLimitIcon() {
  const isDarkTheme = useSelector((state: IAppState) => state.settings.isDarkTheme);
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <circle cx='10' cy='10' r='7.5' fill='url(#paint0_linear_12670_10319)' />
      <path
        d='M10.0013 4.4665C10.0013 4.3237 10.0013 4.2523 10.048 4.20794C10.0947 4.16359 10.1635 4.16713 10.3012 4.17422C11.2209 4.22156 12.1179 4.48612 12.918 4.94802C13.8047 5.46 14.5411 6.19639 15.0531 7.08317C15.5651 7.96995 15.8346 8.97587 15.8346 9.99984C15.8346 11.0238 15.5651 12.0297 15.0531 12.9165C14.5411 13.8033 13.8047 14.5397 12.918 15.0517C12.0312 15.5636 11.0253 15.8332 10.0013 15.8332C8.97734 15.8332 7.97141 15.5636 7.08464 15.0517C6.2846 14.5898 5.60697 13.9452 5.1061 13.1723C5.03114 13.0567 4.99366 12.9988 5.00872 12.9362C5.02379 12.8736 5.08562 12.8379 5.20929 12.7665L9.8513 10.0864C9.92451 10.0442 9.96111 10.023 9.98121 9.98823C10.0013 9.95343 10.0013 9.91116 10.0013 9.82663V4.4665Z'
        fill={isDarkTheme ? '#fff' : '#FAF5FF'}
      />
      <defs>
        <linearGradient
          id='paint0_linear_12670_10319'
          x1='18.617'
          y1='-5.75'
          x2='2.4902'
          y2='2.12914'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#000DFF' />
          <stop offset='1' stopColor='#FF00E5' />
        </linearGradient>
      </defs>
    </svg>
  );
}
