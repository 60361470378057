import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Redirect } from 'layout/components/redirect';
import { IRoute } from 'interfaces';
import VerticalLayout from 'layout/vertical/Vertical';
import { InternalRouteWrapper, ExternalRouteWrapper } from 'layout/components/route-guards';
import { PageWithBreadcrumbs } from 'layout/vertical/PageWithBreadCrumbs';
import { TurnstilePageWrapper } from 'layout/components/turnstile';
import { usePathPrefix } from 'hooks/useLanguage';
import { routes } from './routes';

type RoutesProps = {
  routes: IRoute[];
}

const Routes = ({ routes }: RoutesProps) => {
  const prefix = usePathPrefix();

  return (
    <Switch>
      {routes.map((route, index) => {
        return (
          <Route
            key={index}
            exact={route.exact}
            path={!route.path.startsWith('/') ? `${prefix}/render/${route.path}` : `/${route.path}\``}
            component={() => {
              let component = <route.component />;
  
              if (route.internal) {
                component = <InternalRouteWrapper><route.component /></InternalRouteWrapper>
              }
  
              if (route.external) {
                component = <ExternalRouteWrapper><route.component /></ExternalRouteWrapper>
              }
  
              return (
                <PageWithBreadcrumbs getBreadcrumbs={route.getBreadcrumbs}>
                  {component}
                </PageWithBreadcrumbs>
              )
            }}
          />
        );
      })}
  
      <Route path='*'>
        <Redirect to="/page-not-found" />
      </Route>
    </Switch>
  );
}

const RenderPage = () => (
  <VerticalLayout>
    <TurnstilePageWrapper>
      <Routes routes={[...routes]} />
    </TurnstilePageWrapper>
  </VerticalLayout>
);

export default RenderPage;
