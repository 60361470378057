import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'layout/components/app-button';
import { hideModal } from '../../../../redux/modals/actions';
import useTranslation from 'hooks/useTranslations';

type ConfirmDeletionProps = {
  onConfirm: Function;
};

export function ConfirmDeletion({ onConfirm }: ConfirmDeletionProps) {
  const dispatch = useDispatch();
  const translate = useTranslation();

  const handleCancel = () => {
    dispatch(hideModal());
  };

  const handleDelete = () => {
    onConfirm();
    dispatch(hideModal());
  };

  return (
    <div className='info-modal'>
      <div className='info-modal__title-deletion'>{translate("Are you sure?")}</div>
      <div className='info-modal__text'>{translate("deleted track cannot be restored")}</div>
      <div className='info-modal__buttons-deletion'>
        <Button className='btn__cancel' onClick={handleCancel}>
          {translate("Cancel")}
        </Button>
        <Button className='btn__delete' onClick={handleDelete}>
          {translate("Delete")}
        </Button>
      </div>
    </div>
  );
}
