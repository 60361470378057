import { useForm, UseFormProps } from 'react-hook-form';
import { useImperativeHandle } from 'react';

export function useInitForm(ref, props?: UseFormProps) {
  const methods = useForm(props);

  useImperativeHandle(ref, () => ({
    setError: (name: string, error: any) => {
      methods.setError(name, error);
    }
  }));


  return methods;
}
