import { Turnstile } from 'layout/components/turnstile';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setTurnstileToken } from '../../../../redux/cloudfare/actions';
import { hideModal } from '../../../../redux/modals/actions';
import { storage } from 'utils/storage';
import { TURNTILE_TOKEN_KEY } from 'constants/cookies-names';

type TurstileModalProps = {
  onSubmit?: (token: string) => void;
}

export function TurstileModal({ onSubmit }: TurstileModalProps) {
  const dispatch = useDispatch();

  return (
    <div className='info-modal'>
      <Turnstile
        fullWidth
        onVerify={(token) => {
          dispatch(setTurnstileToken(token));
          storage.setItem(TURNTILE_TOKEN_KEY, token)
          dispatch(hideModal());
          onSubmit?.(token);
        }}
        onError={(err) => {
          console.log(err)
        }}
      />
    </div>
  );
}
